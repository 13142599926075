import React from 'react';
import PropTypes from 'prop-types';
import PencilIcon from 'mdi-react/PencilIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import { isUserAdmin } from '../../../security/authorization';

function UserEditCustomComponent({ data, onClickEdit, onClickDelete }) {
  if (!data) return null;

  const userRoleTypesArray = data?.roleType?.split(', ')?.map(role => ({ name: role })) ?? [];
  const style = {
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <div style={style}>
      <PencilIcon size={16} onClick={() => onClickEdit({ ...data, roles: userRoleTypesArray })} style={{ cursor: 'pointer' }} />
      {isUserAdmin() && <DeleteIcon size={16} onClick={() => onClickDelete({ ...data })} style={{ cursor: 'pointer', marginLeft: '16px' }} />}
    </div>
  );
}

UserEditCustomComponent.propTypes = {
  data: PropTypes.shape({
    roleType: PropTypes.string,
  }),
  onClickEdit: PropTypes.func.isRequired,
};

UserEditCustomComponent.defaultProps = {
  data: {
    roleType: '',
  },
};

export default UserEditCustomComponent;
