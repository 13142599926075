import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import SelectInput, { components } from 'react-select';
import dangerIcon from '../../assets/public/Danger.png';
import MenuDown from 'mdi-react/MenuDownIcon';
import ClearIcon from 'mdi-react/ClearIcon';
import './Select.css';
import Chip, { ChipComponent } from '../Chip/Chip';

function Select({
  isLoading,
  isSearchable,
  isClearable = false,
  onChange,
  placeholder,
  value,
  options,
  isDisabled,
  size = 'small',
  chipSize,
  errorMessage,
  label,
  required,
  optional,
  isMulti,
  chipsOutside,
  getOptionLabel,
  getOptionValue,
  customMenuList,
  removeMenu,
  inputValue,
  escapeClearsValue,
  onInputChange,
  onKeyDown,
  onBlur,
  removeDropdownIndicator,
  withInput,
  menuPortalTarget,
}) {
  const [isInputFocused, setIsInputFocused] = useState(false);

  const customSize = {
    small: {
      height: '32px',
      borderRadius: '4px',
      padding: isMulti ? '0px 12px' : '8px 12px',
      fontSize: '14px',
      iconSize: '20px',
    },
    medium: {
      height: '44px',
      borderRadius: '6px',
      padding: isMulti ? '0px 12px' : '10px 16px',
      fontSize: '16px',
      iconSize: '30px',
    },
    large: {
      height: '56px',
      borderRadius: '6px',
      padding: isMulti ? '0px 12px' : '14px 20px',
      fontSize: '20px',
      iconSize: '40px',
    },
  };

  const hasErrorBorder = errorMessage ? '#841818' : '#7C9098';
  const hasErrorColor = errorMessage ? '#C11414' : '#000';
  const hasErrorColorPlaceholder = errorMessage ? '#C11414' : '#AEBBC0';

  const customSelectStyles = {
    control: (base, { isDisabled }) => ({
      ...base,
      'minHeight': customSize[size]?.height,
      'zIndex': isInputFocused && value?.length > 1 && !chipsOutside ? '999' : null,
      'position': isInputFocused && value?.length > 1 && !chipsOutside ? 'absolute' : 'relative',
      'width': '100%',
      'boxShadow': 'none',
      'outline': 'none',
      'fontFamily': 'Muli',
      'fontSize': customSize[size]?.fontSize,
      'borderRadius': customSize[size]?.borderRadius,
      'borderTopLeftRadius': withInput ? '0px' : null,
      'borderBottomLeftRadius': withInput ? '0px' : null,
      'color': isDisabled ? '#3D454830' : hasErrorColor,
      'border': isDisabled ? '1px solid #7C909830' : `1px solid ${hasErrorBorder}`,
      'backgroundColor': Boolean(errorMessage) ? '#FFF1F1' : '#fff',
      '&:hover': {
        backgroundColor: Boolean(errorMessage) ? 'FFF1F1' : '#F4F6F7',
        border: `2px solid ${hasErrorBorder}`,
        borderRadius: customSize[size]?.borderRadius,
        borderTopLeftRadius: withInput ? '0px' : null,
        borderBottomLeftRadius: withInput ? '0px' : null,
      },
    }),
    input: base => ({
      ...base,
      margin: 0,
      padding: 0,
    }),
    valueContainer: base => ({
      ...base,
      ':active': {
        height: '100%',
      },
      'height': isInputFocused && !chipsOutside ? '100%' : customSize[size]?.height,
      'minHeight': customSize[size]?.height,
      'margin': 0,
      'padding': customSize[size]?.padding,
    }),
    singleValue: base => ({
      ...base,
    }),
    option: (base, { isSelected, isFocused }) => ({
      ...base,
      fontFamily: 'Muli',
      fontSize: customSize[size]?.fontSize,
      fontWeight: '400',
      color: '#5F6F76',
      borderBottom: '1px solid #DDE1E3',
      backgroundColor: (isSelected || isFocused) && '#E8F4FF',
    }),
    menu: base => ({
      ...base,
      margin: 0,
      padding: 0,
      border: '1px solid #000',
      borderRadius: customSize[size]?.borderRadius,
    }),
    menuList: base => ({
      ...base,
      margin: 0,
      padding: 0,
      borderRadius: customSize[size]?.borderRadius,
      zIndex: 3,
    }),
    clearIndicator: base => ({
      ...base,
      height: 'auto',
      width: customSize[size]?.height,
      padding: 0,
    }),
    dropdownIndicator: base => ({
      ...base,
      height: 'auto',
      width: customSize[size]?.height,
      padding: 0,
    }),
    multiValue: base => ({
      ...base,
      maxWidth: !isInputFocused && value?.length > 1 && !chipsOutside ? '75%' : null,
      padding: 0,
      margin: 0,
      backgroundColor: 'transparent',
    }),
    multiValueLabel: base => ({
      ...base,
      color: '#29487f',
    }),
    multiValueRemove: base => ({
      ...base,
      'display': 'none',
      'color': '#29487f',
      'borderRadius': '4px',
      '&:hover': {
        borderRadius: '4px',
        color: '#29487f',
        backgroundColor: '#b3d8ff',
      },
    }),
  };

  const DropdownIndicator = props => {
    if (removeDropdownIndicator) {
      return null;
    }
    return (
      <components.DropdownIndicator {...props}>
        <MenuDown size={customSize[size]?.iconSize} />
      </components.DropdownIndicator>
    );
  };

  const Menu = props => {
    if (removeMenu) {
      return null;
    }
    return <components.Menu {...props}></components.Menu>;
  };

  const ClearIndicator = props => {
    return (
      <components.ClearIndicator {...props}>
        <ClearIcon size={customSize[size]?.iconSize} />
      </components.ClearIndicator>
    );
  };

  const CustomeMenuList = props => {
    return <components.MenuList {...props}></components.MenuList>;
  };

  const MultiValue = ({ index, getValue, ...props }) => {
    const maxToShow = 1;
    if (!isInputFocused && !chipsOutside) {
      return index < maxToShow ? (
        <components.MultiValue {...props}>
          <ChipComponent data={props.data} handleRemoveValue={handleRemoveValue} size={chipSize} isInputFocused={isInputFocused} />
        </components.MultiValue>
      ) : index === maxToShow ? (
        <ChipComponent data={{ value: 'countChip', label: `${getValue()?.length - 1} more` }} size={chipSize} isInputFocused={isInputFocused} />
      ) : null;
    }
    return (
      <components.MultiValue {...props}>
        <ChipComponent data={props.data} handleRemoveValue={handleRemoveValue} size={chipSize} isInputFocused={isInputFocused} />
      </components.MultiValue>
    );
  };

  const handleRemoveValue = name => {
    if (!onChange) return;
    const removedValue = value.find(val => val.value === name);
    if (!removedValue) return;
    onChange(
      value.filter(val => val.value !== name),
      { name, action: 'remove-value', removedValue },
    );
  };

  const handleRemoveAll = name => {
    if (!onChange) return;
    onChange([], { name, action: 'clear' });
  };

  return (
    <div
      className={cx('custom-select-container', {
        'custom-select-container-disabled': isDisabled,
      })}
    >
      <div
        className={cx('custom-select-wrapper', {
          [`custom-select-wrapper-${size}`]: label,
        })}
      >
        <div className={`custom-select-label-${size}`}>
          {label && <div className="custom-select-label">{label}</div>}
          {required && <div className="custom-select-label-required">*</div>}
          {optional && <div className="custom-select-label-optional">(optional)</div>}
        </div>
        <SelectInput
          styles={customSelectStyles}
          theme="neutral30"
          isLoading={isLoading}
          isSearchable={isSearchable}
          components={{
            Placeholder: () =>
              !placeholder ? '' : <div style={{ color: hasErrorColorPlaceholder, fontSize: customSize[size]?.fontSize, fontWeight: '400' }}>{placeholder}</div>,
            IndicatorSeparator: () => null,
            DropdownIndicator,
            ClearIndicator,
            MenuList: customMenuList ? customMenuList : CustomeMenuList,
            Menu,
            MultiValue,
          }}
          isClearable={isClearable && !chipsOutside}
          value={value}
          options={options}
          onChange={onChange}
          isDisabled={isDisabled}
          menuPosition={'fixed'}
          menuPlacement="auto"
          menuPortalTarget={menuPortalTarget && menuPortalTarget}
          isMulti={isMulti}
          controlShouldRenderValue={!chipsOutside}
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          inputValue={inputValue}
          escapeClearsValue={escapeClearsValue}
          onInputChange={onInputChange}
          onKeyDown={onKeyDown}
          onFocus={() => setIsInputFocused(true)}
          onBlur={() => {
            setIsInputFocused(false);
            onBlur && onBlur();
          }}
        />
      </div>
      {isMulti && chipsOutside ? <Chip values={value} handleRemoveValue={handleRemoveValue} handleRemoveAll={handleRemoveAll} size={chipSize} /> : null}
      {Boolean(errorMessage) && (
        <div className="custom-select-error-message">
          <img src={dangerIcon} alt="error icon" className="custom-select-error-icon" />
          {errorMessage}
        </div>
      )}
    </div>
  );
}

Select.propTypes = {
  onChange: PropTypes.func,
};

export default Select;
