import { formatUserManagementFilters } from '../utils/userManagementUtils';

export const fetchUserManagementApi = `{
    users {
      id
      email
      accountStatus
      enabled
      emailVerified
      createdDate
      lastModifiedDate
      lastLoginDateTime
      groups {
        id
        name
      }
      customers {
        id
        shortName
        longName
        abbrev
        vehicleGroups {
          id
          name
        }
      }
    }
  }`;

export const fetchUserManagementApiV2 = (pageNumber, pageSize, filters, sort, customerId) => {
  const sortInput = sort ? `sortingInput: { sortBy: "${sort?.colId}", sortAsc: ${Boolean(sort?.sort === 'asc')} }` : '';
  return `{
    users(
      userDtoInput: {
        ${formatUserManagementFilters(filters)}
        customerId: ${customerId ?? null}
        paginationInput: { pageSize: ${pageSize}, pageNumber: ${pageNumber} }
        ${sortInput}
      }
    ) {
      userCount
      users {
        id
        email
        accountStatus
        enabled
        emailVerified
        createdDate
        lastModifiedDate
        lastLoginDateTime
        groups {
          id
          name
        }
        customers {
          id
          shortName
          longName
          abbrev
          vehicleGroups {
            id
            name
          }
        }
      }
    }
  }`;
};
