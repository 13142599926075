import _camelCase from 'lodash/camelCase';
import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import moment from 'moment-timezone';
import {
  CREATE_NEW_ALERT_START,
  CREATE_NEW_ALERT_CANCEL,
  SET_CREATED_ALERT_RULE_NAME,
  TOGGLE_ALL_VEHICLE_IDS,
  SET_GEOFENCE_OCCUPANCY_IDS,
  SET_ALERT_VEHICLE_IDS,
  SET_ALERT_VEHICLE_GROUP_IDS,
  ADD_NOTIFY_EMAIL_CONFIG,
  REMOVE_NOTIFY_EMAIL_CONFIG,
  CREATE_NEW_ALERT_RULE_BEGIN,
  CREATE_NEW_ALERT_RULE_FAIL,
  CREATE_NEW_ALERT_RULE_SUCCESS,
  SET_ALERTS_LIST_SEARCH_QUERY,
  FETCH_ALERTS_LIST_DATA_BEGIN,
  FETCH_ALERTS_LIST_DATA_SUCCESS,
  FETCH_ALERTS_LIST_DATA_FAIL,
  CREATE_ALERT_RULE_PREDICATE,
  REMOVE_ALERT_RULE_PREDICATE,
  SET_NOTIFICATIONS_FREQUENCY,
  LOAD_ALERT_RULE_BEGIN,
  LOAD_ALERT_RULE_SUCCESS,
  LOAD_ALERT_RULE_FAIL,
  UPDATE_ALERT_RULE_BEGIN,
  UPDATE_ALERT_RULE_SUCCESS,
  UPDATE_ALERT_RULE_FAIL,
  TOGGLE_ALERT_DELETE_MODAL,
  DELETE_ALERT_RULE_BEGIN,
  DELETE_ALERT_RULE_SUCCESS,
  DELETE_ALERT_RULE_FAIL,
  TOGGLE_ALERT_NOTIFICATIONS_SCREEN,
  SET_SELECTED_ALERT_PREDICATES,
  FETCH_ABS_FAULT_TYPES_SUCCESS,
  FETCH_LIGHT_FAULT_TYPES_SUCCESS,
  FETCH_COMPONENT_FAULT_TYPES_SUCCESS,
  FETCH_LIGHT_FAULT_TYPES_FAIL,
  FETCH_ABS_FAULT_TYPES_FAIL,
  FETCH_COMPONENT_FAULT_TYPES_FAIL,
  GET_GEOFENCES_SUCCESS,
  GET_GEOFENCES_FAIL,
  SET_ALERT_TYPE_OPTION_DROPDOWN_VALUE,
  ENABLE_ALERT_RULE_BEGIN,
  ENABLE_ALERT_RULE_SUCCESS,
  ENABLE_ALERT_RULE_FAIL,
  DISABLE_ALERT_RULE_BEGIN,
  DISABLE_ALERT_RULE_SUCCESS,
  DISABLE_ALERT_RULE_FAIL,
  SET_ALERT_RULE_DURATION_INPUT_VALUE,
  SET_ALERT_RULE_LOAD_WEIGHT_INPUT_VALUE,
  SET_FUEL_LEVEL_THRESHOLD_VALUE,
  RESET_ALERT_RULE_STATE,
  FETCH_ALL_VEHICLES_BEGIN,
  FETCH_ALL_VEHICLES_SUCCESS,
  FETCH_ALL_VEHICLES_FAIL,
  TOGGLE_ALL_REPORT_GEOFENCES,
  CREATE_YARD_REPORT_CONFIG_FAIL,
  CREATE_YARD_REPORT_CONFIG_SUCCESS,
  CREATE_REPORT_CONFIG_START,
  CREATE_REPORT_CONFIG_CANCEL,
  SET_YARD_REPORT_NAME,
  SET_REPORT_CUSTOMER_DATA,
  SET_YARD_REPORT_VINS,
  SET_YARD_REPORT_VEHICLE_GROUPS,
  TOGGLE_ALL_YARD_REPORT_VEHICLES,
  SET_YARD_REPORT_NOTIFY_EMAIL,
  REMOVE_YARD_REPORT_NOTIFY_EMAIL,
  SET_YARD_REPORT_TIME_SCHEDULES,
  SET_REPORT_GEOFENCE_LOCATIONS,
  CREATE_YARD_REPORT_CONFIG_BEGIN,
  FETCH_ALL_REPORT_CONGIFS_BEGIN,
  FETCH_ALL_REPORT_CONGIFS_SUCCESS,
  FETCH_ALL_REPORT_CONGIFS_FAIL,
  LOAD_YARD_REPORT_CONFIG_BEGIN,
  LOAD_YARD_REPORT_CONFIG_SUCCESS,
  LOAD_YARD_REPORT_CONFIG_FAIL,
  DELETE_YARD_REPORT_CONFIG_BEGIN,
  DELETE_YARD_REPORT_CONFIG_FAIL,
  DELETE_YARD_REPORT_CONFIG_SUCCESS,
  UPDATE_YARD_REPORT_CONFIG_BEGIN,
  UPDATE_YARD_REPORT_CONFIG_FAIL,
  UPDATE_YARD_REPORT_CONFIG_SUCCESS,
  TOGGLE_DELETE_REPORT_CONFIG_MODAL,
  SET_ALERT_RULE_CUSTOMER_DATA,
  FETCH_ALL_DWELL_REPORT_CONFIGS_BEGIN,
  FETCH_ALL_DWELL_REPORT_CONFIGS_FAIL,
  FETCH_ALL_DWELL_REPORT_CONFIGS_SUCCESS,
  CREATE_DWELL_REPORT_CONFIG_BEGIN,
  CREATE_DWELL_REPORT_CONFIG_FAIL,
  CREATE_DWELL_REPORT_CONFIG_SUCCESS,
  UPDATE_DWELL_REPORT_CONFIG_BEGIN,
  UPDATE_DWELL_REPORT_CONFIG_FAIL,
  UPDATE_DWELL_REPORT_CONFIG_SUCCESS,
  LOAD_DWELL_REPORT_CONFIG_BEGIN,
  LOAD_DWELL_REPORT_CONFIG_FAIL,
  LOAD_DWELL_REPORT_CONFIG_SUCCESS,
  DELETE_DWELL_REPORT_CONFIG_BEGIN,
  DELETE_DWELL_REPORT_CONFIG_FAIL,
  DELETE_DWELL_REPORT_CONFIG_SUCCESS,
  SET_TOGGLE_ALL_ASSETS_MODAL,
  UPDATE_YARD_REPORT_CONFIG_STATUS_BEGIN,
  UPDATE_YARD_REPORT_CONFIG_STATUS_FAIL,
  UPDATE_YARD_REPORT_CONFIG_STATUS_SUCCESS,
  UPDATE_DWELL_REPORT_CONFIG_STATUS_BEGIN,
  UPDATE_DWELL_REPORT_CONFIG_STATUS_FAIL,
  UPDATE_DWELL_REPORT_CONFIG_STATUS_SUCCESS,
  OPEN_CREATE_ALERT_DIALOG,
  OPEN_UPDATE_ALERT_DIALOG,
  OPEN_DELETE_ALERT_DIALOG,
  SET_ABS_SUMMARY_NOTIFICATIONS_FREQUENCY_CATEGORY,
  CREATE_REPORT_CONFIG_SUCCESS,
  CREATE_REPORT_CONFIG_BEGIN,
  CREATE_REPORT_CONFIG_FAIL,
  UPDATE_REPORT_CONFIG_BEGIN,
  UPDATE_REPORT_CONFIG_SUCCESS,
  UPDATE_REPORT_CONFIG_FAIL,
  FETCH_REPORT_CONFIGS_SUCCESS,
  FETCH_REPORT_CONFIGS_FAIL,
  FETCH_REPORT_CONFIGS_BEGIN,
  LOAD_REPORT_CONFIG_BEGIN,
  LOAD_REPORT_CONFIG_SUCCESS,
  LOAD_REPORT_CONFIG_FAIL,
  DELETE_REPORT_CONFIG_BEGIN,
  DELETE_REPORT_CONFIG_SUCCESS,
  DELETE_REPORT_CONFIG_FAIL,
  UPDATE_REPORT_CONFIG_STATUS_BEGIN,
  UPDATE_REPORT_CONFIG_STATUS_FAIL,
  UPDATE_REPORT_CONFIG_STATUS_SUCCESS,
  SET_REPORT_CONFIG_SCHEDULE_DETAILS,
  OPEN_ALERT_DIALOG_BOX,
  CLOSE_ALERT_DIALOG_BOX,
  SET_THERMO_KING_FAULT_LEVEL_VALUE,
  SET_REPORT_HISTORY_TIMEFRAME_VALUE,
  CREATE_THERMO_KING_REPORT_BEGIN,
  CREATE_THERMO_KING_REPORT_SUCCESS,
  CREATE_THERMO_KING_REPORT_FAIL,
  SET_GEOFENCE_OCCUPANCY_INVERTED_VALUE,
  SET_ASSET_GEOFENCE_THRESHOLD_TRAILERS_VALUE,
  SET_SELECTED_ASSET_GEOFENCE_THRESHOLD_GEOFENCES,
  allGeofencesSelectOption,
  SET_REPORT_EMAIL_FREQUENCY,
  SET_INCLUDE_SENSOR_DATA_VALUE,
  MILLISECONDS_PER_DAY,
  MOVE_ALERT_BEGIN,
  MOVE_ALERT_FAIL,
  MOVE_ALERT_SUCCESS,
  MOVE_REPORT_BEGIN,
  MOVE_REPORT_SUCCESS,
  MOVE_REPORT_FAIL,
} from '../constants';
import { getDurationPeriodFromMs } from '../utils/timeUtils';
import alertPredicateTypes from '../constants/enums/alertPredicateTypes';
import {
  isDurationPredicateTypeBySensorTypeName,
  getAlertPredicateTypeOptions,
  findLightFaultPredicateByFaultTypeIds,
  isLoadedBogieWeightAlertPredicateType,
  findAlertPredicateTypeByName,
  findTisAlertPredicateByName,
  getSelectableAlertPredicateTypes,
  findReportTypeById,
  findAlertPredicateTypeByIdAndName,
  celsiusToFahrenheit,
} from '../utils/alertUtils';
import reportTimeFrameDays from '../constants/enums/reportTimeFrameDays';
import absSummaryNotificationFrequencies from '../constants/enums/absSummaryNotificationFrequencies';
import { findSelectedGeofencesByGeofenceIds } from '../utils/geofenceUtils';
const browsersTimezone = moment.tz.guess();

const initialState = {
  isCreateAlertDialogOpen: false,
  isUpdateAlertDialogOpen: false,
  isDeleteAlertDialogOpen: false,
  loadAlertRuleId: null,
  alertRule: null,
  alertRules: null,
  error: null,
  loadedAlertRuleOrig: null,
  isCreatingConfig: false,
  isLoadingConfig: false,
  isDeletingConfig: false,
  isAlertDeleteModalActive: false,
  isEditingConfig: false,
  selectAlertRuleTypeGroups: null,
  isLoadingAlertRuleTypeGroups: false,
  alertRuleTypeGroups: null,
  availableVehicles: null,
  isLoadingIndex: true,
  filteredAlertRules: null,
  query: '',
  isAlertPredicateValid: false,
  alertRuleHasBeenUpdated: false,
  selectedAlertSensorType: '',
  alertPredicateType: {},
  alertPredicateSensorTypes: getSelectableAlertPredicateTypes(),
  loadedAlertPredicates: [],
  selectedAlertPredicates: [],
  absFaultTypes: [],
  lightFaultTypes: [],
  lightFaultOptions: getAlertPredicateTypeOptions(alertPredicateTypes.LIGHTS),
  componentFaultTypes: [],
  allGeofences: [],
  geofenceOccupancyIds: [],
  isAlertNotificationsActive: false,
  alertTypeOptionDropdownValue: '',
  alertRuleDurationValue: '',
  alertRuleLoadWeightValue: '',
  fuelLevelThresholdValue: '',
  assetsGeofenceThresholdTrailersValue: '',
  selectedAssetsGeofenceThresholdGeofences: [],
  thermoKingFaultLevelValue: [],
  isTogglingActiveAlertRuleId: null,
  enableAlertError: null,
  fetchAllVehiclesLoading: false,
  fetchAllVehiclesError: null,
  currentReportConfig: null,
  loadedReportConfig: null,
  loadedReportConfigId: null,
  reportConfigType: null,
  allReportingScheduleDays: Object.values(reportTimeFrameDays).map(day => ({ ...day, active: false, timeFrames: [] })),
  yardReportConfigs: [],
  reportConfigLoading: false,
  allReportConfigsLoading: false,
  reportConfigError: false,
  deleteReportConfigModal: false,
  dwellReportConfigs: [],
  isToggleAllAssetsModalActive: false,
  reportSchedules: {
    selectedDays: [],
    selectedTimeFrames: [],
  },
  isTogglingActiveReportConfigId: null,
  isTogglingActiveReportError: null,

  allReportConfigs: [],
  filteredReportConfigs: [],

  alertDialogOpen: false,
  alertDialogTitle: '',
  alertDialogMessage: '',
  isGeofenceOccupancyInvertedValue: false,
  isMovingAlert: false,
  isMovingReport: false,
};

const actionMappings = {
  [CREATE_NEW_ALERT_START]: '_createNewAlertStart',
  [CREATE_NEW_ALERT_CANCEL]: '_createNewAlertCancel',
  [SET_CREATED_ALERT_RULE_NAME]: '_setCreatedAlertRuleName',
  [TOGGLE_ALL_VEHICLE_IDS]: '_toggleAllVehicleIds',
  [SET_GEOFENCE_OCCUPANCY_IDS]: '_setGeofenceOccupancyIds',
  [SET_ALERT_VEHICLE_IDS]: '_setAlertVehicleIds',
  [SET_ALERT_VEHICLE_GROUP_IDS]: '_setAlertVehicleGroupIds',
  [ADD_NOTIFY_EMAIL_CONFIG]: '_addNotifyEmailConfig',
  [REMOVE_NOTIFY_EMAIL_CONFIG]: '_removeNotifyEmailConfig',
  [CREATE_NEW_ALERT_RULE_BEGIN]: '_createNewAlertRuleBegin',
  [CREATE_NEW_ALERT_RULE_FAIL]: '_createNewAlertRuleFail',
  [CREATE_NEW_ALERT_RULE_SUCCESS]: '_createNewAlertRuleSuccess',
  [OPEN_CREATE_ALERT_DIALOG]: '_openCreateAlertDialog',
  [OPEN_UPDATE_ALERT_DIALOG]: '_openUpdateAlertDialog',
  [OPEN_DELETE_ALERT_DIALOG]: '_openDeleteAlertDialog',
  [SET_ALERTS_LIST_SEARCH_QUERY]: '_setAlertsListSearchQuery',
  [FETCH_ALERTS_LIST_DATA_BEGIN]: '_fetchAlertsListDataBegin',
  [FETCH_ALERTS_LIST_DATA_SUCCESS]: '_fetchAlertsListDataSuccess',
  [FETCH_ALERTS_LIST_DATA_FAIL]: '_fetchAlertsListDataFail',
  [CREATE_ALERT_RULE_PREDICATE]: '_createAlertRulePredicate',
  [REMOVE_ALERT_RULE_PREDICATE]: '_removeAlertRulePredicate',
  [SET_NOTIFICATIONS_FREQUENCY]: '_setNotificationsFrequency',
  [SET_ALERT_RULE_CUSTOMER_DATA]: '_setAlertRuleCustomerData',
  [LOAD_ALERT_RULE_BEGIN]: '_loadAlertRuleBegin',
  [LOAD_ALERT_RULE_SUCCESS]: '_loadAlertRuleSuccess',
  [LOAD_ALERT_RULE_FAIL]: '_loadAlertRuleFail',
  [UPDATE_ALERT_RULE_BEGIN]: '_updateAlertRuleBegin',
  [UPDATE_ALERT_RULE_SUCCESS]: '_updateAlertRuleSuccess',
  [UPDATE_ALERT_RULE_FAIL]: '_updateAlertRuleFail',
  [TOGGLE_ALERT_DELETE_MODAL]: '_toggleAlertDeleteModal',
  [DELETE_ALERT_RULE_BEGIN]: '_deleteAlertRuleBegin',
  [MOVE_ALERT_BEGIN]: '_moveAlertBegin',
  [MOVE_REPORT_BEGIN]: '_moveReportBegin',
  [DELETE_ALERT_RULE_SUCCESS]: '_deleteAlertRuleSuccess',
  [MOVE_ALERT_SUCCESS]: '_moveAlertSuccess',
  [MOVE_REPORT_SUCCESS]: '_moveReportSuccess',
  [DELETE_ALERT_RULE_FAIL]: '_deleteAlertRuleFail',
  [MOVE_ALERT_FAIL]: '_moveAlertFail',
  [MOVE_REPORT_FAIL]: '_moveReportFail',
  [TOGGLE_ALERT_NOTIFICATIONS_SCREEN]: '_toggleAlertNotificationsScreen',
  [SET_SELECTED_ALERT_PREDICATES]: '_setSelectedAlertPredicates',
  [FETCH_ABS_FAULT_TYPES_SUCCESS]: '_fetchAbsFaultTypesSuccess',
  [FETCH_LIGHT_FAULT_TYPES_SUCCESS]: '_fetchLightFaultTypesSuccess',
  [FETCH_COMPONENT_FAULT_TYPES_SUCCESS]: '_fetchComponentFaultTypesSuccess',
  [FETCH_ABS_FAULT_TYPES_FAIL]: '_fetchAbsFaultTypesFail',
  [FETCH_COMPONENT_FAULT_TYPES_FAIL]: '_fetchComponentFaultTypesFail',
  [FETCH_LIGHT_FAULT_TYPES_FAIL]: '_fetchLightFaultTypesFail',
  [GET_GEOFENCES_SUCCESS]: '_getGeofencesSuccess',
  [GET_GEOFENCES_FAIL]: '_getGeofencesFail',
  [SET_ALERT_TYPE_OPTION_DROPDOWN_VALUE]: '_setAlertTypeOptionDropdownValue',
  [ENABLE_ALERT_RULE_BEGIN]: '_enableAlertRuleBegin',
  [ENABLE_ALERT_RULE_SUCCESS]: '_enableAlertRuleSuccess',
  [ENABLE_ALERT_RULE_FAIL]: '_enableAlertRuleFail',
  [DISABLE_ALERT_RULE_BEGIN]: '_disableAlertRuleBegin',
  [DISABLE_ALERT_RULE_SUCCESS]: '_disableAlertRuleSuccess',
  [DISABLE_ALERT_RULE_FAIL]: '_disableAlertRuleFail',
  [SET_ALERT_RULE_DURATION_INPUT_VALUE]: '_setAlertRuleDurationInputValue',
  [SET_ALERT_RULE_LOAD_WEIGHT_INPUT_VALUE]: '_setAlertRuleLoadWeightInputValue',
  [SET_FUEL_LEVEL_THRESHOLD_VALUE]: '_setFuelLevelThresholdValue',
  [SET_ASSET_GEOFENCE_THRESHOLD_TRAILERS_VALUE]: '_setAssetsGeofenceThresholdTrailersValue',
  [SET_SELECTED_ASSET_GEOFENCE_THRESHOLD_GEOFENCES]: '_setSelectedAssetsGeofenceThresholdGeofences',
  [SET_THERMO_KING_FAULT_LEVEL_VALUE]: '_setThermoKingFaultLevelValue',
  [RESET_ALERT_RULE_STATE]: '_resetAlertRuleState',
  [FETCH_ALL_VEHICLES_BEGIN]: '_fetchAllVehiclesBegin',
  [FETCH_ALL_VEHICLES_SUCCESS]: '_fetchAllVehiclesSuccess',
  [FETCH_ALL_VEHICLES_FAIL]: '_fetchAllVehiclesFail',
  [CREATE_REPORT_CONFIG_START]: '_createReportConfigStart',
  [CREATE_REPORT_CONFIG_CANCEL]: '_createReportConfigCancel',
  [SET_YARD_REPORT_NAME]: '_setYardReportName',
  [SET_REPORT_CUSTOMER_DATA]: '_setReportCustomerData',
  [SET_YARD_REPORT_VINS]: '_setYardReportVins',
  [TOGGLE_ALL_YARD_REPORT_VEHICLES]: '_toggleAllYardReportVehicles',
  [SET_YARD_REPORT_VEHICLE_GROUPS]: '_setYardReportVehicleGroups',
  [SET_YARD_REPORT_NOTIFY_EMAIL]: '_setYardReportNotifyEmail',
  [REMOVE_YARD_REPORT_NOTIFY_EMAIL]: '_removeYardReportNotifyEmail',
  [SET_YARD_REPORT_TIME_SCHEDULES]: '_setYardReportTimeSchedules',
  [SET_REPORT_GEOFENCE_LOCATIONS]: '_setReportGeofenceLocations',
  [TOGGLE_ALL_REPORT_GEOFENCES]: '_toggleAllReportGeofences',
  [CREATE_YARD_REPORT_CONFIG_BEGIN]: '_createYardConfigReportBegin',
  [CREATE_YARD_REPORT_CONFIG_FAIL]: '_createYardConfigReportFail',
  [CREATE_YARD_REPORT_CONFIG_SUCCESS]: '_createYardConfigReportSuccess',
  [FETCH_ALL_REPORT_CONGIFS_BEGIN]: '_fetchAllReportConfigsBegin',
  [FETCH_ALL_REPORT_CONGIFS_SUCCESS]: '_fetchAllReportConfigsSuccess',
  [FETCH_ALL_REPORT_CONGIFS_FAIL]: '_fetchAllReportConfigsFail',
  [LOAD_YARD_REPORT_CONFIG_BEGIN]: '_loadYardReportConfigBegin',
  [LOAD_YARD_REPORT_CONFIG_SUCCESS]: '_loadYardReportConfigSuccess',
  [LOAD_YARD_REPORT_CONFIG_FAIL]: '_loadYardReportConfigFail',
  [DELETE_YARD_REPORT_CONFIG_BEGIN]: '_deleteYardReportConfigBegin',
  [DELETE_YARD_REPORT_CONFIG_FAIL]: '_deleteYardReportConfigFail',
  [DELETE_YARD_REPORT_CONFIG_SUCCESS]: '_deleteYardReportConfigSuccess',
  [UPDATE_YARD_REPORT_CONFIG_BEGIN]: '_updateYardReportConfigBegin',
  [UPDATE_YARD_REPORT_CONFIG_FAIL]: '_updateYardReportConfigFail',
  [UPDATE_YARD_REPORT_CONFIG_SUCCESS]: '_updateYardReportConfigSuccess',
  [TOGGLE_DELETE_REPORT_CONFIG_MODAL]: '_toggleDeleteReportConfigModal',
  [FETCH_ALL_DWELL_REPORT_CONFIGS_BEGIN]: '_fetchAllDwellReportConfigsBegin',
  [FETCH_ALL_DWELL_REPORT_CONFIGS_FAIL]: '_fetchAllDwellReportConfigsFail',
  [FETCH_ALL_DWELL_REPORT_CONFIGS_SUCCESS]: '_fetchAllDwellReportConfigsSuccess',
  [CREATE_DWELL_REPORT_CONFIG_BEGIN]: '_createDwellReportConfigBegin',
  [CREATE_DWELL_REPORT_CONFIG_FAIL]: '_createDwellReportConfigFail',
  [CREATE_DWELL_REPORT_CONFIG_SUCCESS]: '_createDwellReportConfigSuccess',
  [UPDATE_DWELL_REPORT_CONFIG_BEGIN]: '_updateDwellReportConfigBegin',
  [UPDATE_DWELL_REPORT_CONFIG_FAIL]: '_updateDwellReportConfigFail',
  [UPDATE_DWELL_REPORT_CONFIG_SUCCESS]: '_updateDwellReportConfigSuccess',
  [LOAD_DWELL_REPORT_CONFIG_BEGIN]: '_loadDwellReportConfigBegin',
  [LOAD_DWELL_REPORT_CONFIG_FAIL]: '_loadDwellReportConfigFail',
  [LOAD_DWELL_REPORT_CONFIG_SUCCESS]: '_loadDwellReportConfigSuccess',
  [DELETE_DWELL_REPORT_CONFIG_BEGIN]: '_deleteDwellReportConfigBegin',
  [DELETE_DWELL_REPORT_CONFIG_FAIL]: '_deleteDwellReportConfigFail',
  [DELETE_DWELL_REPORT_CONFIG_SUCCESS]: '_deleteDwellReportConfigSuccess',
  [SET_TOGGLE_ALL_ASSETS_MODAL]: '_setToggleAllAssetsModal',
  [UPDATE_YARD_REPORT_CONFIG_STATUS_BEGIN]: '_updateYardReportConfigStatusBegin',
  [UPDATE_YARD_REPORT_CONFIG_STATUS_FAIL]: '_updateYardReportConfigStatusFail',
  [UPDATE_YARD_REPORT_CONFIG_STATUS_SUCCESS]: '_updateYardReportConfigStatusSuccess',
  [UPDATE_DWELL_REPORT_CONFIG_STATUS_BEGIN]: '_updateDwellReportConfigStatusBegin',
  [UPDATE_DWELL_REPORT_CONFIG_STATUS_FAIL]: '_updateDwellReportConfigStatusFail',
  [UPDATE_DWELL_REPORT_CONFIG_STATUS_SUCCESS]: '_updateDwellReportConfigStatusSuccess',
  [SET_ABS_SUMMARY_NOTIFICATIONS_FREQUENCY_CATEGORY]: '_setAbsSummaryNotificationsFrequenctCategory',
  [SET_REPORT_HISTORY_TIMEFRAME_VALUE]: '_setReportHistoryTimeframeValue',
  [FETCH_REPORT_CONFIGS_BEGIN]: '_fetchReportConfigsBegin',
  [FETCH_REPORT_CONFIGS_SUCCESS]: '_fetchReportConfigsSuccess',
  [FETCH_REPORT_CONFIGS_FAIL]: '_fetchReportConfigsFail',
  [CREATE_REPORT_CONFIG_BEGIN]: '_createReportConfigBegin',
  [CREATE_REPORT_CONFIG_SUCCESS]: '_createReportConfigSuccess',
  [CREATE_REPORT_CONFIG_FAIL]: '_createReportConfigFail',
  [UPDATE_REPORT_CONFIG_BEGIN]: '_updateReportConfigBegin',
  [UPDATE_REPORT_CONFIG_SUCCESS]: '_updateReportConfigSuccess',
  [UPDATE_REPORT_CONFIG_FAIL]: '_updateReportConfigFail',
  [LOAD_REPORT_CONFIG_BEGIN]: '_loadReportConfigBegin',
  [LOAD_REPORT_CONFIG_SUCCESS]: '_loadReportConfigSuccess',
  [LOAD_REPORT_CONFIG_FAIL]: '_loadReportConfigFail',
  [DELETE_REPORT_CONFIG_BEGIN]: '_deleteReportConfigBegin',
  [DELETE_REPORT_CONFIG_SUCCESS]: '_deleteReportConfigSuccess',
  [DELETE_REPORT_CONFIG_FAIL]: '_deleteReportConfigFail',
  [UPDATE_REPORT_CONFIG_STATUS_BEGIN]: '_updateReportConfigStatusBegin',
  [UPDATE_REPORT_CONFIG_STATUS_SUCCESS]: '_updateReportConfigStatusSuccess',
  [UPDATE_REPORT_CONFIG_STATUS_FAIL]: '_updateReportConfigStatusFail',
  [SET_REPORT_CONFIG_SCHEDULE_DETAILS]: '_setReportConfigScheduleDetails',
  [OPEN_ALERT_DIALOG_BOX]: '_openAlertDialogBox',
  [CLOSE_ALERT_DIALOG_BOX]: '_closeAlertDialogBox',
  [CREATE_THERMO_KING_REPORT_BEGIN]: '_createThermoKingReportBegin',
  [CREATE_THERMO_KING_REPORT_SUCCESS]: '_createThermoKingReportSuccess',
  [CREATE_THERMO_KING_REPORT_FAIL]: '_createThermoKingReportFail',
  [SET_GEOFENCE_OCCUPANCY_INVERTED_VALUE]: '_setGeofenceOccupancyInvertedValue',
  [SET_REPORT_EMAIL_FREQUENCY]: '_setReportEmailFrequency',
  [SET_INCLUDE_SENSOR_DATA_VALUE]: '_setIncludeSensorDataValue',
};

const formatInitialSelectedPredicates = (alertRule, lightFaultTypes, lightFaultOptions, allGeofences) => {
  let formattedInitialSelectedAlertPredicates = [];
  const initialSelectedAlertPredicates = alertRule.alertPredicates;
  initialSelectedAlertPredicates.forEach(alertPredicate => {
    const alertPredicateTypeName = _get(alertPredicate, 'alertPredicateTypeName');
    const alertPredicateTypeId = alertPredicate?.alertPredicateTypeId ?? null;
    const alertPredicateType = findAlertPredicateTypeByIdAndName(alertPredicateTypeId, alertPredicateTypeName);
    const selectedTetherStatusDuration = alertPredicate.tetherStateDurationMs ? getDurationPeriodFromMs(alertPredicate.tetherStateDurationMs) : null;
    const selectedDoorStatusDuration = alertPredicate.doorStatusDurationMs ? getDurationPeriodFromMs(alertPredicate.doorStatusDurationMs) : null;
    const selectedGeofenceDuration = alertPredicate.geofenceOccupancyDurationMs ? getDurationPeriodFromMs(alertPredicate.geofenceOccupancyDurationMs) : null;
    const geofenceEnterEvents = alertPredicate.geofenceEnterEvents;
    const geofenceExitEvents = alertPredicate.geofenceExitEvents;
    const selectedPredicateContext = {
      alertPredicate,
      alertPredicateTypeName,
      formattedInitialSelectedAlertPredicates,
      selectedTetherStatusDuration,
      selectedDoorStatusDuration,
      lightFaultTypes,
      selectedGeofenceDuration,
      lightFaultOptions,
      alertPredicateType,
      geofenceEnterEvents,
      geofenceExitEvents,
      allGeofences,
    };
    handleAllSensorPredicates(selectedPredicateContext);
  });

  return formattedInitialSelectedAlertPredicates;
};

const handleAllSensorPredicates = selectedPredicateContext => {
  handleAbsFaultsFormattedPredicates(selectedPredicateContext);
  handleComponentFaultsFormattedPredicates(selectedPredicateContext);
  handleLoadStatusChangesFormattedPredicates(selectedPredicateContext);
  handleTetherStatusChangeFormattedPredicates(selectedPredicateContext);
  handleTetherStatusDurationFormattedPredicates(selectedPredicateContext);
  handleDoorStatusChangeFormattedPredicates(selectedPredicateContext);
  handleDoorStatusDurationFormattedPredicates(selectedPredicateContext);
  handleLightFaultFormattedPredicates(selectedPredicateContext);
  handleTisStatusChangeFormattedPredicates(selectedPredicateContext);
  handleSensorBoxBatteryFormattedPredicates(selectedPredicateContext);
  handleNetBogieWeightThresholdFormattedPredicates(selectedPredicateContext);
  handleGeofenceBoundaryCrossingFormattedPredicates(selectedPredicateContext);
  handleGeofenceOccupancyDurationFormattedPredicates(selectedPredicateContext);
  handleGeofenceOccupancyFormattedPredicates(selectedPredicateContext);
  handleReeferUnitFuelFormattedPredicates(selectedPredicateContext);
  handleReeferAlarmFormattedPredicates(selectedPredicateContext);
  handleAssetGeofenceThresholdPredicates(selectedPredicateContext);
  handleTowPredicates(selectedPredicateContext);
  handleTpmsThresholdFormattedPredicates(selectedPredicateContext);
  handleTireTemperatureThresholdFormattedPredicates(selectedPredicateContext);
};

const handleAbsFaultsFormattedPredicates = selectedPredicateContext => {
  const { alertPredicate, formattedInitialSelectedAlertPredicates, alertPredicateType } = selectedPredicateContext;
  if (alertPredicateType !== alertPredicateTypes.ABS_FAULT) {
    return;
  }

  if (alertPredicate.anyAbsSystemFault) {
    formattedInitialSelectedAlertPredicates.push({
      selectedAlertPredicateOption: alertPredicateTypes.ABS_FAULT.predicateOptions.ABS_FAULTS,
      alertPredicateType: alertPredicateTypes.ABS_FAULT,
    });
  }
  return formattedInitialSelectedAlertPredicates;
};

const handleComponentFaultsFormattedPredicates = selectedPredicateContext => {
  const { alertPredicate, alertPredicateType, formattedInitialSelectedAlertPredicates } = selectedPredicateContext;
  if (alertPredicateType !== alertPredicateTypes.COMPONENT_FAULT) {
    return;
  }

  if (alertPredicate.anyComponentFault) {
    formattedInitialSelectedAlertPredicates.push({
      selectedAlertPredicateOption: alertPredicateTypes.ABS_FAULT.predicateOptions.COMPONENT_FAULTS,
      alertPredicateType: alertPredicateTypes.ABS_FAULT,
    });
  }
  return formattedInitialSelectedAlertPredicates;
};

const handleLoadStatusChangesFormattedPredicates = selectedPredicateContext => {
  const { alertPredicate, alertPredicateType, formattedInitialSelectedAlertPredicates } = selectedPredicateContext;
  if (alertPredicateType !== alertPredicateTypes.LOAD_STATUS_CHANGE) {
    return;
  } else if (alertPredicate.loadStatusLoaded && alertPredicate.loadStatusUnloaded) {
    formattedInitialSelectedAlertPredicates.push({
      selectedAlertPredicateOption: alertPredicateTypes.LOAD_STATUS_CHANGE.predicateOptions.ANY_LOAD_STATUS,
      alertPredicateType: alertPredicateTypes.LOAD_STATUS_CHANGE,
    });
  } else if (alertPredicate.loadStatusLoaded) {
    formattedInitialSelectedAlertPredicates.push({
      selectedAlertPredicateOption: alertPredicateTypes.LOAD_STATUS_CHANGE.predicateOptions.LOADED,
      alertPredicateType: alertPredicateTypes.LOAD_STATUS_CHANGE,
    });
  } else if (alertPredicate.loadStatusUnloaded) {
    formattedInitialSelectedAlertPredicates.push({
      selectedAlertPredicateOption: alertPredicateTypes.LOAD_STATUS_CHANGE.predicateOptions.UNLOADED,
      alertPredicateType: alertPredicateTypes.LOAD_STATUS_CHANGE,
    });
  }
  return formattedInitialSelectedAlertPredicates;
};

const handleTetherStatusChangeFormattedPredicates = selectedPredicateContext => {
  const { alertPredicate, alertPredicateType, formattedInitialSelectedAlertPredicates } = selectedPredicateContext;
  if (alertPredicateType !== alertPredicateTypes.TETHER_STATUS_CHANGE) {
    return;
  } else if (alertPredicate.tethered && alertPredicate.untethered) {
    formattedInitialSelectedAlertPredicates.push({
      selectedAlertPredicateOption: alertPredicateTypes.TETHER_STATUS_CHANGE.predicateOptions.ANY_TETHER_STATUS,
      alertPredicateType: alertPredicateTypes.TETHER_STATUS_CHANGE,
    });
  } else if (alertPredicate.tethered) {
    formattedInitialSelectedAlertPredicates.push({
      selectedAlertPredicateOption: alertPredicateTypes.TETHER_STATUS_CHANGE.predicateOptions.TETHERED,
      alertPredicateType: alertPredicateTypes.TETHER_STATUS_CHANGE,
    });
  } else if (alertPredicate.untethered) {
    formattedInitialSelectedAlertPredicates.push({
      selectedAlertPredicateOption: alertPredicateTypes.TETHER_STATUS_CHANGE.predicateOptions.UNTETHERED,
      alertPredicateType: alertPredicateTypes.TETHER_STATUS_CHANGE,
    });
  }
  return formattedInitialSelectedAlertPredicates;
};

const handleTetherStatusDurationFormattedPredicates = selectedPredicateContext => {
  const { alertPredicate, alertPredicateType, formattedInitialSelectedAlertPredicates, selectedTetherStatusDuration } = selectedPredicateContext;
  if (alertPredicateType !== alertPredicateTypes.TETHER_STATUS_DURATION) {
    return;
  } else if (alertPredicate.tethered) {
    formattedInitialSelectedAlertPredicates.push({
      selectedAlertPredicateOption: alertPredicateTypes.TETHER_STATUS_DURATION.predicateOptions.TETHERED,
      displayDurationFields: true,
      durationMs: selectedTetherStatusDuration ? selectedTetherStatusDuration.durationMs : '',
      selectedDurationType: selectedTetherStatusDuration ? selectedTetherStatusDuration.selectedDurationType : '',
      alertPredicateType: alertPredicateTypes.TETHER_STATUS_DURATION,
    });
  } else if (alertPredicate.untethered) {
    formattedInitialSelectedAlertPredicates.push({
      selectedAlertPredicateOption: alertPredicateTypes.TETHER_STATUS_DURATION.predicateOptions.UNTETHERED,
      displayDurationFields: true,
      durationMs: selectedTetherStatusDuration ? selectedTetherStatusDuration.durationMs : '',
      selectedDurationType: selectedTetherStatusDuration ? selectedTetherStatusDuration.selectedDurationType : '',
      alertPredicateType: alertPredicateTypes.TETHER_STATUS_DURATION,
    });
  }
  return formattedInitialSelectedAlertPredicates;
};

const handleDoorStatusChangeFormattedPredicates = selectedPredicateContext => {
  const { alertPredicate, alertPredicateType, formattedInitialSelectedAlertPredicates } = selectedPredicateContext;
  if (alertPredicateType !== alertPredicateTypes.DOOR_STATUS_CHANGE) {
    return;
  } else if (alertPredicate.doorOpened && alertPredicate.doorClosed) {
    formattedInitialSelectedAlertPredicates.push({
      selectedAlertPredicateOption: alertPredicateTypes.DOOR_STATUS_CHANGE.predicateOptions.ANY_DOOR_STATUS,
      alertPredicateType: alertPredicateTypes.DOOR_STATUS_CHANGE,
    });
  } else if (alertPredicate.doorOpened) {
    formattedInitialSelectedAlertPredicates.push({
      selectedAlertPredicateOption: alertPredicateTypes.DOOR_STATUS_CHANGE.predicateOptions.OPEN,
      alertPredicateType: alertPredicateTypes.DOOR_STATUS_CHANGE,
    });
  } else if (alertPredicate.doorClosed) {
    formattedInitialSelectedAlertPredicates.push({
      selectedAlertPredicateOption: alertPredicateTypes.DOOR_STATUS_CHANGE.predicateOptions.CLOSE,
      alertPredicateType: alertPredicateTypes.DOOR_STATUS_CHANGE,
    });
  }
  return formattedInitialSelectedAlertPredicates;
};

const handleDoorStatusDurationFormattedPredicates = selectedPredicateContext => {
  const { alertPredicate, alertPredicateType, formattedInitialSelectedAlertPredicates, selectedDoorStatusDuration } = selectedPredicateContext;
  if (alertPredicateType !== alertPredicateTypes.DOOR_STATUS_DURATION) {
    return;
  } else if (alertPredicate.doorOpened) {
    formattedInitialSelectedAlertPredicates.push({
      selectedAlertPredicateOption: alertPredicateTypes.DOOR_STATUS_DURATION.predicateOptions.OPEN,
      displayDurationFields: true,
      durationMs: selectedDoorStatusDuration ? selectedDoorStatusDuration.durationMs : '',
      selectedDurationType: selectedDoorStatusDuration ? selectedDoorStatusDuration.selectedDurationType : '',
      alertPredicateType: alertPredicateTypes.DOOR_STATUS_DURATION,
    });
  } else if (alertPredicate.doorClosed) {
    formattedInitialSelectedAlertPredicates.push({
      selectedAlertPredicateOption: alertPredicateTypes.DOOR_STATUS_DURATION.predicateOptions.CLOSE,
      displayDurationFields: true,
      durationMs: selectedDoorStatusDuration ? selectedDoorStatusDuration.durationMs : '',
      selectedDurationType: selectedDoorStatusDuration ? selectedDoorStatusDuration.selectedDurationType : '',
      alertPredicateType: alertPredicateTypes.DOOR_STATUS_DURATION,
    });
  }
  return formattedInitialSelectedAlertPredicates;
};

const handleLightFaultFormattedPredicates = selectedPredicateContext => {
  const { alertPredicate, alertPredicateType, formattedInitialSelectedAlertPredicates, lightFaultOptions } = selectedPredicateContext;
  if (alertPredicateType !== alertPredicateTypes.LIGHT_FAULT && alertPredicateType !== alertPredicateTypes.LIGHTS) {
    return;
  } else if (alertPredicate.anyLightFault) {
    formattedInitialSelectedAlertPredicates.push({
      selectedAlertPredicateOption: alertPredicateTypes.LIGHTS.predicateOptions.ANY_LIGHTS_STATUS,
      alertPredicateType: alertPredicateTypes.LIGHTS,
    });
  } else if (alertPredicate.lightFaultTypeIds && !_isEmpty(alertPredicate.lightFaultTypeIds)) {
    const lightFault = findLightFaultPredicateByFaultTypeIds(lightFaultOptions, alertPredicate);
    formattedInitialSelectedAlertPredicates.push({
      selectedAlertPredicateOption: lightFault,
      alertPredicateType: alertPredicateTypes.LIGHTS,
    });
  }
  return formattedInitialSelectedAlertPredicates;
};

const handleTisStatusChangeFormattedPredicates = selectedPredicateContext => {
  const { alertPredicate, alertPredicateType, formattedInitialSelectedAlertPredicates } = selectedPredicateContext;
  if (alertPredicateType !== alertPredicateTypes.TIS_STATUS_CHANGE) {
    return;
  } else if (alertPredicate.anyTisStatus) {
    formattedInitialSelectedAlertPredicates.push({
      selectedAlertPredicateOption: alertPredicateTypes.TIS_STATUS_CHANGE.predicateOptions.ANY_TIS_STATUS,
      alertPredicateType: alertPredicateTypes.TIS_STATUS_CHANGE,
    });
  } else if (alertPredicate.tisStatuses && !_isEmpty(alertPredicate.tisStatuses)) {
    alertPredicate.tisStatuses.forEach(tisStatus => {
      formattedInitialSelectedAlertPredicates.push({
        selectedAlertPredicateOption: findTisAlertPredicateByName(tisStatus),
        alertPredicateType: alertPredicateTypes.TIS_STATUS_CHANGE,
      });
    });
  }
  return formattedInitialSelectedAlertPredicates;
};

const handleSensorBoxBatteryFormattedPredicates = selectedPredicateContext => {
  const { alertPredicate, alertPredicateType, formattedInitialSelectedAlertPredicates } = selectedPredicateContext;
  if (alertPredicateType !== alertPredicateTypes.SENSOR_BOX_BATTERY_STATUS_CHANGE) {
    return;
  } else if (alertPredicate.sensorBoxBatteryRed && alertPredicate.sensorBoxBatteryYellow) {
    formattedInitialSelectedAlertPredicates.push({
      selectedAlertPredicateOption: alertPredicateTypes.SENSOR_BOX_BATTERY_STATUS_CHANGE.predicateOptions.ANY_LOW_VOLTAGE_STATUS,
      alertPredicateType: alertPredicateTypes.SENSOR_BOX_BATTERY_STATUS_CHANGE,
    });
  } else if (alertPredicate.sensorBoxBatteryYellow) {
    formattedInitialSelectedAlertPredicates.push({
      selectedAlertPredicateOption: alertPredicateTypes.SENSOR_BOX_BATTERY_STATUS_CHANGE.predicateOptions.CAUTION_STATUS,
      alertPredicateType: alertPredicateTypes.SENSOR_BOX_BATTERY_STATUS_CHANGE,
    });
  } else if (alertPredicate.sensorBoxBatteryRed) {
    formattedInitialSelectedAlertPredicates.push({
      selectedAlertPredicateOption: alertPredicateTypes.SENSOR_BOX_BATTERY_STATUS_CHANGE.predicateOptions.CRITICAL_STATUS,
      alertPredicateType: alertPredicateTypes.SENSOR_BOX_BATTERY_STATUS_CHANGE,
    });
  }
  return formattedInitialSelectedAlertPredicates;
};

const handleNetBogieWeightThresholdFormattedPredicates = selectedPredicateContext => {
  const { alertPredicate, alertPredicateType, formattedInitialSelectedAlertPredicates } = selectedPredicateContext;
  if (alertPredicateType !== alertPredicateTypes.NET_BOGIE_WEIGHT_THRESHOLD) {
    return;
  }
  formattedInitialSelectedAlertPredicates.push({
    selectedAlertPredicateOption: '',
    displayDurationFields: true,
    durationMs: alertPredicate.netBogieWeightLbs,
    selectedDurationType: '',
    alertPredicateType: alertPredicateTypes.NET_BOGIE_WEIGHT_THRESHOLD,
  });
  return formattedInitialSelectedAlertPredicates;
};

const handleGeofenceBoundaryCrossingFormattedPredicates = selectedPredicateContext => {
  const { alertPredicate, alertPredicateType, formattedInitialSelectedAlertPredicates, allGeofences } = selectedPredicateContext;
  if (alertPredicateType !== alertPredicateTypes.GEOFENCE_BOUNDARY_CROSSING) {
    return;
  } else if (alertPredicate.anyCustomerGeofence) {
    const anyCustomerGeofenceName = alertPredicateTypes.GEOFENCE_BOUNDARY_CROSSING.predicateOptions.ALL_GEOFENCES.name;
    formattedInitialSelectedAlertPredicates.push({
      selectedAlertPredicateOption: { label: anyCustomerGeofenceName, value: anyCustomerGeofenceName },
      alertPredicateType: alertPredicateTypes.GEOFENCE_BOUNDARY_CROSSING,
      geofenceEnterEvents: alertPredicate.geofenceEnterEvents,
      geofenceExitEvents: alertPredicate.geofenceExitEvents,
    });
  } else if (!_isEmpty(alertPredicate.geofenceIds)) {
    const selectedGeofences = [];
    alertPredicate.geofenceIds &&
      alertPredicate.geofenceIds.forEach(geofenceId => {
        const selectedGeofence = allGeofences.find(geofence => geofence.id === geofenceId);
        selectedGeofences.push({ label: selectedGeofence?.name, value: selectedGeofence?.name });
      });
    formattedInitialSelectedAlertPredicates.push({
      selectedAlertPredicateOption: selectedGeofences,
      alertPredicateType: alertPredicateTypes.GEOFENCE_BOUNDARY_CROSSING,
      geofenceEnterEvents: alertPredicate.geofenceEnterEvents,
      geofenceExitEvents: alertPredicate.geofenceExitEvents,
    });
  }
  return formattedInitialSelectedAlertPredicates;
};

const handleGeofenceOccupancyDurationFormattedPredicates = selectedPredicateContext => {
  const { alertPredicate, alertPredicateType, formattedInitialSelectedAlertPredicates, selectedGeofenceDuration, allGeofences } = selectedPredicateContext;
  if (alertPredicateType !== alertPredicateTypes.GEOFENCE_OCCUPANCY_DURATION) {
    return;
  } else if (alertPredicate.anyCustomerGeofence) {
    const anyCustomerGeofenceName = alertPredicateTypes.GEOFENCE_OCCUPANCY.predicateOptions.ALL_GEOFENCES.name;
    formattedInitialSelectedAlertPredicates.push({
      selectedAlertPredicateOption: { label: anyCustomerGeofenceName, value: anyCustomerGeofenceName },
      displayDurationFields: true,
      durationMs: selectedGeofenceDuration ? selectedGeofenceDuration.durationMs : '',
      selectedDurationType: selectedGeofenceDuration ? selectedGeofenceDuration.selectedDurationType : '',
      alertPredicateType: alertPredicateTypes.GEOFENCE_OCCUPANCY_DURATION,
    });
  } else if (!_isEmpty(alertPredicate.geofenceIds)) {
    const selectedGeofences = [];
    alertPredicate.geofenceIds &&
      alertPredicate.geofenceIds.forEach(geofenceId => {
        const selectedGeofence = allGeofences.find(geofence => geofence.id === geofenceId);
        selectedGeofences.push({ label: selectedGeofence?.name, value: selectedGeofence?.name });
      });
    formattedInitialSelectedAlertPredicates.push({
      selectedAlertPredicateOption: selectedGeofences,
      displayDurationFields: true,
      durationMs: selectedGeofenceDuration ? selectedGeofenceDuration.durationMs : '',
      selectedDurationType: selectedGeofenceDuration ? selectedGeofenceDuration.selectedDurationType : '',
      alertPredicateType: alertPredicateTypes.GEOFENCE_OCCUPANCY_DURATION,
    });
  }
  return formattedInitialSelectedAlertPredicates;
};

const handleGeofenceOccupancyFormattedPredicates = selectedPredicateContext => {
  const { alertPredicateType, formattedInitialSelectedAlertPredicates, geofenceOccupancyIds, alertPredicate, allGeofences } = selectedPredicateContext;
  if (alertPredicateType !== alertPredicateTypes.GEOFENCE_OCCUPANCY) {
    return;
  } else if (alertPredicate.anyCustomerGeofence) {
    const anyCustomerGeofenceName = alertPredicateTypes.GEOFENCE_OCCUPANCY.predicateOptions.ALL_GEOFENCES.name;
    formattedInitialSelectedAlertPredicates.push({
      selectedAlertPredicateOption: { label: anyCustomerGeofenceName, value: anyCustomerGeofenceName },
      alertPredicateType: alertPredicateTypes.GEOFENCE_OCCUPANCY,
    });
  } else if (!_isEmpty(geofenceOccupancyIds) || !_isEmpty(alertPredicate.geofenceIds)) {
    const selectedGeofences = [];
    alertPredicate.geofenceIds &&
      alertPredicate.geofenceIds.forEach(geofenceId => {
        const selectedGeofence = allGeofences.find(geofence => geofence.id === geofenceId);
        selectedGeofences.push({ label: selectedGeofence?.name, value: selectedGeofence?.name });
      });
    formattedInitialSelectedAlertPredicates.push({
      selectedAlertPredicateOption: selectedGeofences,
      alertPredicateType: alertPredicateTypes.GEOFENCE_OCCUPANCY,
    });
  }
  return formattedInitialSelectedAlertPredicates;
};

const handleReeferUnitFuelFormattedPredicates = selectedPredicateContext => {
  const { alertPredicate, alertPredicateType, formattedInitialSelectedAlertPredicates } = selectedPredicateContext;
  if (alertPredicateType !== alertPredicateTypes.REEFER_FUEL) {
    return;
  }

  formattedInitialSelectedAlertPredicates.push({
    alertPredicateType: alertPredicateTypes.REEFER_FUEL,
    thermoKingFuelLevelPercentage: alertPredicate?.thermoKingFuelLevelPercentage ?? '',
  });

  return formattedInitialSelectedAlertPredicates;
};

const handleReeferAlarmFormattedPredicates = selectedPredicateContext => {
  const { alertPredicate, alertPredicateType, formattedInitialSelectedAlertPredicates } = selectedPredicateContext;
  if (alertPredicateType !== alertPredicateTypes.REEFER_ALARM) {
    return;
  }

  formattedInitialSelectedAlertPredicates.push({
    alertPredicateType: alertPredicateTypes.REEFER_ALARM,
    thermoKingFaultLevel: alertPredicate?.thermoKingFaultLevel ?? '',
  });

  return formattedInitialSelectedAlertPredicates;
};

const handleAssetGeofenceThresholdPredicates = selectedPredicateContext => {
  const { alertPredicate, alertPredicateType, formattedInitialSelectedAlertPredicates } = selectedPredicateContext;
  if (alertPredicateType !== alertPredicateTypes.ASSET_GEOFENCE_THRESHOLD_REPORT) {
    return;
  }

  formattedInitialSelectedAlertPredicates.push({
    alertPredicateType: alertPredicateTypes.ASSET_GEOFENCE_THRESHOLD_REPORT,
    geofenceTrailersThreshold: alertPredicate?.geofenceTrailersThreshold ?? '',
    geofenceIds: alertPredicate?.geofenceIds ?? [],
  });

  return formattedInitialSelectedAlertPredicates;
};

const handleTowPredicates = selectedPredicateContext => {
  const { alertPredicateType, formattedInitialSelectedAlertPredicates } = selectedPredicateContext;
  if (alertPredicateType !== alertPredicateTypes.TOW_ALERT) {
    return;
  }

  formattedInitialSelectedAlertPredicates.push({
    alertPredicateType: alertPredicateTypes.TOW_ALERT,
  });

  return formattedInitialSelectedAlertPredicates;
};

const handleTpmsThresholdFormattedPredicates = selectedPredicateContext => {
  const { alertPredicate, alertPredicateType, formattedInitialSelectedAlertPredicates } = selectedPredicateContext;
  if (alertPredicateType !== alertPredicateTypes.TPMS_THRESHOLD) {
    return;
  }
  formattedInitialSelectedAlertPredicates.push({
    selectedAlertPredicateOption: '',
    displayDurationFields: true,
    durationMs: alertPredicate.tpmsThresholdPsi,
    selectedDurationType: '',
    alertPredicateType: alertPredicateTypes.TPMS_THRESHOLD,
  });
  return formattedInitialSelectedAlertPredicates;
};

const handleTireTemperatureThresholdFormattedPredicates = selectedPredicateContext => {
  const { alertPredicate, alertPredicateType, formattedInitialSelectedAlertPredicates } = selectedPredicateContext;
  if (alertPredicateType !== alertPredicateTypes.TIRE_TEMPERATURE) {
    return;
  }
  formattedInitialSelectedAlertPredicates.push({
    selectedAlertPredicateOption: '',
    displayDurationFields: true,
    durationMs: alertPredicate.tireTemperatureThresholdC ? celsiusToFahrenheit(Number(alertPredicate.tireTemperatureThresholdC)) : null,
    selectedDurationType: '',
    alertPredicateType: alertPredicateTypes.TIRE_TEMPERATURE,
  });
  return formattedInitialSelectedAlertPredicates;
};

const getDefaultPredicate = alertPredicateType => {
  if (!alertPredicateType?.defaultPredicateFields) {
    return null;
  }
  return [
    {
      alertPredicateTypeId: alertPredicateType.id,
      alertPredicateTypeName: alertPredicateType.name,
      ...alertPredicateType.defaultPredicateFields,
    },
  ];
};

const initNewAlert = (username, alertPredicateType) => {
  const initialPredicate = getDefaultPredicate(alertPredicateType);
  const notifyEmails = Array(username);
  return {
    id: null,
    name: '',
    ...(initialPredicate && { alertPredicates: initialPredicate }),
    isActive: true,
    allVehicles: true,
    vehicleIds: [],
    vehicleGroupIds: [],
    alertNotificationRateLimitMs: MILLISECONDS_PER_DAY,
    notifyMe: true,
    notifyAllCustomerUsers: false,
    notifyEmails,
    displayTitle: '',
    displayNotify: '',
    displayVehicles: '',
    created: null,
    updated: null,
    predicates: getInitialSelectedPredicates(alertPredicateType),
    customerId: null,
    thermoKingFuelLevelPercentage: '',
    geofenceTrailersThreshold: '',
    geofenceIds: [],
    thermoKingFaultLevel: '',
    isGeofenceOccupancyInverted: false,
    includeSensorData: false,
  };
};

const initNewReport = (username, customerIds = [], reportConfigType) => {
  const reportScheduleCategory =
    reportConfigType === alertPredicateTypes.ABS_SUMMARY_REPORT ||
    reportConfigType === alertPredicateTypes.ABS_FAULT_DETAIL_REPORT ||
    reportConfigType === alertPredicateTypes.DAILY_MAINTENANCE_REPORT
      ? absSummaryNotificationFrequencies.DAILY.queryFieldName
      : reportConfigType === alertPredicateTypes.WEEKLY_MAINTENANCE_REPORT
      ? absSummaryNotificationFrequencies.WEEKLY.queryFieldName
      : null;
  const notifyEmails = Array(username);
  return {
    id: null,
    name: null,
    reportTypeId: reportConfigType?.reportTypeId ?? null,
    customerIds,
    allVehicles: true, // optional, defaults to true if vins and vehicleGroupIds are null and allVehicles is omitted
    vins: [], // (ignored if allVehicles flag = true)
    vehicleGroupIds: [], // (ignored if allVehicles flag = true)
    notifyEmails, // (list of strings)
    allGeofences: true, // (defaults to true if geofenceIds is nullOrEmpty)
    geofenceIds: [], // (ignored if allGeofences = true)
    geofences: [],
    faultTypeIds: [],
    allFaultTypes: true,
    reportingSchedules: {
      timezone: null,
      category: reportScheduleCategory,
      schedules: [],
    },
    customerId: null,
    vehicleIds: [],
    reportHistoryTimeframe: null,
    assetThreshold: '',
    emailFrequency: {
      value: '',
      units: '',
    },
  };
};

const getInitialSelectedPredicates = alertPredicateType => {
  const initialPredicates = [];
  const shouldDisplayDurationFields = isDurationPredicateTypeBySensorTypeName(alertPredicateType.name);
  const alertPredicateOptions = getAlertPredicateTypeOptions(alertPredicateType);
  const defaultAlertTypeOptionDropdownValue = alertPredicateType.defaultPredicateFields ? alertPredicateOptions[0] : null;
  initialPredicates.push({
    displayDurationFields: shouldDisplayDurationFields,
    durationMs: '',
    selectedDurationType: 'minutes',
    defaultAlertTypeOptionDropdownValue,
    alertPredicateType,
  });

  return initialPredicates;
};

const reducer = {
  _alertsLoadAlertRuleTypeGroupsBegin(state) {
    return {
      ...state,
      alertRuleTypeGroups: null,
      isLoadingAlertRuleTypeGroups: true,
      error: null,
    };
  },
  _alertsLoadAlertRuleTypeGroupsSuccess(state, { alertRuleTypeGroups, availableVehicles }) {
    const selectAlertRuleTypeGroups = [];
    alertRuleTypeGroups.map(alert => {
      const alertObject = {
        id: alert.id,
        key: alert.key,
        value: alert.name,
        label: alert.name,
        ruleTypes: alert.ruleTypes,
        icon: _camelCase(alert.key),
      };
      return selectAlertRuleTypeGroups.push(alertObject);
    });

    return {
      ...state,
      alertRuleTypeGroups,
      selectAlertRuleTypeGroups,
      availableVehicles,
      isLoadingAlertRuleTypeGroups: false,
      isAlertNotificationsActive: false,
      error: null,
    };
  },
  _alertsLoadAlertRuleTypeGroupsFail(state, { error }) {
    return {
      ...state,
      isLoadingAlertRuleTypeGroups: false,
      error,
    };
  },
  _fetchAbsFaultTypesSuccess(state, { absFaultTypes }) {
    return {
      ...state,
      absFaultTypes,
      error: null,
    };
  },
  _fetchAbsFaultTypesFail(state, { error }) {
    return {
      ...state,
      error,
    };
  },
  _fetchComponentFaultTypesSuccess(state, { componentFaultTypes }) {
    return {
      ...state,
      componentFaultTypes,
      error: null,
    };
  },
  _fetchComponentFaultTypesFail(state, { error }) {
    return {
      ...state,
      error,
    };
  },
  _fetchLightFaultTypesSuccess(state, { lightFaultTypes }) {
    lightFaultTypes.unshift({
      id: 0,
      description: alertPredicateTypes.LIGHTS.predicateOptions.ANY_LIGHTS_STATUS.name,
    });

    return {
      ...state,
      lightFaultTypes,
      error: null,
    };
  },
  _fetchLightFaultTypesFail(state, { error }) {
    return {
      ...state,
      error,
    };
  },
  _getGeofencesSuccess(state, { allGeofences }) {
    allGeofences.unshift({
      id: '0',
      name: alertPredicateTypes.GEOFENCE_BOUNDARY_CROSSING.predicateOptions.ALL_GEOFENCES.name,
    });

    return {
      ...state,
      allGeofences,
      error: null,
    };
  },
  _getGeofencesFail(state, { error }) {
    return {
      ...state,
      error,
    };
  },
  _createNewAlertStart(state, { alertPredicateType, username }) {
    if (!alertPredicateType) {
      return {
        ...state,
        isCreatingConfig: false,
        loadedAlertRuleOrig: null,
        loadAlertRuleId: null,
        error: null,
        alertRule: null,
      };
    }

    return {
      ...state,
      isCreatingConfig: false,
      error: null,
      alertPredicateType,
      selectedAlertSensorType: alertPredicateType.name,
      alertRule: initNewAlert(username, alertPredicateType),
      selectedAlertPredicates: getInitialSelectedPredicates(alertPredicateType),
      alertConfigDurationValue: '',
      alertConfigLoadWeightValue: '',
      geofenceOccupancyIds: [],
      alertTypeOptionDropdownValue: '',
      alertRuleLoadWeightValue: null,
      fuelLevelThresholdValue: '',
      reportConfigType: null,
      thermoKingFaultLevelValue: [],
      isGeofenceOccupancyInvertedValue: false,
      assetsGeofenceThresholdTrailersValue: '',
      selectedAssetsGeofenceThresholdGeofences: [],
    };
  },
  _createNewAlertCancel(state) {
    return {
      ...state,
      isCreatingConfig: false,
      error: null,
      alertRule: null,
      loadedAlertRuleOrig: null,
      loadAlertRuleId: null,
      currentReportConfig: null,
      loadedReportConfig: null,
      reportConfigType: null,
    };
  },
  _setCreatedAlertRuleName(state, { name }) {
    const alertRule = { ...state.alertRule };
    alertRule.name = name;
    const loadedAlertRuleOrig = { ...state.loadedAlertRuleOrig };
    const alertRuleHasBeenUpdated = _get(alertRule, 'id') && !_isEqual(loadedAlertRuleOrig, alertRule);

    return {
      ...state,
      alertRule,
      alertRuleHasBeenUpdated,
      name,
    };
  },
  _toggleAllVehicleIds(state, { isChecked }) {
    if (!state.alertRule) {
      return state;
    }
    const alertRule = { ...state.alertRule };
    alertRule.allVehicles = isChecked;
    if (isChecked) {
      alertRule.vehicleIds = [];
      alertRule.vehicleGroupIds = [];
    }
    return {
      ...state,
      alertRule,
    };
  },
  _setAlertVehicleIds(state, { vehicleIds, selectedCustomerId }) {
    if (!state.alertRule) {
      return state;
    }
    const alertRule = { ...state.alertRule };
    const loadedAlertRuleOrig = { ...state.loadedAlertRuleOrig };
    const alertRuleHasBeenUpdated = _get(alertRule, 'id') && !_isEqual(loadedAlertRuleOrig, alertRule);
    alertRule.vehicleIds = vehicleIds;
    alertRule.selectedCustomerId = selectedCustomerId;
    alertRule.allVehicles = _isEmpty(vehicleIds) && _isEmpty(alertRule.vehicleGroupIds);

    return {
      ...state,
      alertRule,
      alertRuleHasBeenUpdated,
    };
  },
  _setAlertVehicleGroupIds(state, { vehicleGroupIds, selectedCustomerId }) {
    if (!state.alertRule) {
      return state;
    }
    const alertRule = { ...state.alertRule };
    const loadedAlertRuleOrig = { ...state.loadedAlertRuleOrig };
    const alertRuleHasBeenUpdated = _get(alertRule, 'id') && !_isEqual(loadedAlertRuleOrig, alertRule);
    alertRule.vehicleGroupIds = vehicleGroupIds;
    alertRule.selectedCustomerId = selectedCustomerId;
    alertRule.allVehicles = _isEmpty(vehicleGroupIds) && _isEmpty(alertRule.vehicleIds);

    return {
      ...state,
      alertRule,
      alertRuleHasBeenUpdated,
    };
  },
  _addNotifyEmailConfig(state, { email }) {
    if (!state.alertRule) {
      return state;
    }
    const alertRule = { ...state.alertRule };
    const emails = alertRule.notifyEmails.slice();
    if (!emails.includes(email)) {
      emails.push(email);
    }
    alertRule.notifyEmails = emails;
    const loadedAlertRuleOrig = { ...state.loadedAlertRuleOrig };
    const alertRuleHasBeenUpdated = _get(alertRule, 'id') && !_isEqual(loadedAlertRuleOrig, alertRule);

    return {
      ...state,
      alertRule,
      alertRuleHasBeenUpdated,
    };
  },
  _removeNotifyEmailConfig(state, { email }) {
    if (!state.alertRule) {
      return state;
    }
    const alertRule = { ...state.alertRule };
    const emails = alertRule.notifyEmails.slice().filter(it => it !== email);

    alertRule.notifyEmails = emails;
    const loadedAlertRuleOrig = { ...state.loadedAlertRuleOrig };
    const alertRuleHasBeenUpdated = _get(alertRule, 'id') && !_isEqual(loadedAlertRuleOrig, alertRule);

    return {
      ...state,
      alertRule,
      alertRuleHasBeenUpdated,
    };
  },
  _createNewAlertRuleBegin(state) {
    return {
      ...state,
      error: null,
      isCreatingConfig: true,
    };
  },
  _createNewAlertRuleFail(state, { error }) {
    return {
      ...state,
      error,
      isCreatingConfig: false,
      alertRule: null,
    };
  },
  _createNewAlertRuleSuccess(state, { alertRule }) {
    return {
      ...state,
      loadAlertRuleId: alertRule.id,
      loadedConfig: alertRule,
      alertRule: null,
      error: null,
      isCreatingConfig: false,
    };
  },
  _openCreateAlertDialog(state) {
    return {
      ...state,
      isCreateAlertDialogOpen: !state.isCreateAlertDialogOpen,
    };
  },
  _openUpdateAlertDialog(state) {
    return {
      ...state,
      isUpdateAlertDialogOpen: !state.isUpdateAlertDialogOpen,
    };
  },
  _openDeleteAlertDialog(state) {
    return {
      ...state,
      isDeleteAlertDialogOpen: !state.isDeleteAlertDialogOpen,
    };
  },
  _setAlertsListSearchQuery(state, { query }) {
    return {
      ...state,
      query,
      filteredAlertRules: state.alertRules.filter(it => new RegExp(query, 'i').test(it.name)),
      filteredReportConfigs: state.allReportConfigs?.filter(it => new RegExp(query, 'i').test(it.name)),
    };
  },
  _fetchAlertsListDataBegin(state) {
    return {
      ...state,
      alertRules: null,
      error: null,
      isLoadingIndex: true,
    };
  },
  _fetchAlertsListDataSuccess(state, { alertRules }) {
    return {
      ...state,
      alertRules,
      filteredAlertRules: alertRules,
      error: null,
      isLoadingIndex: false,
      query: '',
    };
  },
  _fetchAlertsListDataFail(state, { error }) {
    return {
      ...state,
      error,
      isLoadingIndex: false,
    };
  },
  _createAlertRulePredicate(state, { selectedAlertSensorType, isCreatingCustomAlert }) {
    const updatedSelectedAlertPredicates = [...state.selectedAlertPredicates];
    const selectedAlertPredicateType = _get(selectedAlertSensorType, 'alertPredicateType', '');
    const shouldDisplayDurationFields = isDurationPredicateTypeBySensorTypeName(_get(selectedAlertPredicateType.name));
    if (selectedAlertSensorType?.alertPredicateType?.geofenceOccupancy && isCreatingCustomAlert) {
      updatedSelectedAlertPredicates.push({
        alertPredicateType: alertPredicateTypes.GEOFENCE_OCCUPANCY,
        selectedAlertPredicateOption: null,
        shouldDisplayDurationFields,
        durationMs: shouldDisplayDurationFields ? selectedAlertSensorType.durationMs : null,
        selectedDurationType: shouldDisplayDurationFields ? selectedAlertSensorType.selectedDurationType : null,
        isGeofenceOccupancyInverted: false,
      });
    } else
      updatedSelectedAlertPredicates.push({
        selectedAlertPredicateOption: null,
        shouldDisplayDurationFields,
        durationMs: shouldDisplayDurationFields ? selectedAlertSensorType.durationMs : null,
        selectedDurationType: shouldDisplayDurationFields ? selectedAlertSensorType.selectedDurationType : null,
      });

    return {
      ...state,
      selectedAlertPredicates: updatedSelectedAlertPredicates,
    };
  },
  _removeAlertRulePredicate(state, { predicateIndex }) {
    if (!state.alertRule) {
      return state;
    }
    const updatedSelectedAlertPredicates = [...state.selectedAlertPredicates];
    updatedSelectedAlertPredicates.splice(predicateIndex, 1);
    const alertRule = { ...state.alertRule };
    alertRule.alertPredicates = alertRule?.alertPredicates?.filter((val, idx) => idx !== predicateIndex);
    return {
      ...state,
      alertRule,
      selectedAlertPredicates: updatedSelectedAlertPredicates,
    };
  },
  _setNotificationsFrequency(state, { alertNotificationRateLimitMs }) {
    if (!state.alertRule) {
      return state;
    }
    const alertRule = { ...state.alertRule };
    alertRule.alertNotificationRateLimitMs = alertNotificationRateLimitMs;
    const loadedAlertRuleOrig = { ...state.loadedAlertRuleOrig };
    const alertRuleHasBeenUpdated = _get(alertRule, 'id') && !_isEqual(loadedAlertRuleOrig, alertRule);

    return {
      ...state,
      alertRule,
      alertRuleHasBeenUpdated,
    };
  },
  _setAlertRuleCustomerData(state, { selectedCustomer }) {
    if (!state.alertRule) {
      return state;
    }
    const alertRule = { ...state.alertRule };
    const loadedAlertRuleOrig = { ...state.loadedAlertRuleOrig };
    const selectedCustomerId = Number(selectedCustomer?.id);
    alertRule.customerId = selectedCustomerId;
    const alertRuleHasBeenUpdated = _get(alertRule, 'id') && !_isEqual(loadedAlertRuleOrig, alertRule);

    return {
      ...state,
      alertRule,
      alertRuleHasBeenUpdated,
    };
  },
  _loadAlertRuleBegin(state, { alertRuleId }) {
    return {
      ...state,
      loadAlertRuleId: alertRuleId,
      error: null,
      isDeletingConfig: false,
      isLoadingConfig: true,
      loadedReportConfigId: null,
      loadedReportConfig: null,
      reportConfigType: null,
      alertPredicateType: null,
    };
  },
  _loadAlertRuleSuccess(state, { alertRule }) {
    const loadedAlertRuleOrig = { ...alertRule };
    (loadedAlertRuleOrig.alertPredicates || []).forEach(predicate => {
      const loadedAlertPredicateTypeId = predicate?.alertPredicateTypeId ?? '';
      const alertPredicateType =
        predicate?.alertPredicateTypeName === alertPredicateTypes.TPMS_THRESHOLD.name ||
        predicate?.alertPredicateTypeName === alertPredicateTypes.TIRE_TEMPERATURE.name
          ? Object.values(alertPredicateTypes).find(apt => apt.id === loadedAlertPredicateTypeId && apt.name === predicate?.alertPredicateTypeName)
          : Object.values(alertPredicateTypes).find(apt => apt.id === loadedAlertPredicateTypeId);

      predicate.alertPredicateTypeName = alertPredicateType?.name ?? '';
      if (predicate.alertPredicateTypeId === alertPredicateTypes.REEFER_ALARM.id) {
        predicate.thermoKingFaultLevel.sort();
      }
    });
    const filteredAlertPredicates = loadedAlertRuleOrig?.alertPredicates?.filter(ap => alertPredicateTypes.GEOFENCE_OCCUPANCY.id !== ap?.alertPredicateTypeId);
    const alertPredicateTypeName = filteredAlertPredicates.length === 1 ? filteredAlertPredicates[0]?.alertPredicateTypeName : '';
    const alertPredicateType =
      filteredAlertPredicates.length === 1
        ? findAlertPredicateTypeByName(filteredAlertPredicates[0]?.alertPredicateTypeName ?? '')
        : alertPredicateTypes.CUSTOM;
    const alertRuleLoadWeightValue = (loadedAlertRuleOrig.alertPredicates || []).find(pred =>
      isLoadedBogieWeightAlertPredicateType(pred.alertPredicateTypeName),
    );
    const reeferUnitFuelPredicate = (loadedAlertRuleOrig.alertPredicates || []).find(pred => pred.alertPredicateTypeId === alertPredicateTypes.REEFER_FUEL.id);
    const reeferAlarmPredicate = (loadedAlertRuleOrig.alertPredicates || []).find(pred => pred.alertPredicateTypeId === alertPredicateTypes.REEFER_ALARM.id);
    const assetGeofenceThresholdPredicate = (loadedAlertRuleOrig.alertPredicates || []).find(
      pred => pred.alertPredicateTypeId === alertPredicateTypes.ASSET_GEOFENCE_THRESHOLD_REPORT.id,
    );
    const geofenceOccupancyPredicate = (loadedAlertRuleOrig.alertPredicates || []).find(
      pred => pred.alertPredicateTypeId === alertPredicateTypes.GEOFENCE_OCCUPANCY.id,
    );
    const formattedInitialPredicates = formatInitialSelectedPredicates(alertRule, state.lightFaultTypes, state.lightFaultOptions, state.allGeofences);
    const assetThresholdGeofences = assetGeofenceThresholdPredicate?.anyCustomerGeofence
      ? allGeofencesSelectOption.selectFormat
      : findSelectedGeofencesByGeofenceIds(assetGeofenceThresholdPredicate?.geofenceIds, state.allGeofences) ?? [];
    return {
      ...state,
      isLoadingConfig: false,
      error: null,
      alertRule: loadedAlertRuleOrig,
      loadedAlertRuleOrig,
      loadedAlertPredicates: formattedInitialPredicates,
      selectedAlertPredicates: formattedInitialPredicates,
      isAlertPredicateValid: true,
      selectedAlertSensorType: alertPredicateTypeName,
      alertRuleLoadWeightValue: alertRuleLoadWeightValue?.netBogieWeightLbs ?? '',
      fuelLevelThresholdValue: reeferUnitFuelPredicate?.thermoKingFuelLevelPercentage ?? '',
      thermoKingFaultLevelValue: reeferAlarmPredicate?.thermoKingFaultLevel ?? [],
      isGeofenceOccupancyInvertedValue: geofenceOccupancyPredicate?.isGeofenceOccupancyInverted ?? false,
      assetsGeofenceThresholdTrailersValue: assetGeofenceThresholdPredicate?.geofenceTrailersThreshold ?? '',
      selectedAssetsGeofenceThresholdGeofences: assetThresholdGeofences,
      alertPredicateType,
    };
  },
  _loadAlertRuleFail(state, { error }) {
    return {
      ...state,
      isLoadingConfig: false,
      alertRule: null,
      error,
    };
  },

  _updateAlertRuleBegin(state) {
    return {
      ...state,
      error: null,
      isEditingConfig: true,
    };
  },
  _updateAlertRuleSuccess(state, { alertRule }) {
    const alertRules = [];
    if (state.alertRules) {
      state.alertRules.forEach(c => {
        if (c.id === alertRule.id) {
          alertRules.push(alertRule);
        } else {
          alertRules.push(c);
        }
      });
    }
    return {
      ...state,
      error: null,
      isEditingConfig: false,
      alertRule,
      loadedAlertRuleOrig: { ...alertRule },
      alertRules: alertRules.length === 0 ? null : alertRules,
    };
  },
  _updateAlertRuleFail(state, { error }) {
    return {
      ...state,
      error,
      isEditingConfig: false,
      alertRule: null,
    };
  },
  _toggleAlertDeleteModal(state, { isAlertDeleteModalActive }) {
    return {
      ...state,
      isAlertDeleteModalActive,
    };
  },
  _deleteAlertRuleBegin(state) {
    return {
      ...state,
      isDeletingConfig: true,
      error: null,
    };
  },
  _moveAlertBegin(state) {
    return {
      ...state,
      isMovingAlert: true,
      error: null,
    };
  },
  _moveReportBegin(state) {
    return {
      ...state,
      isMovingReport: true,
      error: null,
    };
  },
  _deleteAlertRuleSuccess(state) {
    return {
      ...state,
      loadAlertRuleId: null,
      isCreatingConfig: false,
      alertRule: null,
      isDeletingConfig: false,
      error: null,
    };
  },
  _moveAlertSuccess(state) {
    return {
      ...state,
      loadAlertRuleId: null,
      isCreatingConfig: false,
      alertRule: null,
      isMovingAlert: false,
      error: null,
    };
  },
  _moveReportSuccess(state) {
    return {
      ...state,
      currentReportConfig: null,
      reportConfigType: null,
      loadedReportConfig: null,
      loadedReportConfigId: null,
      isMovingReport: false,
      error: null,
    };
  },
  _deleteAlertRuleFail(state, { error }) {
    return {
      ...state,
      isDeletingConfig: false,
      error,
      alertRule: null,
    };
  },
  _moveAlertFail(state, { error }) {
    return {
      ...state,
      isMovingAlert: false,
      error,
      alertRule: null,
    };
  },
  _moveReportFail(state, { error }) {
    return {
      ...state,
      isMovingReport: false,
      error,
      currentReportConfig: null,
      reportConfigType: null,
      loadedReportConfig: null,
      loadedReportConfigId: null,
    };
  },
  _toggleAlertNotificationsScreen(state) {
    return {
      ...state,
      isAlertNotificationsActive: !state.isAlertNotificationsActive,
    };
  },

  _setSelectedAlertPredicates(state, { selectedAlertPredicates, alertRulePredicates, isAlertPredicateValid }) {
    const alertRule = { ...state.alertRule };
    const loadedAlertRuleOrig = { ...state.loadedAlertRuleOrig };
    alertRule.alertPredicates = alertRulePredicates;
    const alertRuleHasBeenUpdated = _get(alertRule, 'id') && !_isEqual(loadedAlertRuleOrig, alertRule);
    return {
      ...state,
      alertRule,
      selectedAlertPredicates,
      isAlertPredicateValid,
      alertRuleHasBeenUpdated,
    };
  },
  _setAlertTypeOptionDropdownValue(state, { value }) {
    return {
      ...state,
      alertTypeOptionDropdownValue: value,
    };
  },
  _setAlertRuleDurationInputValue(state, { value }) {
    return {
      ...state,
      alertRuleDurationValue: value,
    };
  },
  _setAlertRuleLoadWeightInputValue(state, { value }) {
    return {
      ...state,
      alertRuleLoadWeightValue: value,
    };
  },
  _setFuelLevelThresholdValue(state, { value }) {
    return {
      ...state,
      fuelLevelThresholdValue: value,
    };
  },
  _setAssetsGeofenceThresholdTrailersValue(state, { trailersInputValue }) {
    const updatedReportConfig = {
      ...state.currentReportConfig,
      assetThreshold: trailersInputValue,
    };

    return {
      ...state,
      currentReportConfig: updatedReportConfig,
    };
  },
  _setSelectedAssetsGeofenceThresholdGeofences(state, { selectedGeofencesValue }) {
    return {
      ...state,
      selectedAssetsGeofenceThresholdGeofences: selectedGeofencesValue,
    };
  },
  _setThermoKingFaultLevelValue(state, { severityFaultsArray }) {
    return {
      ...state,
      thermoKingFaultLevelValue: severityFaultsArray,
    };
  },
  _enableAlertRuleBegin(state, { alertRuleId }) {
    return {
      ...state,
      isTogglingActiveAlertRuleId: alertRuleId,
      enableAlertError: null,
    };
  },
  _enableAlertRuleSuccess(state, { alertRuleId }) {
    let updatedAlertRules = _cloneDeep(state.alertRules || []);
    const targetAlertRuleIdx = updatedAlertRules.findIndex(c => c.id === alertRuleId);
    if (targetAlertRuleIdx >= 0) {
      updatedAlertRules[targetAlertRuleIdx].deactivated = null;
    }
    updatedAlertRules = updatedAlertRules.length === 0 ? null : updatedAlertRules;

    return {
      ...state,
      alertRules: updatedAlertRules,
      filteredAlertRules: updatedAlertRules,
      isTogglingActiveAlertRuleId: null,
      enableAlertError: null,
    };
  },
  _enableAlertRuleFail(state, { error }) {
    return {
      ...state,
      isTogglingActiveAlertRuleId: null,
      enableAlertError: error,
    };
  },
  _disableAlertRuleBegin(state, { alertRuleId }) {
    return {
      ...state,
      isTogglingActiveAlertRuleId: alertRuleId,
      enableAlertError: null,
    };
  },
  _disableAlertRuleSuccess(state, { alertRuleId }) {
    let updatedAlertRules = _cloneDeep(state.alertRules || []);
    const targetAlertRuleIdx = updatedAlertRules.findIndex(c => c.id === alertRuleId);
    if (targetAlertRuleIdx >= 0) {
      updatedAlertRules[targetAlertRuleIdx].deactivated = true;
    }
    updatedAlertRules = updatedAlertRules.length === 0 ? null : updatedAlertRules;

    return {
      ...state,
      alertRules: updatedAlertRules,
      filteredAlertRules: updatedAlertRules,
      isTogglingActiveAlertRuleId: null,
      enableAlertError: null,
    };
  },
  _disableAlertRuleFail(state, { error }) {
    return {
      ...state,
      isTogglingActiveAlertRuleId: null,
      enableAlertError: error,
    };
  },
  _resetAlertRuleState(state) {
    return {
      ...state,
      selectedAlertSensorType: null,
      alertRule: null,
      reportConfigType: null,
      currentReportConfig: null,
    };
  },
  _setGeofenceOccupancyIds(state, { geofenceOccupancyIds }) {
    return {
      ...state,
      geofenceOccupancyIds,
    };
  },
  _fetchAllVehiclesBegin(state) {
    return {
      ...state,
      fetchAllVehiclesLoading: true,
      fetchAllVehiclesError: null,
    };
  },
  _fetchAllVehiclesSuccess(state, { availableVehicles }) {
    return {
      ...state,
      fetchAllVehiclesLoading: false,
      fetchAllVehiclesError: null,
      availableVehicles,
    };
  },
  _fetchAllVehiclesFail(state) {
    return {
      ...state,
      fetchAllVehiclesLoading: false,
      fetchAllVehiclesError: true,
    };
  },
  _createReportConfigStart(state, { reportConfigType, username, customerIds }) {
    return {
      ...state,
      customerIds,
      currentReportConfig: initNewReport(username, customerIds, reportConfigType),
      allReportingScheduleDays: Object.values(reportTimeFrameDays).map(day => ({ ...day, active: false, timeFrames: [] })),
      reportConfigType,
      loadedReportConfigId: null,
      loadedReportConfig: null,
      reportSchedules: {
        selectedDays: [],
        selectedTimeFrames: [],
      },
    };
  },
  _createReportConfigCancel(state) {
    return {
      ...state,
      currentReportConfig: null,
      reportConfigType: null,
      loadedReportConfig: null,
      loadedReportConfigId: null,
    };
  },
  _setYardReportName(state, { reportName }) {
    const updatedReportConfig = { ...state.currentReportConfig, name: reportName };
    return {
      ...state,
      currentReportConfig: updatedReportConfig,
    };
  },
  _setReportCustomerData(state, { selectedCustomer }) {
    const selectedCustomerId = Number(selectedCustomer?.id);
    const updatedReportConfig = { ...state.currentReportConfig, customerIds: [selectedCustomerId], customerId: selectedCustomer?.id };
    return {
      ...state,
      currentReportConfig: updatedReportConfig,
    };
  },
  _setYardReportVins(state, { vehicles }) {
    const updatedReportConfig = {
      ...state.currentReportConfig,
      vins: vehicles?.map(v => v.value) ?? [],
      allVehicles: _isEmpty(vehicles) && _isEmpty(state.currentReportConfig?.vehicleGroupIds),
      vehicleIds: vehicles?.map(v => v.value) ?? [],
    };

    return {
      ...state,
      currentReportConfig: updatedReportConfig,
    };
  },
  _toggleAllYardReportVehicles(state, { isChecked }) {
    const updatedReportConfig = { ...state.currentReportConfig, allVehicles: isChecked };
    if (isChecked) {
      updatedReportConfig.vins = [];
      updatedReportConfig.vehicleIds = [];
      updatedReportConfig.vehicleGroupIds = [];
    }
    return {
      ...state,
      currentReportConfig: updatedReportConfig,
    };
  },
  _setYardReportVehicleGroups(state, { vehicleGroupIds }) {
    const updatedReportConfig = {
      ...state.currentReportConfig,
      vehicleGroupIds,
      allVehicles: _isEmpty(vehicleGroupIds) && _isEmpty(state.currentReportConfig?.vehicleIds),
    };
    return {
      ...state,
      currentReportConfig: updatedReportConfig,
    };
  },
  _setYardReportNotifyEmail(state, { addedEmail }) {
    const emails = state.currentReportConfig?.notifyEmails?.slice();
    if (!emails.includes(addedEmail)) {
      emails.push(addedEmail);
    }
    const updatedReportConfig = {
      ...state.currentReportConfig,
      notifyEmails: emails,
    };
    return {
      ...state,
      currentReportConfig: updatedReportConfig,
    };
  },
  _removeYardReportNotifyEmail(state, { removedEmail }) {
    const emails = state.currentReportConfig?.notifyEmails?.slice()?.filter(it => it !== removedEmail);
    const updatedReportConfig = {
      ...state.currentReportConfig,
      notifyEmails: emails,
    };
    return {
      ...state,
      currentReportConfig: updatedReportConfig,
    };
  },
  _setYardReportTimeSchedules(state, { selectedDays, selectedTimeFrames }) {
    const selectedDaysArray = Array.isArray(selectedDays) ? selectedDays : Array(selectedDays);
    const selectedTimeFramesArray = Array.isArray(selectedTimeFrames) ? selectedTimeFrames : Array(selectedTimeFrames);
    const updatedReportSchedules = { ...state.reportSchedules };

    updatedReportSchedules.selectedDays = selectedDaysArray;
    updatedReportSchedules.selectedTimeFrames = selectedTimeFramesArray;

    if (selectedDaysArray && selectedTimeFramesArray) {
      selectedDaysArray.forEach(day => {
        day.timeFrames = selectedTimeFramesArray?.map(timeframe => ({ dayOfWeek: day?.dayOfWeek, time: timeframe?.value }));
      });
    }

    const updatedReportConfig = {
      ...state.currentReportConfig,
      reportingSchedules: {
        ...state.currentReportConfig?.reportingSchedules,
        timezone: browsersTimezone,
        schedules: selectedDaysArray?.map(activeDay => activeDay.timeFrames)?.flat(),
      },
    };

    return {
      ...state,
      currentReportConfig: updatedReportConfig,
      reportSchedules: updatedReportSchedules,
    };
  },
  _setReportGeofenceLocations(state, { geofences }) {
    const updatedReportConfig = { ...state.currentReportConfig, geofenceIds: geofences?.map(g => g.value) ?? [], geofences, allGeofences: !Boolean(geofences) };
    return {
      ...state,
      currentReportConfig: updatedReportConfig,
    };
  },
  _toggleAllReportGeofences(state, { isChecked }) {
    const updatedReportConfig = { ...state.currentReportConfig, allGeofences: isChecked };
    if (isChecked) {
      updatedReportConfig.geofenceIds = [];
      updatedReportConfig.geofences = [];
    }
    return {
      ...state,
      currentReportConfig: updatedReportConfig,
    };
  },
  _createYardConfigReportBegin(state) {
    return {
      ...state,
      reportConfigLoading: true,
      reportConfigError: null,
    };
  },
  _createYardConfigReportFail(state) {
    return {
      ...state,
      reportConfigLoading: false,
      reportConfigError: true,
    };
  },
  _createYardConfigReportSuccess(state, { reportConfigid }) {
    return {
      ...state,
      loadedReportConfigId: reportConfigid,
      currentReportConfig: { ...state.currentReportConfig, id: reportConfigid },
      loadedReportConfig: { ...state.currentReportConfig, id: reportConfigid },
      reportConfigLoading: false,
      reportConfigError: null,
    };
  },
  _fetchAllReportConfigsBegin(state) {
    return {
      ...state,
      allReportConfigsLoading: true,
      reportConfigError: null,
    };
  },
  _fetchAllReportConfigsSuccess(state, { yardReportConfigs }) {
    return {
      ...state,
      yardReportConfigs,
      allReportConfigsLoading: false,
      reportConfigError: null,
    };
  },
  _fetchAllReportConfigsFail(state) {
    return {
      ...state,
      allReportConfigsLoading: false,
      reportConfigError: true,
      yardReportConfigs: [],
    };
  },
  _loadYardReportConfigBegin(state, { configId }) {
    return {
      ...state,
      loadedReportConfigId: configId,
      reportConfigLoading: true,
      reportConfigError: null,
      loadAlertRuleId: null,
      loadedAlertRuleOrig: null,
      allReportingScheduleDays: Object.values(reportTimeFrameDays).map(day => ({ ...day, active: false, timeFrames: [] })),
      currentReportConfig: null,
      reportSchedules: {
        selectedDays: [],
        selectedTimeFrames: [],
      },
    };
  },
  _loadYardReportConfigSuccess(state, { loadedReportConfig }) {
    const loadedReportingSchedules = loadedReportConfig?.reportingSchedules?.schedules ?? [];
    let updatedReportSchedules = { ...state.reportSchedules };

    loadedReportingSchedules.forEach(schedule => {
      const scheduleDay = Object.values(reportTimeFrameDays).find(it => it.dayOfWeek === schedule.dayOfWeek);
      if (scheduleDay && !updatedReportSchedules.selectedDays.includes(scheduleDay)) {
        updatedReportSchedules.selectedDays.push(scheduleDay);
      }
      const currentTimeFrame = { label: schedule.time, value: schedule.time };
      if (!updatedReportSchedules.selectedTimeFrames.includes(currentTimeFrame)) {
        updatedReportSchedules.selectedTimeFrames.push(currentTimeFrame);
      }
    });

    const updatedReportingSchedulesDays = state.allReportingScheduleDays?.map(schedule => {
      const filteredReportingSchedulesByDay = loadedReportingSchedules?.filter(atfs => atfs?.dayOfWeek === schedule?.dayOfWeek);
      if (!_isEmpty(filteredReportingSchedulesByDay)) {
        schedule.active = true;
        schedule.timeFrames = filteredReportingSchedulesByDay;
      }
      return schedule;
    });
    return {
      ...state,
      currentReportConfig: loadedReportConfig,
      loadedReportConfig,
      reportConfigLoading: false,
      reportConfigError: null,
      reportConfigType: alertPredicateTypes.YARD_REPORT,
      allReportingScheduleDays: updatedReportingSchedulesDays,
      reportSchedules: updatedReportSchedules,
    };
  },
  _loadYardReportConfigFail(state) {
    return {
      ...state,
      reportConfigLoading: false,
      reportConfigError: true,
    };
  },
  _deleteYardReportConfigBegin(state) {
    return {
      ...state,
      reportConfigLoading: true,
      reportConfigError: false,
    };
  },
  _deleteYardReportConfigFail(state) {
    return {
      ...state,
      reportConfigLoading: false,
      reportConfigError: true,
    };
  },
  _deleteYardReportConfigSuccess(state) {
    return {
      ...state,
      reportConfigLoading: false,
      reportConfigError: false,
      currentReportConfig: null,
      loadedReportConfig: null,
      loadedReportConfigId: null,
    };
  },
  _updateYardReportConfigBegin(state) {
    return {
      ...state,
      reportConfigLoading: true,
      reportConfigError: false,
    };
  },
  _updateYardReportConfigFail(state) {
    return {
      ...state,
      reportConfigLoading: false,
      reportConfigError: true,
    };
  },
  _updateYardReportConfigSuccess(state, { yardReportConfig }) {
    const allReportConfigs = [];
    if (state.yardReportConfigs) {
      state.yardReportConfigs.forEach(config => {
        if (config.id === yardReportConfig.id) {
          allReportConfigs.push(yardReportConfig);
        } else {
          allReportConfigs.push(config);
        }
      });
    }
    return {
      ...state,
      reportConfigLoading: false,
      reportConfigError: false,
      yardReportConfigs: allReportConfigs,
      loadedReportConfig: yardReportConfig,
      currentReportConfig: yardReportConfig,
    };
  },
  _toggleDeleteReportConfigModal(state, { modalVisible }) {
    return {
      ...state,
      deleteReportConfigModal: modalVisible,
    };
  },
  _fetchAllDwellReportConfigsBegin(state) {
    return {
      ...state,
      allReportConfigsLoading: true,
      reportConfigError: null,
    };
  },
  _fetchAllDwellReportConfigsFail(state) {
    return {
      ...state,
      allReportConfigsLoading: false,
      reportConfigError: true,
    };
  },
  _fetchAllDwellReportConfigsSuccess(state, { dwellReportConfigs }) {
    return {
      ...state,
      allReportConfigsLoading: false,
      dwellReportConfigs,
    };
  },
  _createDwellReportConfigBegin(state) {
    return {
      ...state,
      reportConfigLoading: true,
      reportConfigError: null,
    };
  },
  _createDwellReportConfigFail(state) {
    return {
      ...state,
      reportConfigLoading: true,
      reportConfigError: null,
    };
  },
  _createDwellReportConfigSuccess(state, { reportConfigid }) {
    return {
      ...state,
      loadedReportConfigId: reportConfigid,
      currentReportConfig: { ...state.currentReportConfig, id: reportConfigid },
      loadedReportConfig: { ...state.currentReportConfig, id: reportConfigid },
      reportConfigLoading: false,
      reportConfigError: null,
    };
  },
  _updateDwellReportConfigBegin(state) {
    return {
      ...state,
      reportConfigLoading: true,
      reportConfigError: false,
    };
  },
  _updateDwellReportConfigFail(state) {
    return {
      ...state,
      reportConfigLoading: false,
      reportConfigError: true,
    };
  },
  _updateDwellReportConfigSuccess(state, { dwellReportConfig }) {
    const allDwellReportConfigs = [];
    if (state.dwellReportConfigs) {
      state.dwellReportConfigs.forEach(config => {
        if (config.id === dwellReportConfig.id) {
          allDwellReportConfigs.push(dwellReportConfig);
        } else {
          allDwellReportConfigs.push(config);
        }
      });
    }
    return {
      ...state,
      reportConfigLoading: false,
      reportConfigError: false,
      dwellReportConfigs: allDwellReportConfigs,
      loadedReportConfig: dwellReportConfig,
      currentReportConfig: dwellReportConfig,
    };
  },
  _loadDwellReportConfigBegin(state, { configId }) {
    return {
      ...state,
      loadedReportConfigId: configId,
      reportConfigLoading: true,
      reportConfigError: null,
      loadAlertRuleId: null,
      loadedAlertRuleOrig: null,
      allReportingScheduleDays: Object.values(reportTimeFrameDays).map(day => ({ ...day, active: false, timeFrames: [] })),
      currentReportConfig: null,
      reportSchedules: {
        selectedDays: [],
        selectedTimeFrames: [],
      },
    };
  },
  _loadDwellReportConfigFail(state) {
    return {
      ...state,
      reportConfigLoading: false,
      reportConfigError: true,
    };
  },
  _loadDwellReportConfigSuccess(state, { loadedReportConfig }) {
    const loadedReportingSchedules = loadedReportConfig?.reportingSchedules?.schedules ?? [];
    let updatedReportSchedules = { ...state.reportSchedules };

    loadedReportingSchedules.forEach(schedule => {
      const scheduleDay = Object.values(reportTimeFrameDays).find(it => it.dayOfWeek === schedule.dayOfWeek);
      if (scheduleDay && !updatedReportSchedules.selectedDays.includes(scheduleDay)) {
        updatedReportSchedules.selectedDays.push(scheduleDay);
      }
      const currentTimeFrame = { label: schedule.time, value: schedule.time };
      if (!updatedReportSchedules.selectedTimeFrames.includes(currentTimeFrame)) {
        updatedReportSchedules.selectedTimeFrames.push(currentTimeFrame);
      }
    });

    const updatedReportingSchedulesDays = state.allReportingScheduleDays?.map(schedule => {
      const filteredReportingSchedulesByDay = loadedReportingSchedules?.filter(atfs => atfs?.dayOfWeek === schedule?.dayOfWeek);
      if (!_isEmpty(filteredReportingSchedulesByDay)) {
        schedule.active = true;
        schedule.timeFrames = filteredReportingSchedulesByDay;
      }
      return schedule;
    });
    return {
      ...state,
      currentReportConfig: loadedReportConfig,
      loadedReportConfig,
      reportConfigLoading: false,
      reportConfigError: null,
      reportConfigType: alertPredicateTypes.DWELL_REPORT,
      allReportingScheduleDays: updatedReportingSchedulesDays,
      reportSchedules: updatedReportSchedules,
    };
  },
  _deleteDwellReportConfigBegin(state) {
    return {
      ...state,
      reportConfigLoading: true,
      reportConfigError: false,
    };
  },
  _deleteDwellReportConfigFail(state) {
    return {
      ...state,
      reportConfigLoading: false,
      reportConfigError: true,
    };
  },
  _deleteDwellReportConfigSuccess(state) {
    return {
      ...state,
      reportConfigLoading: false,
      reportConfigError: false,
      currentReportConfig: null,
      loadedReportConfig: null,
      loadedReportConfigId: null,
    };
  },
  _setToggleAllAssetsModal(state, { isToggleAllAssetsModalActive }) {
    return {
      ...state,
      isToggleAllAssetsModalActive,
    };
  },
  _updateYardReportConfigStatusBegin(state, { reportConfigId }) {
    return {
      ...state,
      isTogglingActiveReportConfigId: reportConfigId,
      isTogglingActiveReportError: null,
    };
  },
  _updateYardReportConfigStatusFail(state) {
    return {
      ...state,
      isTogglingActiveReportConfigId: null,
      isTogglingActiveReportError: true,
    };
  },
  _updateYardReportConfigStatusSuccess(state, { status, reportConfigId }) {
    let updatedYardReportConfigs = _cloneDeep(state.yardReportConfigs ?? []);
    const targetYardReportConfigIdx = updatedYardReportConfigs.findIndex(yrc => yrc.id === reportConfigId);
    if (targetYardReportConfigIdx >= 0) {
      updatedYardReportConfigs[targetYardReportConfigIdx].deactivated = !status;
    }
    return {
      ...state,
      yardReportConfigs: updatedYardReportConfigs ?? [],
      isTogglingActiveReportConfigId: null,
      isTogglingActiveReportError: null,
    };
  },
  _updateDwellReportConfigStatusBegin(state, { reportConfigId }) {
    return {
      ...state,
      isTogglingActiveReportConfigId: reportConfigId,
      isTogglingActiveReportError: null,
    };
  },
  _updateDwellReportConfigStatusFail(state) {
    return {
      ...state,
      isTogglingActiveReportConfigId: null,
      isTogglingActiveReportError: true,
    };
  },
  _updateDwellReportConfigStatusSuccess(state, { status, reportConfigId }) {
    let updatedDwellReportConfigs = _cloneDeep(state.dwellReportConfigs ?? []);
    const targetDwellReportConfigIdx = updatedDwellReportConfigs.findIndex(yrc => yrc.id === reportConfigId);
    if (targetDwellReportConfigIdx >= 0) {
      updatedDwellReportConfigs[targetDwellReportConfigIdx].deactivated = !status;
    }
    return {
      ...state,
      dwellReportConfigs: updatedDwellReportConfigs ?? [],
      isTogglingActiveReportConfigId: null,
      isTogglingActiveReportError: null,
    };
  },
  _setAbsSummaryNotificationsFrequenctCategory(state, { category }) {
    const updatedReportSchedules = { ...state.reportSchedules };
    updatedReportSchedules.category = category;

    const updatedReportConfig = {
      ...state.currentReportConfig,
      reportingSchedules: {
        ...state.currentReportConfig?.reportingSchedules,
        category,
      },
    };

    return {
      ...state,
      currentReportConfig: updatedReportConfig,
      reportSchedules: updatedReportSchedules,
    };
  },
  _setReportHistoryTimeframeValue(state, { timeframe }) {
    const updatedReportConfig = {
      ...state.currentReportConfig,
      reportHistoryTimeframe: timeframe,
    };

    return {
      ...state,
      currentReportConfig: updatedReportConfig,
    };
  },
  _fetchReportConfigsBegin(state) {
    return {
      ...state,
      allReportConfigsLoading: true,
      reportConfigError: null,
    };
  },
  _fetchReportConfigsSuccess(state, { allReportConfigs }) {
    return {
      ...state,
      allReportConfigs,
      filteredReportConfigs: allReportConfigs,
      allReportConfigsLoading: false,
      reportConfigError: null,
      query: '',
    };
  },
  _fetchReportConfigsFail(state) {
    return {
      ...state,
      allReportConfigs: [],
      filteredReportConfigs: [],
      allReportConfigsLoading: false,
      reportConfigError: true,
    };
  },
  _createReportConfigBegin(state) {
    return {
      ...state,
      reportConfigLoading: true,
      reportConfigError: null,
    };
  },
  _createReportConfigSuccess(state, { reportConfigid }) {
    const updatedReportSchedules = { ...state.reportSchedules };
    const daysRestrictedFrequencyCategories = [
      absSummaryNotificationFrequencies.DAILY.queryFieldName,
      absSummaryNotificationFrequencies.SEMI_MONTHLY.queryFieldName,
      absSummaryNotificationFrequencies.MONTHLY.queryFieldName,
    ];

    const currentReportConfig = { ...state.currentReportConfig, id: reportConfigid };
    if (daysRestrictedFrequencyCategories.some(it => it === currentReportConfig?.reportingSchedules?.category)) {
      currentReportConfig.reportingSchedules.schedules = [{ dayOfWeek: null, time: currentReportConfig?.reportingSchedules?.schedules[0]?.time ?? null }];
      updatedReportSchedules.selectedDays = [];
    }
    return {
      ...state,
      loadedReportConfigId: reportConfigid,
      currentReportConfig,
      loadedReportConfig: currentReportConfig,
      reportConfigLoading: false,
      reportConfigError: null,
      reportSchedules: updatedReportSchedules,
    };
  },
  _createReportConfigFail(state) {
    return {
      ...state,
      reportConfigLoading: false,
      reportConfigError: true,
      currentReportConfig: null,
    };
  },
  _updateReportConfigBegin(state) {
    return {
      ...state,
      reportConfigLoading: true,
      reportConfigError: false,
    };
  },
  _updateReportConfigSuccess(state, { reportConfig }) {
    const updatedReportSchedules = { ...state.reportSchedules };
    const allReportConfigs = [];
    const daysRestrictedFrequencyCategories = [
      absSummaryNotificationFrequencies.DAILY.queryFieldName,
      absSummaryNotificationFrequencies.SEMI_MONTHLY.queryFieldName,
      absSummaryNotificationFrequencies.MONTHLY.queryFieldName,
    ];
    if (state.allReportConfigs) {
      state.allReportConfigs.forEach(config => {
        if (config.id === reportConfig.id) {
          allReportConfigs.push(reportConfig);
        } else {
          allReportConfigs.push(config);
        }
      });
    }

    if (daysRestrictedFrequencyCategories.some(it => it === reportConfig?.reportingSchedules?.category)) {
      reportConfig.reportingSchedules.schedules = [{ dayOfWeek: null, time: reportConfig?.reportingSchedules?.schedules[0]?.time ?? null }];
      updatedReportSchedules.selectedDays = [];
    }
    return {
      ...state,
      reportConfigLoading: false,
      reportConfigError: false,
      allReportConfigs,
      loadedReportConfig: reportConfig,
      currentReportConfig: reportConfig,
      reportSchedules: updatedReportSchedules,
    };
  },
  _updateReportConfigFail(state) {
    return {
      ...state,
      reportConfigLoading: false,
      reportConfigError: true,
      currentReportConfig: null,
    };
  },
  _loadReportConfigBegin(state, { configId }) {
    return {
      ...state,
      loadedReportConfigId: configId,
      reportConfigLoading: true,
      reportConfigError: null,
      loadAlertRuleId: null,
      loadedAlertRuleOrig: null,
      allReportingScheduleDays: Object.values(reportTimeFrameDays).map(day => ({ ...day, active: false, timeFrames: [] })),
      currentReportConfig: null,
      reportSchedules: {
        selectedDays: [],
        selectedTimeFrames: [],
      },
    };
  },
  _loadReportConfigSuccess(state, { loadedReportConfig }) {
    const loadedReportingSchedules = loadedReportConfig?.reportingSchedules?.schedules ?? [];
    let updatedReportSchedules = { ...state.reportSchedules };

    loadedReportingSchedules.forEach(schedule => {
      const scheduleDay = Object.values(reportTimeFrameDays).find(it => it.dayOfWeek === schedule?.dayOfWeek);
      if (scheduleDay && !updatedReportSchedules.selectedDays.includes(scheduleDay)) {
        updatedReportSchedules.selectedDays.push(scheduleDay);
      }
      const currentTimeFrame = { label: schedule?.time, value: schedule?.time };
      if (!updatedReportSchedules.selectedTimeFrames.includes(currentTimeFrame)) {
        updatedReportSchedules.selectedTimeFrames.push(currentTimeFrame);
      }
    });

    const updatedReportingSchedulesDays = state.allReportingScheduleDays?.map(schedule => {
      const filteredReportingSchedulesByDay = loadedReportingSchedules?.filter(atfs => atfs?.dayOfWeek === schedule?.dayOfWeek);
      if (!_isEmpty(filteredReportingSchedulesByDay)) {
        schedule.active = true;
        schedule.timeFrames = filteredReportingSchedulesByDay;
      }
      return schedule;
    });
    return {
      ...state,
      currentReportConfig: loadedReportConfig,
      loadedReportConfig,
      reportConfigLoading: false,
      reportConfigError: null,
      reportConfigType: findReportTypeById(loadedReportConfig?.reportTypeId) ?? null,
      allReportingScheduleDays: updatedReportingSchedulesDays,
      reportSchedules: updatedReportSchedules,
    };
  },
  _loadReportConfigFail(state) {
    return {
      ...state,
      reportConfigLoading: false,
      reportConfigError: true,
      currentReportConfig: null,
    };
  },
  _deleteReportConfigBegin(state) {
    return {
      ...state,
      reportConfigLoading: true,
      reportConfigError: false,
    };
  },
  _deleteReportConfigSuccess(state) {
    return {
      ...state,
      reportConfigLoading: false,
      reportConfigError: false,
      currentReportConfig: null,
      loadedReportConfig: null,
      loadedReportConfigId: null,
    };
  },
  _deleteReportConfigFail(state) {
    return {
      ...state,
      reportConfigLoading: false,
      reportConfigError: true,
      currentReportConfig: null,
    };
  },
  _updateReportConfigStatusBegin(state, { reportConfigId }) {
    return {
      ...state,
      isTogglingActiveReportConfigId: reportConfigId,
      isTogglingActiveReportError: null,
    };
  },
  _updateReportConfigStatusSuccess(state, { status, reportConfigId }) {
    let updatedReportConfigs = _cloneDeep(state.allReportConfigs ?? []);
    const targetReportConfigIdx = updatedReportConfigs.findIndex(rc => rc.id === reportConfigId);
    if (targetReportConfigIdx >= 0) {
      updatedReportConfigs[targetReportConfigIdx].deactivated = !status;
    }
    return {
      ...state,
      allReportConfigs: updatedReportConfigs ?? [],
      filteredReportConfigs: updatedReportConfigs ?? [],
      isTogglingActiveReportConfigId: null,
      isTogglingActiveReportError: null,
    };
  },
  _updateReportConfigStatusFail(state) {
    return {
      ...state,
      isTogglingActiveReportConfigId: null,
      isTogglingActiveReportError: true,
    };
  },
  _setReportConfigScheduleDetails(state, { selectedDays, selectedTimeFrames }) {
    const updatedReportSchedules = { ...state.reportSchedules };
    updatedReportSchedules.selectedDays = selectedDays;
    updatedReportSchedules.selectedTimeFrames = selectedTimeFrames;

    const updatedReportConfig = {
      ...state.currentReportConfig,
      reportingSchedules: {
        ...state.currentReportConfig?.reportingSchedules,
        timezone: browsersTimezone,
        schedules: [
          {
            dayOfWeek: !_isEmpty(selectedDays) ? selectedDays[0]?.dayOfWeek : null,
            time: !_isEmpty(selectedTimeFrames) ? selectedTimeFrames[0]?.value : null,
          },
        ],
      },
    };

    return {
      ...state,
      currentReportConfig: updatedReportConfig,
      reportSchedules: updatedReportSchedules,
    };
  },
  _openAlertDialogBox(state, { title, message }) {
    return {
      ...state,
      alertDialogOpen: true,
      alertDialogTitle: title,
      alertDialogMessage: message,
    };
  },
  _closeAlertDialogBox(state) {
    return {
      ...state,
      alertDialogOpen: false,
    };
  },
  _createThermoKingReportBegin(state) {
    return {
      ...state,
      reportConfigLoading: true,
      reportConfigError: false,
    };
  },
  _createThermoKingReportSuccess(state) {
    return {
      ...state,
      reportConfigLoading: false,
      reportConfigError: false,
      reportConfigType: null,
      currentReportConfig: null,
    };
  },
  _createThermoKingReportFail(state) {
    return {
      ...state,
      reportConfigLoading: false,
      reportConfigError: true,
      reportConfigType: null,
      currentReportConfig: null,
    };
  },
  _setGeofenceOccupancyInvertedValue(state, { value }) {
    return {
      ...state,
      isGeofenceOccupancyInvertedValue: value,
    };
  },
  _setReportEmailFrequency(state, { emailFrequency }) {
    const updatedReportConfig = {
      ...state.currentReportConfig,
      emailFrequency,
    };
    return {
      ...state,
      currentReportConfig: updatedReportConfig,
    };
  },
  _setIncludeSensorDataValue(state, { value }) {
    if (!state.alertRule) {
      return state;
    }
    const alertRule = { ...state.alertRule };
    alertRule.includeSensorData = value;

    return {
      ...state,
      alertRule,
    };
  },
};

const alertConfigurationReducer = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default alertConfigurationReducer;
