import _get from 'lodash/get';
import ReactGA from 'react-ga4';
import moment from 'moment';
import { userName } from '../config/properties';
import { validateToken } from '../utils/authUtils';
import { sendLoginCredentials } from '../api/loginApi';
import { isUserTechnician, determineUserRole, isAdminOrCustomer, isUserAdmin } from '../security/authorization';
import { getCurrentUserLocation } from '../utils/getCurrentUserLocationUtil';

import {
  RECEIVE_USER_DATA,
  TOGGLE_LOGIN_LOADING,
  FETCH_LOGIN_FAIL,
  SET_IS_USER_AUTHENTICATED,
  SET_LOGIN_FAIL_MESSAGE,
  TOGGLE_ME_DATA_LOADING,
  FETCH_ME_DATA_FAIL,
  RECEIVE_ME_DATA,
  SET_PARTIAL_USER_DATA,
  SET_TERMS_OF_USE_DIALOG_STATUS,
  SET_CANCEL_TERMS_OF_USE_ACCEPTANCE_STATUS,
  SET_PASSWORD_EXPIRATION,
  CONTINUE_LOGIN,
  widgetTimestampsFormat,
} from '../constants';
import cms from '../messages/index';
import { doQuery } from '../utils/fetch';
import { fetchMeQuery } from '../api/meApi';
import { checkGraphqlQueryResponse } from '../utils/graphqlUtils';
import { refreshToken, sendLoginV2Credentials } from '../api/loginV2Api';

const toggleLoginLoading = () => ({ type: TOGGLE_LOGIN_LOADING });

const setPasswordExpiration = passwordExpirationDays => ({ type: SET_PASSWORD_EXPIRATION, passwordExpirationDays });

const continueLogin = continueLogin => ({ type: CONTINUE_LOGIN, continueLogin });

const fetchLoginFailure = () => ({ type: FETCH_LOGIN_FAIL });

const setLoginFailMessage = errorMessage => ({ type: SET_LOGIN_FAIL_MESSAGE, errorMessage });

export const receiveLoginData = userData => ({
  type: RECEIVE_USER_DATA,
  payload: { userData },
});

export const login = (credentials, history) => async dispatch => {
  try {
    dispatch(toggleLoginLoading());
    const response = await sendLoginCredentials(credentials.username, credentials.password);

    const responseMessage = _get(response, 'message', '');
    if (responseMessage) {
      dispatch(setLoginFailMessage(cms.errorUserdata));
    }

    dispatch(
      receiveLoginData({
        email: credentials.email,
        idToken: response.token,
        encryptedToken: response.encryptedToken,
        username: credentials.username,
      }),
    );
    dispatch(toggleLoginLoading());
    window.localStorage.setItem(userName, credentials.username);
    window.localStorage.setItem('id_token', response.token);
    window.localStorage.setItem('encrypted_token', response.encryptedToken);
    window.localStorage.setItem('user_roles', determineUserRole());

    ReactGA.set({ user_id: credentials.username });

    const redirectPath = _get(history, 'location.state.redirectLocation', '/control');

    if (isAdminOrCustomer()) {
      history.push(redirectPath);
    } else if (isUserTechnician()) {
      history.push('/help/troubleshooting');
      getCurrentUserLocation();
    } else history.push(redirectPath);
    dispatch(fetchMeData());
  } catch (err) {
    dispatch(fetchLoginFailure());
    dispatch(toggleLoginLoading());
  }
};

const setIsUserAuthenticated = (isLoggedIn, userEmail) => ({
  type: SET_IS_USER_AUTHENTICATED,
  isLoggedIn,
  userEmail,
});

// eslint-disable-next-line consistent-return
export const checkIsUserAuthenticated = () => async dispatch => {
  const isAuthenticated = validateToken();
  if (isAuthenticated) {
    const userEmail = localStorage.getItem('userName');
    dispatch(setIsUserAuthenticated(isAuthenticated, userEmail));
  } else {
    dispatch(setIsUserAuthenticated(false));
  }
};

const toggleMeDataLoading = () => ({ type: TOGGLE_ME_DATA_LOADING });

const fetchMeDataFail = error => ({ type: FETCH_ME_DATA_FAIL, error });

const receiveMeData = data => ({
  type: RECEIVE_ME_DATA,
  data,
});

export const fetchMeData = () => async dispatch => {
  try {
    dispatch(toggleMeDataLoading());
    const response = await doQuery(fetchMeQuery);
    const hasSomeError = await checkGraphqlQueryResponse({ response: response.clone() });
    if (hasSomeError) {
      dispatch(fetchMeDataFail('Unknown error occurred!'));
    } else {
      const { data } = await response.json();
      dispatch(receiveMeData(data?.me ?? {}));
      const customerId = window.localStorage.getItem('customerId');
      const username = window.localStorage.getItem('userName');
      const formattedCustomerNames = data?.me?.customerNames?.length > 1 ? data?.me?.customerNames?.join(', ') : data?.me?.customerNames?.toString();
      const customerNames = isUserAdmin() ? 'Admin' : formattedCustomerNames ?? '';
      window.localStorage.setItem('customerNames', customerNames);
      // Send user identification data to Stonly
      window.StonlyWidget('identify', username);

      // Send Login Event to GTM dataLayer
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'login',
        userId: username,
        loginTimestamp: moment().format(widgetTimestampsFormat),
        customerId,
        customerNames,
      });
    }
    dispatch(toggleMeDataLoading());
  } catch (err) {
    console.log('err', err);
    dispatch(toggleMeDataLoading());
    dispatch(fetchMeDataFail('Unknown error occurred!'));
  }
};

const setPartialUserData = data => ({
  type: SET_PARTIAL_USER_DATA,
  data,
});

export const setTermsOfUseDialogStatus = dialogStatus => ({
  type: SET_TERMS_OF_USE_DIALOG_STATUS,
  dialogStatus,
});

export const setCancelTermsOfUseAcceptanceStatus = status => ({
  type: SET_CANCEL_TERMS_OF_USE_ACCEPTANCE_STATUS,
  status,
});

export const loginV2 = (credentials, history, loginContinue) => async dispatch => {
  try {
    dispatch(toggleLoginLoading());
    dispatch(setCancelTermsOfUseAcceptanceStatus(false));
    const response = await sendLoginV2Credentials(credentials.username, credentials.password);

    if (response.errorMessage === cms.userMustAcceptTermsOfService) {
      window.localStorage.setItem(userName, credentials.username);
      dispatch(
        setPartialUserData({
          email: credentials.email,
          errorMessage: response?.errorMessage ?? '',
          termsOfServiceUrl: response?.termsOfServiceUrl ?? '',
        }),
      );
      dispatch(setTermsOfUseDialogStatus(true));
      dispatch(toggleLoginLoading());
      return;
    }

    const passwordExpired = response?.message === `Account password has expired for user ${credentials.username}. Please reset your password.`;
    const passwordExpirationDaysLeft = passwordExpired ? 0 : response?.passwordExpirationDaysLeft;
    const passwordExpiring = passwordExpirationDaysLeft <= 15 && !loginContinue;

    if (passwordExpired || passwordExpiring) {
      dispatch(setPasswordExpiration(passwordExpirationDaysLeft));
      dispatch(continueLogin(true));
      dispatch(toggleLoginLoading());
      return;
    }

    dispatch(continueLogin(false));

    const responseMessage = _get(response, 'message', '');
    if (responseMessage) {
      dispatch(setLoginFailMessage(cms.errorUserdata));
    }

    dispatch(
      receiveLoginData({
        email: credentials.email,
        idToken: response.token,
        encryptedToken: response.encryptedToken,
        username: credentials.username,
      }),
    );
    dispatch(toggleLoginLoading());
    window.localStorage.setItem(userName, credentials.username);
    window.localStorage.setItem('id_token', response.token);
    window.localStorage.setItem('refresh_token', response.refreshToken);
    window.localStorage.setItem('encrypted_token', response.encryptedToken);
    window.localStorage.setItem('user_roles', determineUserRole());

    ReactGA.set({ user_id: credentials.username });

    const redirectPath = _get(history, 'location.state.redirectLocation', '/control');

    if (isAdminOrCustomer()) {
      history.push(redirectPath);
    } else if (isUserTechnician()) {
      history.push('/help/troubleshooting');
      getCurrentUserLocation();
    } else history.push(redirectPath);
    dispatch(fetchMeData());
  } catch (err) {
    console.log('err', err);
    dispatch(fetchLoginFailure());
    dispatch(toggleLoginLoading());
  }
};

export const renewAccessToken = async () => {
  try {
    const response = await refreshToken();
    window.localStorage.setItem('id_token', response?.token);
    window.localStorage.setItem('encrypted_token', response?.encryptedToken);
  } catch (err) {
    console.log('err', err);
  }
};
