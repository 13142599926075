import React from 'react';
import _get from 'lodash/get';
import { Route, Redirect } from 'react-router-dom';
import { validateToken } from '../utils/authUtils';
import Layout from '../components/Layout/Layout';

const LoginRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const redirectLocation = _get(props, 'location.state.redirectLocation', '/control');

      return validateToken() === false ? (
        <Layout>
          <Component {...props} />
        </Layout>
      ) : (
        <Redirect to={redirectLocation} />
      );
    }}
  />
);
export default LoginRoute;
