import React, { useState, useMemo, forwardRef, useCallback, useRef, useEffect, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import userManagementAttributes from '../../../constants/enums/userManagementAttributes';
import { formatUserMngmntColumns, formatUserMngmntData } from '../../../utils/userManagementUtils';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import UserEnabledCustomComponent from './UserEnabledCustomComponent';
import UserEditCustomComponent from './UserEditCustomComponent';
import UserMngmntCustomFloatingFilter from './UserMngmntCustomFloatingFilter';
import { isUserAdmin } from '../../../security/authorization';
import CustomDateComponent from '../../CurrentFleetStatus/components/CustomDateComponent';
import { findCustomerAdminFilteringOptions } from '../../../constants/enums/userTypes';
import EnableTKCustomComponent from './EnableTKCustomComponent';
import { doAuthQuery } from '../../../utils/fetch';
import { fetchUserManagementApiV2 } from '../../../api/userManagementApi';
import { csvNameTimestamp } from '../../../constants';
import moment from 'moment';

const UserMngmtTable = forwardRef((props, ref) => {
  const {
    hasCustomerAdminRole,
    setSelectedUsers,
    filtersActive,
    onToggleActiveState,
    onToggleCommandControlState,
    onClickEdit,
    selectedCustomer,
    onClickDelete,
    previousFilterModel,
    setPreviousFilterModel,
  } = props;
  const abortControllerRef = useRef(null);
  const containerRef = useRef(null);
  const userTypeFilteringOptions = isUserAdmin() ? userManagementAttributes.TYPE.columnFilterOptions : findCustomerAdminFilteringOptions();
  const [gridApi, setGridApi] = useState(null);
  const [pageSize, setPageSize] = useState(20);
  const [exportClicked, setExportClicked] = useState(false);

  useImperativeHandle(ref, () => ({
    csvExport() {
      csvExport();
    },
    userNameSearch(val) {
      userNameSearch(val);
    },
  }));

  const csvExport = () => {
    setPageSize(gridApi?.paginationProxy?.masterRowCount ?? calculateItemsPerPage());
    setExportClicked(true);
  };

  const userNameSearch = val => {
    const filterModel = {
      [userManagementAttributes.USERNAME.attributeName]: {
        filter: val,
      },
    };
    gridApi.setFilterModel(filterModel);
  };

  const handleEditClick = useCallback(
    data => {
      const model = gridApi?.getFilterModel();
      setPreviousFilterModel(model);
      onClickEdit(data);
    },
    [onClickEdit, gridApi, setPreviousFilterModel],
  );

  const [components] = useState({
    accountStatusCustomFloatingFilter: UserMngmntCustomFloatingFilter({
      columnName: userManagementAttributes.ACCOUNT_STATUS.attributeName,
      columnIdentifier: userManagementAttributes.ACCOUNT_STATUS.customSelectIdentifierName,
      columnFilteringOptions: userManagementAttributes.ACCOUNT_STATUS.columnFilterOptions,
    }),
    emailVerifiedCustomFloatingFilter: UserMngmntCustomFloatingFilter({
      columnName: userManagementAttributes.EMAIL_VERIFIED.attributeName,
      columnIdentifier: userManagementAttributes.EMAIL_VERIFIED.customSelectIdentifierName,
      columnFilteringOptions: userManagementAttributes.EMAIL_VERIFIED.columnFilterOptions,
    }),
    roleTypeCustomFloatingFilter: UserMngmntCustomFloatingFilter({
      columnName: userManagementAttributes.TYPE.attributeName,
      columnIdentifier: userManagementAttributes.TYPE.customSelectIdentifierName,
      columnFilteringOptions: userTypeFilteringOptions,
    }),
    userEnabledCustomFloatingFilter: UserMngmntCustomFloatingFilter({
      columnName: userManagementAttributes.ENABLED.attributeName,
      columnIdentifier: userManagementAttributes.ENABLED.customSelectIdentifierName,
      columnFilteringOptions: userManagementAttributes.ENABLED.columnFilterOptions,
    }),
    userEnabled: props => <UserEnabledCustomComponent {...props} onToggleActiveState={onToggleActiveState} />,
    tkCommandControl: props => <EnableTKCustomComponent {...props} onToggleCommandControlState={onToggleCommandControlState} />,
    userRoleEdit: props => <UserEditCustomComponent {...props} onClickEdit={handleEditClick} onClickDelete={onClickDelete} />,
    agDateInput: CustomDateComponent,
  });

  const onGridReady = params => {
    setGridApi(params.api);
    if (previousFilterModel) {
      retainCustomFiltersState(params);
    }
    params.api.sizeColumnsToFit();
    window.onresize = () => {
      params.api.sizeColumnsToFit();
    };
  };

  const retainCustomFiltersState = params => {
    params.api.setFilterModel(previousFilterModel);
    document.getElementById(userManagementAttributes.ENABLED.customSelectIdentifierName).value = previousFilterModel?.enabled?.filter ?? 'All';
    document.getElementById(userManagementAttributes.ACCOUNT_STATUS.customSelectIdentifierName).value = previousFilterModel?.accountStatus?.filter ?? 'All';
    document.getElementById(userManagementAttributes.EMAIL_VERIFIED.customSelectIdentifierName).value = previousFilterModel?.emailVerified?.filter ?? 'All';
    document.getElementById(userManagementAttributes.TYPE.customSelectIdentifierName).value = previousFilterModel?.roleType?.filter ?? 'All';
    params.api.onFilterChanged();
  };

  const themeStyle = { height: '90%' };
  const columns = useMemo(
    () =>
      Object.values(userManagementAttributes)
        .map(formatUserMngmntColumns)
        .map(col => {
          return {
            ...col,
            floatingFilter: filtersActive,
            suppressMenu: filtersActive,
          };
        }),
    [filtersActive],
  );

  useEffect(() => {
    const handleResize = () => {
      const newItemsPerPage = calculateItemsPerPage();
      setPageSize(newItemsPerPage);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const calculateItemsPerPage = () => {
    if (containerRef.current) {
      const containerHeight = containerRef.current.clientHeight;
      const itemHeight = 60;

      const numberOfItems = Math.floor(containerHeight / itemHeight);
      return numberOfItems;
    }
    return 20;
  };

  const exportCsv = useCallback(() => {
    const csvParams = {
      fileName: `Users Export ${moment().format(csvNameTimestamp)}`,
    };
    gridApi.exportDataAsCsv(csvParams);
    setPageSize(calculateItemsPerPage());
    setExportClicked(false);
    return;
  }, [gridApi]);

  const userManagementData = useCallback(
    data => {
      return data?.map(user => formatUserMngmntData(user, hasCustomerAdminRole));
    },
    [hasCustomerAdminRole],
  );

  const fetchUserManagementData = useCallback(() => {
    if (!gridApi) return;

    const fetchData = async params => {
      gridApi.showLoadingOverlay();
      if (abortControllerRef.current) {
        abortControllerRef.current.abort('New request initiated');
      }
      const abortController = new AbortController();
      abortControllerRef.current = abortController;

      const { filterModel, sortModel, endRow } = params;
      const filters = filterModel ? Object.entries(filterModel) : null;
      const sort = sortModel?.[0] ?? null;
      const pageNumber = Math.floor(endRow / pageSize);
      try {
        const response = await doAuthQuery(fetchUserManagementApiV2(pageNumber, pageSize, filters, sort, selectedCustomer?.id), {}, abortController.signal);
        const data = await response.json();
        const formattedData = userManagementData(data?.data?.users?.users);
        const userCount = data?.data?.users?.userCount ?? 0;

        params.successCallback(formattedData, userCount);
        gridApi.hideOverlay();

        if (userCount === pageSize && exportClicked) {
          exportCsv();
        }
      } catch (error) {
        params.successCallback([], 0);
        if (error !== 'New request initiated') {
          console.error(error);
          gridApi.hideOverlay();
        }
      }
    };

    const dataSource = { getRows: fetchData };
    gridApi.setDatasource(dataSource);
  }, [gridApi, userManagementData, pageSize, exportCsv, exportClicked, selectedCustomer]);

  useEffect(() => {
    fetchUserManagementData();
  }, [fetchUserManagementData]);

  return (
    <div className="ag-theme-alpine" style={themeStyle} ref={containerRef}>
      <AgGridReact
        columnDefs={columns}
        components={components}
        onGridReady={onGridReady}
        rowSelection="multiple"
        suppressDragLeaveHidesColumns
        onSelectionChanged={() => {
          const selectedRows = gridApi?.getSelectedRows() ?? [];
          setSelectedUsers(selectedRows);
        }}
        suppressRowClickSelection
        enableCellTextSelection
        pagination={true}
        rowModelType={'infinite'}
        paginationPageSize={pageSize}
        cacheBlockSize={pageSize}
        suppressColumnVirtualisation
        suppressPropertyNamesCheck
      />
    </div>
  );
});

UserMngmtTable.propTypes = {
  hasCustomerAdminRole: PropTypes.bool,
  setSelectedUsers: PropTypes.func.isRequired,
  filtersActive: PropTypes.bool,
  onToggleActiveState: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  selectedCustomer: PropTypes.object,
};

UserMngmtTable.defaultProps = {
  hasCustomerAdminRole: false,
  filtersActive: true,
  selectedCustomer: null,
};

export default UserMngmtTable;
