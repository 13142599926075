import React from 'react';
import _get from 'lodash/get';
import { Route, Redirect } from 'react-router-dom';
import { validateToken } from '../utils/authUtils';
import Layout from '../components/Layout/Layout';

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const redirectLocation = _get(rest, 'location.pathname', '/control');
      return validateToken() === true ? (
        <Layout>
          <Component {...props} />
        </Layout>
      ) : (
        <Redirect to={{ pathname: '/', state: { redirectLocation } }} />
      );
    }}
  />
);
export default PrivateRoute;
