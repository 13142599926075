import idx from 'idx';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { handleErrors } from '../utils/errorUtils';
import { fetchUserManagementApi } from '../api/userManagementApi';
import { fetchCustomersApi } from '../api/customersApi';
import { createAccountApi } from '../api/createAccountApi';
import { resetUserPasswordRequestApi } from '../api/resetUserPasswordRequestApi';
import { sendVerificationEmailApi } from '../api/sendVerificationEmailApi';
import { disableUserApi } from '../api/disableUserApi';

import {
  SET_USER_MANAGEMENT_DATA_LOADING,
  FETCH_USER_MANAGEMENT_DATA_FAIL,
  FETCH_USER_MANAGEMENT_DATA_SUCCESS,
  TOGGLE_USER_SELECT,
  TOGGLE_ALL_USERS_SELECT,
  SEARCH_ALL_USERS,
  SET_CUSTOMERS_DATA_LOADING,
  FETCH_CUSTOMERS_DATA_FAIL,
  FETCH_CUSTOMERS_DATA_SUCCESS,
  OPEN_CREATE_ACCOUNT_MODAL,
  SET_EMAIL_ADDRESS,
  SET_SELECTED_USER_TYPES,
  SET_SELECTED_CUSTOMERS,
  SET_SELECTED_GO_CUSTOMERS,
  SET_SELECTED_INSTALLER_CUSTOMERS,
  SET_SELECTED_ADMIN_CUSTOMERS,
  SET_DISPLAY_CUSTOMERS,
  SET_DISPLAY_GO_CUSTOMERS,
  SET_DISPLAY_INSTALLER_CUSTOMERS,
  SET_DISPLAY_ADMIN_CUSTOMERS,
  CREATE_USER_BEGIN,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
  CLOSE_RESEND_EMAIL_MODAL,
  SET_RESEND_EMAIL_TYPE,
  RESEND_EMAIL_BEGIN,
  RESEND_EMAIL_SUCCESS,
  RESEND_EMAIL_FAIL,
  SET_EDIT_USER_MODE,
  DISABLE_USER,
  OPEN_CREATE_NEW_USER_DIALOG,
  OPEN_EDIT_USER_DIALOG,
  OPEN_DELETE_USER_DIALOG,
  OPEN_USER_ALERT_DIALOG_BOX,
  CLOSE_USER_ALERT_DIALOG_BOX,
  DELETE_USER_BEGIN,
  DELETE_USER_FAIL,
  DELETE_USER_SUCCESS,
} from '../constants';
import { doQuery } from '../utils/fetch';
import { deleteUserApi } from '../api/deleteUserApi';
import { checkGraphqlQueryResponse } from '../utils/graphqlUtils';
import cms from '../messages';

export const setUserManagementDataLoading = loading => ({
  type: SET_USER_MANAGEMENT_DATA_LOADING,
  loading,
});

export const fetchUserManagementDataFail = error => ({
  type: FETCH_USER_MANAGEMENT_DATA_FAIL,
  error,
});

export const fetchUserManagementDataSuccess = users => ({
  type: FETCH_USER_MANAGEMENT_DATA_SUCCESS,
  users,
});

export const toggleUserSelect = user => ({
  type: TOGGLE_USER_SELECT,
  user,
});

export const toggleAllUsersSelect = event => ({
  type: TOGGLE_ALL_USERS_SELECT,
  event,
});

export const searchAllUsers = keyword => ({
  type: SEARCH_ALL_USERS,
  keyword,
});

export const setCustomersDataLoading = customersDataLoading => ({
  type: SET_CUSTOMERS_DATA_LOADING,
  customersDataLoading,
});

export const getUsersData = () => async dispatch => {
  try {
    dispatch(setUserManagementDataLoading(true));
    const resp = await doQuery(fetchUserManagementApi);
    const hasSomeError = await checkGraphqlQueryResponse({ response: resp.clone() });
    if (hasSomeError) {
      dispatch(setUserManagementDataLoading(false));
      dispatch(fetchUserManagementDataFail('Unknown error occurred!'));
    } else {
      const { data } = await resp.json();
      let users = [];
      if (data && data?.users) {
        users = data?.users?.filter(user => user?.accountStatus);
      }
      dispatch(fetchUserManagementDataSuccess(users));
      dispatch(setUserManagementDataLoading(false));
    }
  } catch (error) {
    dispatch(fetchUserManagementDataFail(error));
    dispatch(setUserManagementDataLoading(false));
  }
};

export const fetchCustomersDataFail = customersDataError => ({
  type: FETCH_CUSTOMERS_DATA_FAIL,
  customersDataError,
});

export const fetchCustomersDataSuccess = customers => ({
  type: FETCH_CUSTOMERS_DATA_SUCCESS,
  customers,
});

export const getCustomersData = () => async dispatch => {
  try {
    dispatch(setCustomersDataLoading(true));
    const resp = await doQuery(fetchCustomersApi);
    handleErrors(resp);

    const { data } = await resp.json();
    if (!_isEmpty(_get(data, 'errors'))) {
      dispatch(setCustomersDataLoading(false));
      dispatch(fetchCustomersDataFail(data.errors));
      return;
    }

    let customers = [];
    if (data && data.customers) {
      customers = data.customers.filter(customer => customer.id !== '0');
    }

    dispatch(fetchCustomersDataSuccess(customers));
    dispatch(setCustomersDataLoading(false));
  } catch (error) {
    dispatch(fetchCustomersDataFail(error));
    dispatch(setCustomersDataLoading(false));
  }
};

export const setEmailAddress = (emailAddress, emailAddressError) => ({
  type: SET_EMAIL_ADDRESS,
  emailAddress,
  emailAddressError,
});

export const setSelectedUserTypes = selectedUserTypes => ({
  type: SET_SELECTED_USER_TYPES,
  selectedUserTypes,
});

export const setSelectedCustomers = selectedCustomers => ({
  type: SET_SELECTED_CUSTOMERS,
  selectedCustomers,
});

export const setSelectedGOCustomers = selectedGOCustomers => ({
  type: SET_SELECTED_GO_CUSTOMERS,
  selectedGOCustomers,
});

export const setSelectedInstallerCustomers = selectedInstallerCustomers => ({
  type: SET_SELECTED_INSTALLER_CUSTOMERS,
  selectedInstallerCustomers,
});

export const setSelectedAdminCustomers = selectedAdminCustomers => ({
  type: SET_SELECTED_ADMIN_CUSTOMERS,
  selectedAdminCustomers,
});

export const setDisplayCustomers = displayCustomers => ({
  type: SET_DISPLAY_CUSTOMERS,
  displayCustomers,
});

export const setDisplayGOCustomers = displayGOCustomers => ({
  type: SET_DISPLAY_GO_CUSTOMERS,
  displayGOCustomers,
});

export const setDisplayInstallerCustomers = displayInstallerCustomers => ({
  type: SET_DISPLAY_INSTALLER_CUSTOMERS,
  displayInstallerCustomers,
});

export const setDisplayAdminCustomers = displayAdminCustomers => ({
  type: SET_DISPLAY_ADMIN_CUSTOMERS,
  displayAdminCustomers,
});

export const openCreateAccountModal = () => ({
  type: OPEN_CREATE_ACCOUNT_MODAL,
});

export const openCreateUserDialog = newCreatedUser => ({
  type: OPEN_CREATE_NEW_USER_DIALOG,
  newCreatedUser,
});

export const openEditUserDialog = newCreatedUser => ({
  type: OPEN_EDIT_USER_DIALOG,
  newCreatedUser,
});

export const openDeleteUserDialog = (deletedUser, deleteAccountError) => ({
  type: OPEN_DELETE_USER_DIALOG,
  deletedUser,
  deleteAccountError,
});

export const createAccount = (fetch, email, roleNames, isEditing) => async dispatch => {
  dispatch({ type: CREATE_USER_BEGIN });
  try {
    const resp = await createAccountApi(fetch, email, roleNames);
    const hasSomeError = await checkGraphqlQueryResponse({ response: resp.clone() });
    if (hasSomeError) {
      dispatch({
        type: CREATE_USER_FAIL,
        error: 'Unknown error occurred!',
      });
      isEditing
        ? dispatch(openUserAlertDialogBox({ title: cms.editUserTitle, message: cms.editUserFail + email }))
        : dispatch(openUserAlertDialogBox({ title: cms.createUserTitle, message: cms.createUserFail + email }));
    } else {
      dispatch({
        type: CREATE_USER_SUCCESS,
      });
      isEditing
        ? dispatch(openUserAlertDialogBox({ title: cms.editUserTitle, message: cms.editUserSuccess + email }))
        : dispatch(openUserAlertDialogBox({ title: cms.createUserTitle, message: cms.createUserSuccess + email }));
    }

    dispatch(getUsersData());
  } catch (error) {
    console.warn('Error', error);
    dispatch({
      type: CREATE_USER_FAIL,
      error: error ?? 'An unknown error occurred.',
    });
    isEditing
      ? dispatch(openUserAlertDialogBox({ title: cms.editUserTitle, message: cms.editUserFail + email }))
      : dispatch(openUserAlertDialogBox({ title: cms.createUserTitle, message: cms.createUserFail + email }));
  }
};

export const deleteAccount = (fetch, userId, email) => async dispatch => {
  dispatch({ type: DELETE_USER_BEGIN });
  try {
    const resp = await deleteUserApi(fetch, userId);
    const hasSomeError = await checkGraphqlQueryResponse({ response: resp.clone() });
    if (hasSomeError) {
      dispatch({ type: DELETE_USER_FAIL });
      dispatch(openUserAlertDialogBox({ title: cms.deleteUserTitle, message: cms.deleteUserFail + email }));
    } else {
      dispatch({ type: DELETE_USER_SUCCESS });
      dispatch(openUserAlertDialogBox({ title: cms.deleteUserTitle, message: cms.deleteUserSuccess + email }));
    }
    dispatch(getUsersData());
  } catch (error) {
    console.warn('Error:', error);
    dispatch({ type: DELETE_USER_FAIL });
    dispatch(openUserAlertDialogBox({ title: cms.deleteUserTitle, message: cms.deleteUserFail + email }));
  }
};

export const closeResendEmailModal = () => ({
  type: CLOSE_RESEND_EMAIL_MODAL,
});

export const setResendEmailType = emailType => ({
  type: SET_RESEND_EMAIL_TYPE,
  emailType,
});

export const resendEmail = (fetch, emailType, emails, userIds, isAdminOrCustomerAdmin) => async dispatch => {
  dispatch({ type: RESEND_EMAIL_BEGIN });
  if (emailType === 'RESET_PASSWORD') {
    const promises = [];

    emails.forEach(email => {
      promises.push(resetUserPasswordRequestApi(email));
    });

    Promise.all(promises)
      .then(() => {
        dispatch({
          type: RESEND_EMAIL_SUCCESS,
          emails,
        });
        dispatch(getUsersData());
      })
      .catch(() => {
        dispatch({
          type: RESEND_EMAIL_FAIL,
          error: 'Failed to send reset password email.',
        });
      });
  } else if (emailType === 'RESEND_VERIFICATION') {
    const promises = [];

    userIds.forEach(userId => {
      promises.push(sendVerificationEmailApi(fetch, userId));
    });

    Promise.all(promises)
      .then(() => {
        dispatch({
          type: RESEND_EMAIL_SUCCESS,
          emails,
        });
        dispatch(getUsersData());
      })
      .catch(() => {
        dispatch({
          type: RESEND_EMAIL_FAIL,
          error: 'Failed to send verification email.',
        });
      });
  }
};

export const disableUser = (fetch, userId) => async dispatch => {
  try {
    const resp = await disableUserApi(fetch, userId);
    handleErrors(resp);
    const json = await resp.json();

    let wasSuccess = idx(json, _ => _.data.disableUser);

    if (!wasSuccess) {
      throw new Error('Unknown error occured!');
    }

    dispatch({
      type: DISABLE_USER,
      wasSuccess,
    });
    dispatch(getUsersData());
  } catch (error) {
    console.log('error', error);
    dispatch({
      type: DISABLE_USER,
      wasSuccess: false,
    });
  }
};

export const setEditUserMode = ({ emailAddress, selectedUserTypes, selectedCustomers, selectedGOCustomers, selectedInstallerCustomers, selectedAdminCustomers, isEditMode, user }) => ({
  type: SET_EDIT_USER_MODE,
  emailAddress,
  selectedUserTypes,
  selectedCustomers,
  selectedGOCustomers,
  selectedInstallerCustomers,
  selectedAdminCustomers,
  isEditMode,
  user,
});

export const openUserAlertDialogBox = ({ title, message }) => ({
  type: OPEN_USER_ALERT_DIALOG_BOX,
  title,
  message,
});
export const closeUserAlertDialogBox = () => ({
  type: CLOSE_USER_ALERT_DIALOG_BOX,
});
