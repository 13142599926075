import ReactGA from 'react-ga4';
import moment from 'moment';
import { gaTimestampFormat } from '../constants';
import { arrayToStringFormating } from './customStringUtils';

const currentTimestamp = moment().format(gaTimestampFormat);

export const sendPageViewEvent = ({ page, title }) => {
  ReactGA.send({ hitType: 'pageview', page, title });
};

export const sendCustomGAEvent = ({ category, action, label }) => {
  ReactGA.event({ category, action, label });
};

export const sendCreatedAlertEvent = ({ name, alertPredicateTypeName, notifyEmails, selectedCustomer }) => {
  const username = window.localStorage.getItem('userName');

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'create_alert_config',
    userId: username,
    alertPredicateTypeName,
    customerId: selectedCustomer?.id,
    customerNames: selectedCustomer?.shortName,
    alertName: name,
    createdAt: currentTimestamp,
    updatedAt: currentTimestamp,
    notifyEmails: arrayToStringFormating(notifyEmails ?? []),
  });
};

export const sendCreatedReportEvent = ({ name, reportTypeName, notifyEmails, selectedCustomer }) => {
  const username = window.localStorage.getItem('userName');

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'create_report',
    userId: username,
    reportTypeName,
    customerId: selectedCustomer?.id,
    customerNames: selectedCustomer?.shortName,
    reportName: name,
    createdAt: currentTimestamp,
    updatedAt: currentTimestamp,
    notifyEmails: arrayToStringFormating(notifyEmails ?? []),
  });
};

export const sendClickedFilterEvent = ({ filterName }) => {
  const username = window.localStorage.getItem('userName');
  const customerId = window.localStorage.getItem('customerId');
  const customerNames = window.localStorage.getItem('customerNames');

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'clicked_filter',
    userId: username,
    filterName,
    customerId,
    customerNames,
    timestamp: currentTimestamp,
  });
};

export const sendClickedSensorEvent = ({ sensorTypeName, sensorTypeId }) => {
  const username = window.localStorage.getItem('userName');
  const customerId = window.localStorage.getItem('customerId');
  const customerNames = window.localStorage.getItem('customerNames');

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'clicked_sensor',
    userId: username,
    sensorTypeId,
    sensorTypeName,
    customerId,
    customerNames,
    timestamp: currentTimestamp,
  });
};

export const sendCustomPageViewEvent = ({ pageName, pageUrl }) => {
  const username = window.localStorage.getItem('userName');
  const customerId = window.localStorage.getItem('customerId');
  const customerNames = window.localStorage.getItem('customerNames');

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'custom_page_view',
    userId: username,
    pageName,
    pageUrl,
    customerId,
    customerNames,
    timestamp: currentTimestamp,
  });
};
