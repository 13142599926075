import { isUserAdmin } from '../../security/authorization';

export default {
  CUSTOMER_ID: {
    displayName: 'Customer ID',
    apiResponseParsePath: 'id',
    fieldName: 'id',
    partsPage: {
      flex: 1,
      sortable: true,
      filter: true,
      suppressMenu: true,
      resizable: true,
      checkboxSelection: true,
    },
  },
  ABBREV: {
    displayName: 'Abbreviation',
    apiResponseParsePath: 'abbrev',
    fieldName: 'abbrev',
    partsPage: {
      flex: 1,
      sortable: true,
      filter: true,
      suppressMenu: true,
      resizable: true,
      editable: isUserAdmin(),
      cellEditor: 'validationCellEditor',
      cellRenderer: 'editableCellRenderer',
      singleClickEdit: true,
    },
  },
  SHORT_NAME: {
    displayName: 'Short Name',
    apiResponseParsePath: 'shortName',
    fieldName: 'short_name',
    partsPage: {
      flex: 1,
      sortable: true,
      filter: true,
      suppressMenu: true,
      resizable: true,
      editable: isUserAdmin(),
      cellEditor: 'validationCellEditor',
      cellRenderer: 'editableCellRenderer',
      singleClickEdit: true,
    },
  },
  LONG_NAME: {
    displayName: 'Long Name',
    apiResponseParsePath: 'longName',
    fieldName: 'long_name',
    partsPage: {
      flex: 1,
      sortable: true,
      filter: true,
      suppressMenu: true,
      resizable: true,
      editable: isUserAdmin(),
      cellEditor: 'validationCellEditor',
      cellRenderer: 'editableCellRenderer',
      singleClickEdit: true,
    },
  },
  CREATED: {
    displayName: 'Created',
    apiResponseParsePath: 'created',
    fieldName: 'created',
    partsPage: {
      flex: 1,
      sortable: true,
      suppressMenu: true,
      resizable: true,
    },
  },
  UPDATED: {
    displayName: 'Updated',
    apiResponseParsePath: 'updated',
    fieldName: 'updated',
    partsPage: {
      flex: 1,
      sortable: true,
      suppressMenu: true,
      resizable: true,
    },
  },
  TOTAL_ACTIVE_ASSETS: {
    displayName: 'Total Active Assets',
    apiResponseParsePath: 'totalActiveAssets',
    fieldName: 'totalActiveAssets',
    partsPage: {
      flex: 1,
      suppressMenu: true,
      resizable: true,
    },
  },
  TOTAL_ASSETS: {
    displayName: 'Total Assets',
    apiResponseParsePath: 'totalAssets',
    fieldName: 'totalAssets',
    partsPage: {
      flex: 1,
      suppressMenu: true,
      resizable: true,
    },
  },
};
