import { env } from '../env';
import createFetch from './createFetch';

export const fleetpulseApiFetch = createFetch(fetch, {
  baseUrl: env.REACT_APP_FLEETPULSE_API_BASE_URL,
  getToken: () => window.localStorage.getItem('id_token'),
});

export const fleetpulseAuthFetch = createFetch(fetch, {
  baseUrl: env.REACT_APP_FLEETPULSE_API_BASE_URL,
  getToken: () => 'Bearer ' + window.localStorage.getItem('id_token'),
});

export const doQuery = query =>
  fleetpulseApiFetch('/graphql', {
    method: 'POST',
    body: JSON.stringify({ query }),
  });

export const doAuthQuery = query =>
  fleetpulseAuthFetch('/auth/graphql', {
    method: 'POST',
    body: JSON.stringify({ query }),
  });
