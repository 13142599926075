import navigationDrawerSensors from '../constants/enums/navigationDrawerSensors';
import sensorTypes from '../constants/enums/sensorTypeIds';
export const ACTIVITY_LOG_DEFAULT_PAGE_SIZE = 3;
export const SENSOR_TYPE_DEFAULT_PAGE_SIZE = 100;
export const ZONE_INFO_DEFAULT_PAGE_SIZE = 10;

export const fetchActivityLogApi = activityLogRequestContext => {
  const amberLampStatusSensorId = sensorTypes?.amberLampStatus?.id;
  const { vinNumber, activityLogPageNumber, activeSensor, sensorTypeIds, installedSensorTypeIds } = activityLogRequestContext;
  const mainActivityLogPageSize = activityLogPageNumber > 0 ? ACTIVITY_LOG_DEFAULT_PAGE_SIZE : ZONE_INFO_DEFAULT_PAGE_SIZE;

  const defaultPageSize =
    activeSensor !== navigationDrawerSensors.drawerFooterSensorTypesGD.ACTIVITY_LOG ? SENSOR_TYPE_DEFAULT_PAGE_SIZE : mainActivityLogPageSize;
  const formattedSensorTypeIds =
    activeSensor === navigationDrawerSensors.defaultDrawerSensorTypes.ABS ? [sensorTypeIds, amberLampStatusSensorId] : [sensorTypeIds];
  const allQureyFields = Object.values(sensorTypes)
    .filter(item => installedSensorTypeIds?.includes(item?.sensor_type_id))
    .map(sensor => sensor.queryFields)
    .toString();
  return `{
    vehicleTrackingV2(vin: "${vinNumber}", pageSize: ${defaultPageSize}, pageNumber: ${activityLogPageNumber}, sensorTypeIds: [${formattedSensorTypeIds}]) {
      activityLog {
        id
        ${activeSensor.queryPath || allQureyFields}
        recorded
      }
    }
  }`;
};

export const thermoKingSetPointActivityLog = activityLogRequestContext => {
  const { vinNumber, activityLogPageNumber } = activityLogRequestContext;
  return `{
    thermoKingSetPointActivityLog(vin: "${vinNumber}", pageSize: ${ZONE_INFO_DEFAULT_PAGE_SIZE}, pageNumber: ${activityLogPageNumber}) {
      activityLog {
        id
        recorded
        thermoKingDetails
      }
    }
  }`;
};
