export const WIDGET_GRID_LAYOUT = 'grid-layout';

export const WIDGET_ITEMS_VISIBILITY_STORAGE_KEYS = {
  urgentIssues: 'urgentIssuesItemsVisibility',
  security: 'securityItemsVisibility',
  recommendedMaintenance: 'recommendedMaintenanceItemsVisibility',
  trailerUtilization: 'trailerUtilizationItemsVisibility',
};

export const MODULES_VISIBILITY_STORAGE_KEY = 'visibleGridModules'


