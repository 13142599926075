import React from 'react';
import sensorTypeIds from './sensorTypeIds';
import brakesIcon from '../../assets/public/alertsABS.svg';
import loadStatusIcon from '../../assets/public/alertsLoad.svg';
import tetherStatusIcon from '../../assets/public/alertsTether.svg';
import tetherStatusDurationIcon from '../../assets/public/alertsTetherDuration.svg';
import doorStatusIcon from '../../assets/public/alertsDoors.svg';
import doorStatusDurationIcon from '../../assets/public/alertsDoorDuration.svg';
import batteryIcon from '../../assets/public/alertsBattery.svg';
import lightsIcon from '../../assets/public/alertsLights.svg';
import tisIcon from '../../assets/public/alertsTIS.svg';
import netBogieWeightLbsIcon from '../../assets/public/alertsLoadedWeightExceeds.svg';
import geofenceOccupancyDurationIcon from '../../assets/public/alertsGeoOccDur.svg';
import geofenceBoundaryCrossingIcon from '../../assets/public/alertsGeoBoundCross.svg';
import reeferFuelIcon from '../../assets/public/reeferFuelIcon.svg';
import reeferAlarmIcon from '../../assets/public/alertsReeferAlarm.svg';
import towAlertIcon from '../../assets/public/alertsTow.svg';
import alertsCustomAlertBell from '../../assets/public/alertsCustomAlertBell.svg';
import alertsABSReport from '../../assets/public/alertsABSReport.svg';
import alertsDwellReport from '../../assets/public/alertsDwellReport.svg';
import alertsReeferTemperatureHistoryReport from '../../assets/public/alertsReeferTemperatureHistoryReport.svg';
import alertsYardReport from '../../assets/public/alertsYardReport.svg';
import alertsGeofenceThresholdReport from '../../assets/public/alertsGeofenceThresholdReport.svg';
import alertsWheelEndTemp from '../../assets/public/wheel-temperature.svg';

export default {
  CUSTOM: {
    id: 0,
    name: '',
    buttonLabel: 'Custom Alert',
    key: 'CUSTOM',
    description: 'Combine any of the component alerts and add geofence locations to create unique alerts for your business needs. ',
    isDurationPredicate: false,
    predicateOptions: null,
    defaultPredicateFields: null,
    selectable: true,
    sensorType: null,
    isGeofencePredicate: false,
    dropdownOption: false,
    geofenceOccupancy: false,
    isReportOption: false,
    alertPredicates: false,
    hasNotificationFrequency: true,
    buttonIcon: <img src={alertsCustomAlertBell} alt="CUSTOM_ALERT" className="alertConfiguration-alertGroupIcon horizontalCardIcon" />,
  },
  GEOFENCE_BOUNDARY_CROSSING: {
    id: 1,
    name: 'Geofence Boundary Crossing',
    buttonLabel: 'Geofence Boundary Crossing',
    key: 'GEOFENCE_BOUNDARY_CROSSING',
    description: 'Receive an alert when a trailer or group of trailer’s GPS coordinates enter or exit a designated geofence boundary.',
    isDurationPredicate: false,
    predicateOptions: {
      ALL_GEOFENCES: {
        id: 1,
        key: 'ALL_GEOFENCES',
        name: 'All Geofences',
        displayName: 'All Geofences',
      },
    },
    defaultPredicateFields: {
      anyCustomerGeofence: true,
      geofenceIds: [],
      geofenceExitEvents: true,
      geofenceEnterEvents: true,
    },
    selectable: true,
    sensorType: null,
    isGeofencePredicate: true,
    dropdownOption: false,
    geofenceOccupancy: false,
    isReportOption: false,
    alertPredicates: true,
    hasNotificationFrequency: true,
    buttonIcon: <img src={geofenceBoundaryCrossingIcon} alt="GEOFENCE_BOUNDARY_CROSSING" className="alertConfiguration-alertGroupIcon" />,
  },
  GEOFENCE_OCCUPANCY_DURATION: {
    id: 2,
    name: 'Geofence Occupancy Duration',
    buttonLabel: 'Geofence Occupancy Duration',
    key: 'GEOFENCE_OCCUPANCY_DURATION',
    description: 'Receive an alert when a trailer or group of trailer’s GPS coordinates spend a specified duration inside a designated geofence.',
    isDurationPredicate: true,
    predicateOptions: {
      ALL_GEOFENCES: {
        id: 1,
        key: 'ALL_GEOFENCES',
        name: 'All Geofences',
        displayName: 'All Geofences',
      },
    },
    defaultPredicateFields: null,
    selectable: true,
    sensorType: null,
    isGeofencePredicate: true,
    dropdownOption: true,
    geofenceOccupancy: false,
    isReportOption: false,
    alertPredicates: true,
    hasNotificationFrequency: true,
    buttonIcon: <img src={geofenceOccupancyDurationIcon} alt="GEOFENCE_OCCUPANCY_DURATION" className="alertConfiguration-alertGroupIcon" />,
  },
  NET_BOGIE_WEIGHT_THRESHOLD: {
    id: 3,
    name: 'Loaded Weight Exceeds',
    buttonLabel: 'Loaded Weight Exceeds',
    key: 'NET_BOGIE_WEIGHT_THRESHOLD',
    description: 'Receive an alert when a trailer or group of trailers exceed a specified rear axle weight.',
    isDurationPredicate: true,
    predicateOptions: null,
    defaultPredicateFields: null,
    selectable: true,
    sensorType: null,
    isGeofencePredicate: false,
    dropdownOption: true,
    geofenceOccupancy: true,
    isReportOption: false,
    alertPredicates: true,
    hasNotificationFrequency: true,
    buttonIcon: <img src={netBogieWeightLbsIcon} alt="NET_BOGIE_WEIGHT_THRESHOLD" className="alertConfiguration-alertGroupIcon" />,
  },
  LOAD_STATUS_CHANGE: {
    id: 4,
    name: 'Load Status',
    buttonLabel: 'Load Status',
    key: 'LOAD_STATUS',
    description: 'Receive an alert when a trailer or group of trailers is loaded, unloaded or both.',
    isDurationPredicate: false,
    predicateOptions: {
      ANY_LOAD_STATUS: {
        id: 1,
        key: 'ANY_LOAD_STATUS',
        name: 'Any Load Status',
        displayName: 'Any Load Status',
      },
      LOADED: {
        id: 2,
        key: 'LOADED',
        name: 'Loaded',
        displayName: 'Loaded',
      },
      UNLOADED: {
        id: 3,
        key: 'UNLOADED',
        name: 'Unloaded',
        displayName: 'Unloaded',
      },
    },
    defaultPredicateFields: {
      loadStatusLoaded: true,
      loadStatusUnloaded: true,
    },
    selectable: true,
    sensorType: sensorTypeIds.enhancedWeight,
    isGeofencePredicate: false,
    dropdownOption: true,
    geofenceOccupancy: true,
    isReportOption: false,
    alertPredicates: true,
    hasNotificationFrequency: true,
    buttonIcon: <img src={loadStatusIcon} alt="LOAD_STATUS" className="alertConfiguration-alertGroupIcon" />,
  },
  ABS_FAULT: {
    id: 5,
    name: 'ABS Fault',
    buttonLabel: 'ABS Fault',
    key: 'BRAKES',
    description:
      'Receive an alert when a trailer or group of trailers exhibits an ABS Fault (when the warning lamp goes on) or when a ABS component fault occurs. ',
    isDurationPredicate: false,
    predicateOptions: {
      ABS_FAULTS: {
        id: 2,
        key: 'ABS_FAULTS',
        name: 'ABS Faults',
        displayName: 'ABS Faults',
      },
      COMPONENT_FAULTS: {
        id: 3,
        key: 'COMPONENT_FAULTS',
        name: 'Component Faults',
        displayName: 'Component Faults',
      },
    },
    defaultPredicateFields: {
      anyAbsSystemFault: true,
    },
    selectable: true,
    sensorType: sensorTypeIds.absSystemFault,
    isGeofencePredicate: false,
    dropdownOption: true,
    geofenceOccupancy: true,
    isReportOption: false,
    alertPredicates: true,
    hasNotificationFrequency: true,
    buttonIcon: <img src={brakesIcon} alt="BRAKES" className="alertConfiguration-alertGroupIcon" />,
  },
  COMPONENT_FAULT: {
    id: 6,
    name: 'Component Fault',
    buttonLabel: 'ABS Fault',
    description: '',
    predicateOptions: null,
    selectable: false,
    sensorType: null,
    isGeofencePredicate: false,
    dropdownOption: false,
    isReportOption: false,
    alertPredicates: false,
    hasNotificationFrequency: true,
  },
  LIGHTS: {
    id: 7,
    name: 'Lights',
    buttonLabel: 'Lights',
    key: 'LIGHTS',
    description: 'Receive an alert when a trailer or group of trailers is exhibiting anomalies (high or low current) on any or all of the 5 light branches.',
    isDurationPredicate: false,
    predicateOptions: {
      ANY_LIGHTS_STATUS: {
        id: 1,
        key: 'ANY_LIGHTS_STATUS',
        name: 'Any Light Status',
        displayName: 'Any Light Status',
        faultTypeIds: null,
        description: null,
      },
      HIGH_CURRENT: {
        id: 2,
        key: 'HIGH_CURRENT',
        name: 'High Current',
        displayName: 'High Current',
        faultTypeIds: [1, 5, 9, 13, 17],
        description: 'Short Circuit',
      },
      LOW_CURRENT: {
        id: 3,
        key: 'LOW_CURRENT',
        name: 'Low Current',
        displayName: 'Low Current',
        faultTypeIds: [2, 6, 10, 14, 18],
        description: 'Outage',
      },
      INTERMITTENT: {
        id: 4,
        key: 'INTERMITTENT',
        name: 'Intermittent',
        displayName: 'Intermittent',
        faultTypeIds: [3, 7, 11, 15, 19],
        description: 'Flicker',
      },
    },
    defaultPredicateFields: {
      anyLightFault: true,
      lightFaultTypeIds: [],
    },
    selectable: true,
    sensorType: sensorTypeIds.lightSystemFault,
    isGeofencePredicate: false,
    dropdownOption: true,
    geofenceOccupancy: true,
    isReportOption: false,
    alertPredicates: true,
    hasNotificationFrequency: true,
    buttonIcon: <img src={lightsIcon} alt="LIGHTS" className="alertConfiguration-alertGroupIcon" />,
  },
  TETHER_STATUS_CHANGE: {
    id: 8,
    name: 'Tether Status',
    buttonLabel: 'Tether Status',
    key: 'TETHER_STATUS',
    description: 'Receive an alert when a trailer or group of trailers is tethered or untethered to a tractor.',
    isDurationPredicate: false,
    predicateOptions: {
      ANY_TETHER_STATUS: {
        id: 1,
        key: 'ANY_TETHER_STATUS',
        name: 'Any Tether Status',
        displayName: 'Any Tether Status',
      },
      TETHERED: {
        id: 2,
        key: 'TETHERED',
        name: 'Tethered',
        displayName: 'Tethered',
      },
      UNTETHERED: {
        id: 3,
        key: 'UNTETHERED',
        name: 'Untethered',
        displayName: 'Untethered',
      },
    },
    defaultPredicateFields: {
      tethered: true,
      untethered: true,
    },
    selectable: true,
    sensorType: sensorTypeIds.tetherChange,
    isGeofencePredicate: false,
    dropdownOption: true,
    geofenceOccupancy: true,
    isReportOption: false,
    alertPredicates: true,
    hasNotificationFrequency: true,
    buttonIcon: <img src={tetherStatusIcon} alt="TETHER_STATUS_CHANGE" className="alertConfiguration-alertGroupIcon" />,
  },
  TETHER_STATUS_DURATION: {
    id: 9,
    name: 'Tether Status Duration',
    buttonLabel: 'Tether Status Duration',
    key: 'TETHER_STATUS_DURATION',
    description: 'Receive an alert when a trailer or group of trailers is tethered or untethered for a specified duration. ',
    isDurationPredicate: true,
    predicateOptions: {
      TETHERED: {
        id: 2,
        key: 'TETHERED',
        name: 'Tethered',
        displayName: 'Tethered',
      },
      UNTETHERED: {
        id: 3,
        key: 'UNTETHERED',
        name: 'Untethered',
        displayName: 'Untethered',
      },
    },
    defaultPredicateFields: null,
    selectable: true,
    sensorType: null,
    isGeofencePredicate: false,
    dropdownOption: true,
    geofenceOccupancy: true,
    isReportOption: false,
    alertPredicates: true,
    hasNotificationFrequency: true,
    buttonIcon: <img src={tetherStatusDurationIcon} alt="TETHER_STATUS_DURATION" className="alertConfiguration-alertGroupIcon" />,
  },
  DOOR_STATUS_CHANGE: {
    id: 10,
    name: 'Door Status',
    buttonLabel: 'Door Status',
    key: 'DOOR_STATUS',
    description: 'Receive an alert when a trailer or group of trailer’s doors are open, closed or both. ',
    isDurationPredicate: false,
    predicateOptions: {
      ANY_DOOR_STATUS: {
        id: 1,
        key: 'ANY_DOOR_STATUS',
        name: 'Any Door Status',
        displayName: 'Any Door Status',
      },
      OPEN: {
        id: 2,
        key: 'OPEN',
        name: 'Open',
        displayName: 'Open',
      },
      CLOSE: {
        id: 3,
        key: 'CLOSE',
        name: 'Close',
        displayName: 'Close',
      },
    },
    defaultPredicateFields: {
      doorClosed: true,
      doorOpened: true,
    },
    selectable: true,
    sensorType: sensorTypeIds.door,
    isGeofencePredicate: false,
    dropdownOption: true,
    geofenceOccupancy: true,
    isReportOption: false,
    alertPredicates: true,
    hasNotificationFrequency: true,
    buttonIcon: <img src={doorStatusIcon} alt="DOOR_STATUS" className="alertConfiguration-alertGroupIcon" />,
  },
  DOOR_STATUS_DURATION: {
    id: 11,
    name: 'Door Status Duration',
    buttonLabel: 'Door Status Duration',
    key: 'DOOR_STATUS_DURATION',
    description: 'Receive an alert when a trailer or group of trailer’s doors are open, closed or both for a specified duration.',
    isDurationPredicate: true,
    predicateOptions: {
      OPEN: {
        id: 2,
        key: 'OPEN',
        name: 'Open',
        displayName: 'Open',
      },
      CLOSE: {
        id: 3,
        key: 'CLOSE',
        name: 'Close',
        displayName: 'Close',
      },
    },
    defaultPredicateFields: null,
    selectable: true,
    sensorType: null,
    isGeofencePredicate: false,
    dropdownOption: true,
    geofenceOccupancy: true,
    isReportOption: false,
    alertPredicates: true,
    hasNotificationFrequency: true,
    buttonIcon: <img src={doorStatusDurationIcon} alt="DOOR_STATUS_DURATION" className="alertConfiguration-alertGroupIcon" />,
  },
  SENSOR_BOX_BATTERY_STATUS_CHANGE: {
    id: 12,
    name: 'Battery',
    buttonLabel: 'Battery',
    key: 'BATTERY',
    description: 'Receive an alert when a trailer or group of trailer’s battery voltage is at critical or caution levels.',
    isDurationPredicate: false,
    predicateOptions: {
      ANY_LOW_VOLTAGE_STATUS: {
        id: 1,
        key: 'ANY_LOW_VOLTAGE_STATUS',
        name: 'Any Low Voltage Status',
        displayName: 'Any Low Voltage Status',
      },
      CAUTION_STATUS: {
        id: 2,
        key: 'CAUTION_STATUS',
        name: 'Caution Status',
        displayName: 'Caution Status',
      },
      CRITICAL_STATUS: {
        id: 3,
        key: 'CRITICAL_STATUS',
        name: 'Critical Status',
        displayName: 'Critical Status',
      },
    },
    defaultPredicateFields: {
      sensorBoxBatteryRed: true,
      sensorBoxBatteryYellow: true,
    },
    selectable: true,
    sensorType: sensorTypeIds.sensorBoxBattery,
    isGeofencePredicate: false,
    dropdownOption: true,
    geofenceOccupancy: true,
    isReportOption: false,
    alertPredicates: true,
    hasNotificationFrequency: true,
    buttonIcon: <img src={batteryIcon} alt="BATTERY" className="alertConfiguration-alertGroupIcon" />,
  },
  TIS_STATUS_CHANGE: {
    id: 13,
    name: 'TIS Status',
    buttonLabel: 'TIS Status',
    key: 'TIS',
    description:
      'Receive an alert when a trailer or group of trailer’s Tire Inflation System is Unstable (demanding air for more than 10 minutes) or Filling (demanding air).',
    isDurationPredicate: false,
    predicateOptions: {
      ANY_TIS_STATUS: {
        id: 1,
        key: 'ANY_TIS_STATUS',
        name: 'Any TIS Status',
        displayName: 'Any TIS Status',
      },
      POTENTIAL_LEAK: {
        id: 2,
        key: 'POTENTIAL_LEAK',
        name: 'Filling',
        displayName: 'Filling',
      },
      UNSTABLE: {
        id: 3,
        key: 'UNSTABLE',
        name: 'Unstable',
        displayName: 'Unstable',
      },
    },
    defaultPredicateFields: {
      anyTisStatus: true,
      tisStatuses: [],
    },
    selectable: true,
    sensorType: sensorTypeIds.tireInflationStatus,
    isGeofencePredicate: false,
    dropdownOption: true,
    geofenceOccupancy: true,
    isReportOption: false,
    alertPredicates: true,
    hasNotificationFrequency: true,
    buttonIcon: <img src={tisIcon} alt="TIS" className="alertConfiguration-alertGroupIcon" />,
  },
  GEOFENCE_OCCUPANCY: {
    id: 14,
    name: 'Geofence Occupancy',
    buttonLabel: 'Geofence Occupancy',
    key: 'GEOFENCE_OCCUPANCY',
    description: '',
    isDurationPredicate: false,
    predicateOptions: {
      ALL_GEOFENCES: {
        id: 1,
        key: 'ALL_GEOFENCES',
        name: 'All Geofences',
        displayName: 'All Geofences',
      },
    },
    selectable: true,
    sensorType: null,
    isGeofencePredicate: true,
    dropdownOption: true,
    geofenceOccupancy: false,
    isReportOption: false,
    alertPredicates: true,
    hasNotificationFrequency: true,
    options: {
      WITHIN_ANY_SPECIFIED_GEOFENCE: {
        id: 1,
        key: 'WITHIN_ANY_SPECIFIED_GEOFENCE',
        name: 'Within Geofence',
        label: 'Within Geofence',
        value: 'Within Geofence',
      },
      OUTSIDE_ALL_SPECIFIED_GEOFENCES: {
        id: 2,
        key: 'OUTSIDE_ALL_SPECIFIED_GEOFENCES',
        name: 'Outside Geofence',
        label: 'Outside Geofence',
        value: 'Outside Geofence',
      },
    },
  },
  REEFER_FUEL: {
    id: 15,
    name: 'Reefer Unit Fuel',
    buttonLabel: 'Reefer Unit Fuel',
    key: 'REEFER_FUEL',
    description: 'Receive an alert when fuel level falls below a specified level.',
    isDurationPredicate: false,
    predicateOptions: null,
    defaultPredicateFields: null,
    selectable: true,
    sensorType: sensorTypeIds.reeferUnit,
    isGeofencePredicate: false,
    dropdownOption: false,
    geofenceOccupancy: true,
    isReportOption: false,
    alertPredicates: false,
    hasNotificationFrequency: true,
    buttonIcon: <img src={reeferFuelIcon} alt="REEFER_FUEL" className="alertConfiguration-alertGroupIcon" />,
  },
  REEFER_ALARM: {
    id: 16,
    name: 'Reefer Alarm',
    buttonLabel: 'Reefer Alarm',
    key: 'REEFER_ALARM',
    description: 'Receive an alert when a reefer unit throws a critical, caution, or check alert.',
    isDurationPredicate: false,
    predicateOptions: null,
    defaultPredicateFields: null,
    selectable: true,
    sensorType: sensorTypeIds.reeferUnit,
    isGeofencePredicate: false,
    dropdownOption: false,
    geofenceOccupancy: true,
    isReportOption: false,
    alertPredicates: false,
    hasNotificationFrequency: true,
    buttonIcon: <img src={reeferAlarmIcon} alt="REEFER_ALARM" className="alertConfiguration-alertGroupIcon" />,
  },
  TOW_ALERT: {
    id: 18,
    name: 'Tow',
    buttonLabel: 'Tow Alert',
    key: 'TOW_ALERT',
    description: 'Receive an alert when a trailer is towed without full power.',
    isDurationPredicate: false,
    predicateOptions: null,
    defaultPredicateFields: null,
    selectable: true,
    isGeofencePredicate: false,
    dropdownOption: true,
    geofenceOccupancy: false,
    isReportOption: false,
    alertPredicates: true,
    hasNotificationFrequency: true,
    buttonIcon: <img src={towAlertIcon} alt="TOW_ALERT" className="alertConfiguration-alertGroupIcon" />,
  },
  DWELL_REPORT: {
    id: 18,
    reportTypeId: 2,
    name: 'Dwell Report',
    buttonLabel: 'Dwell Report',
    key: 'DWELL_REPORT',
    isDurationPredicate: false,
    predicateOptions: null,
    defaultPredicateFields: null,
    selectable: true,
    sensorType: null,
    isGeofencePredicate: false,
    dropdownOption: false,
    geofenceOccupancy: false,
    isReportOption: true,
    description: `For Pro and Base models, monitors the total number of trailers untethered and ranks them by the amount of untethered time at the frequency you’ve selected.  For Go models, monitors the total number of trailers without movement and ranks them by the amount of time without movement at the frequency you’ve selected.`,
    shortPath: '/alertconfiguration/dwell',
    alertPredicates: false,
    isMultiSelect: true,
    hasNotificationFrequency: true,
    buttonIcon: <img src={alertsDwellReport} alt="DWELL_REPORT" className="alertConfiguration-alertGroupIcon horizontalCardIcon" />,
  },
  YARD_REPORT: {
    id: 19,
    reportTypeId: 1,
    name: 'Yard Report',
    buttonLabel: 'Yard Report',
    key: 'YARD_REPORT',
    isDurationPredicate: false,
    predicateOptions: null,
    defaultPredicateFields: null,
    selectable: true,
    sensorType: null,
    isGeofencePredicate: false,
    dropdownOption: false,
    geofenceOccupancy: false,
    isReportOption: true,
    description: `Receive a summary of the trailers that are currently located inside your geofence(s), sorted by dwell time.`,
    shortPath: '/alertconfiguration/yard',
    alertPredicates: false,
    isMultiSelect: true,
    hasNotificationFrequency: true,
    buttonIcon: <img src={alertsYardReport} alt="YARD_REPORT" className="alertConfiguration-alertGroupIcon horizontalCardIcon" />,
  },
  ABS_SUMMARY_REPORT: {
    id: 20,
    reportTypeId: 3,
    name: 'ABS Fault Summary Report',
    buttonLabel: 'ABS Fault Summary Report',
    key: 'ABS_SUMMARY_REPORT',
    isDurationPredicate: false,
    predicateOptions: null,
    defaultPredicateFields: null,
    selectable: true,
    sensorType: null,
    isGeofencePredicate: false,
    dropdownOption: false,
    geofenceOccupancy: false,
    isReportOption: true,
    description: `Periodic reporting of ABS Faults for your fleet providing the priority order of faults to address with additional data points and troubleshooting detail.`,
    shortPath: '/alertconfiguration/absSummary',
    alertPredicates: false,
    isMultiSelect: false,
    hasNotificationFrequency: true,
    buttonIcon: <img src={alertsABSReport} alt="ABS_SUMMARY_REPORT" className="alertConfiguration-alertGroupIcon horizontalCardIcon" />,
  },
  ABS_FAULT_DETAIL_REPORT: {
    id: 21,
    reportTypeId: 4,
    name: 'ABS Fault Detail Report',
    buttonLabel: 'ABS Fault Detail Report',
    key: 'ABS_FAULT_DETAIL_REPORT',
    isDurationPredicate: false,
    predicateOptions: null,
    defaultPredicateFields: null,
    selectable: true,
    sensorType: null,
    isGeofencePredicate: false,
    dropdownOption: false,
    geofenceOccupancy: false,
    isReportOption: true,
    description: `Periodic reporting of all active ABS faults, summarized in an .xlsx file delivered to your email inbox with links to troubleshooting detail.`,
    shortPath: '/alertconfiguration/absFaultDetail',
    alertPredicates: false,
    isMultiSelect: false,
    hasNotificationFrequency: true,
    buttonIcon: <img src={alertsABSReport} alt="ABS_FAULT_DETAIL_REPORT" className="alertConfiguration-alertGroupIcon horizontalCardIcon" />,
  },
  REEFER_TEMP_HISTORY_REPORT: {
    id: 22,
    reportTypeId: 8,
    name: 'Reefer Temperature History Report',
    buttonLabel: 'Reefer Temperature History Report',
    key: 'REEFER_TEMP_HISTORY_REPORT',
    isDurationPredicate: false,
    predicateOptions: null,
    defaultPredicateFields: null,
    selectable: true,
    sensorType: null,
    isGeofencePredicate: false,
    dropdownOption: false,
    geofenceOccupancy: false,
    isReportOption: true,
    description: `Create an instant report of Reefer temperature data history, summarized in an .xlsx file and delivered to your email inbox.`,
    shortPath: '/alertconfiguration/reeferTempHistory',
    alertPredicates: false,
    isMultiSelect: false,
    hasNotificationFrequency: false,
    buttonIcon: (
      <img
        src={alertsReeferTemperatureHistoryReport}
        alt="REEFER_TEMPERATURE_HISTORY_REPORT"
        className="alertConfiguration-alertGroupIcon horizontalCardIcon"
      />
    ),
  },
  ASSET_GEOFENCE_THRESHOLD_REPORT: {
    id: 23,
    reportTypeId: 5,
    name: 'Asset Geofence Threshold Report',
    buttonLabel: 'Asset Geofence Threshold Report',
    key: 'ASSET_GEOFENCE_THRESHOLD_REPORT',
    isDurationPredicate: false,
    predicateOptions: null,
    defaultPredicateFields: null,
    selectable: true,
    sensorType: null,
    isGeofencePredicate: false,
    dropdownOption: false,
    geofenceOccupancy: false,
    isReportOption: true,
    description: 'Receive an alert when the number of trailers exceeds a designed geofence threshold.',
    shortPath: '/alertconfiguration/assetGeofenceThreshold',
    alertPredicates: false,
    isMultiSelect: false,
    hasNotificationFrequency: false,
    buttonIcon: (
      <img src={alertsGeofenceThresholdReport} alt="ASSET_GEOFENCE_THRESHOLD_REPORT" className="alertConfiguration-alertGroupIcon horizontalCardIcon" />
    ),
  },
  TPMS_THRESHOLD: {
    id: 19,
    name: 'TPMS Threshold Alert',
    buttonLabel: 'TPMS Alert',
    key: 'TPMS',
    description: 'Receive an alert when a trailer or group of trailers drops below a selected TPMS threshold.',
    isDurationPredicate: true,
    predicateOptions: null,
    defaultPredicateFields: null,
    selectable: true,
    sensorType: null,
    isGeofencePredicate: false,
    dropdownOption: true,
    geofenceOccupancy: true,
    isReportOption: false,
    alertPredicates: true,
    hasNotificationFrequency: true,
    buttonIcon: <img src={tisIcon} alt="TPM Threshold" className="alertConfiguration-alertGroupIcon" />,
  },
  TIRE_TEMPERATURE: {
    id: 20,
    name: 'Tire Temperature Threshold Alert',
    buttonLabel: 'Tire Temperature Alert',
    key: 'TIRE_TEMPERATURE',
    description: 'Receive an alert when a trailer or group of trailers exceeds a specified threshold.',
    isDurationPredicate: true,
    predicateOptions: null,
    defaultPredicateFields: null,
    selectable: true,
    sensorType: null,
    isGeofencePredicate: false,
    dropdownOption: true,
    geofenceOccupancy: true,
    isReportOption: false,
    alertPredicates: true,
    hasNotificationFrequency: true,
    buttonIcon: <img src={alertsWheelEndTemp} alt="Tire Temperature" className="alertConfiguration-alertGroupIcon" />,
  },
  DAILY_MAINTENANCE_REPORT: {
    id: 24,
    reportTypeId: 6,
    name: 'Daily Maintenance Report',
    buttonLabel: 'Daily Maintenance Report',
    key: 'DAILY_MAINTENANCE_REPORT',
    isDurationPredicate: false,
    predicateOptions: null,
    defaultPredicateFields: null,
    selectable: true,
    sensorType: null,
    isGeofencePredicate: false,
    dropdownOption: false,
    geofenceOccupancy: false,
    isReportOption: true,
    description: `The daily maintenance report shows you the number of trailers with the following issues: Trailers with a level 1 Fault Code And-Or Fault active for >100 cumulative miles in a week on a level 2 fault code should then be added to level 1. And-Or Amber lamp on for more than two continuous hours at time of report.`,
    shortPath: '/alertconfiguration/dailyMaintenanceReport',
    alertPredicates: false,
    isMultiSelect: false,
    hasNotificationFrequency: true,
    buttonIcon: <img src={alertsABSReport} alt="ABS_SUMMARY_REPORT" className="alertConfiguration-alertGroupIcon horizontalCardIcon" />,
  },
  WEEKLY_MAINTENANCE_REPORT: {
    id: 25,
    reportTypeId: 7,
    name: 'Weekly Maintenance Report',
    buttonLabel: 'Weekly Maintenance Report',
    key: 'WEEKLY_MAINTENANCE_REPORT',
    isDurationPredicate: false,
    predicateOptions: null,
    defaultPredicateFields: null,
    selectable: true,
    sensorType: null,
    isGeofencePredicate: false,
    dropdownOption: false,
    geofenceOccupancy: false,
    isReportOption: true,
    description: `The weekly maintenance report shows you all the trailers in your fleet with fault codes broken down into level 1, 2 or 3.`,
    shortPath: '/alertconfiguration/weeklyMaintenanceReport',
    alertPredicates: false,
    isMultiSelect: false,
    hasNotificationFrequency: true,
    buttonIcon: <img src={alertsABSReport} alt="ABS_SUMMARY_REPORT" className="alertConfiguration-alertGroupIcon horizontalCardIcon" />,
  },
};
