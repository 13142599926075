import customerManagementAttributes from '../constants/enums/customerManagementAttributes';

export const getTotalAssets = (trailersPerCustomerCount, customerId) => {
  const totalActiveAssets = trailersPerCustomerCount.find(customer => customer?.customerId?.toString() === customerId);
  return totalActiveAssets?.trailerCount ?? 0;
};

export const formatCustomerFilters = filters => {
  if (!filters) return;

  const formattedFilters = filters.map(item => {
    const formattedValue = item[0] === customerManagementAttributes.CUSTOMER_ID.apiResponseParsePath ? `${item[1]?.filter}` : `"${item[1]?.filter}"`;
    const formattedKey = item[0] === customerManagementAttributes.CUSTOMER_ID.apiResponseParsePath ? `ids` : `${item[0]}`;
    return `${formattedKey}: ${formattedValue}`;
  });

  return formattedFilters;
};
