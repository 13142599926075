import { getColumnFieldName } from '../utils/agGridUtils';
import { formatCustomerFilters } from '../utils/customerManagementUtil';
import customerManagementAttributes from '../constants/enums/customerManagementAttributes';

export const fetchCustomersApi = `{
customers {
    id
    shortName
    longName
    created
    updated
    abbrev
    active
  }
}`;

export const fetchCustomersApiWithOption = showDeactivatedCustomers => {
  return `{
    customers(showDeactivated: ${showDeactivatedCustomers}) {
      id
      shortName
      longName
      created
      updated
      abbrev
      active
    }
  }`;
};

export const fetchCustomersApiPaginated = (pageNumber, pageSize, filters, sort, showDeactivatedCustomers) => {
  const formattedSort = sort
    ? `{ sortBy: "${getColumnFieldName(customerManagementAttributes, sort?.colId)}", sortAsc: ${Boolean(sort?.sort === 'asc')} }`
    : null;
  return `{
    findAllCustomers(input: {
     ${formatCustomerFilters(filters)}
      showDeactivated: ${showDeactivatedCustomers}
      paginationInput:{pageSize: ${pageSize}, pageNumber: ${pageNumber}}
      sortingInput: ${formattedSort}
    }) {
      customerCount
      customers{
        id
        longName
        shortName
        abbrev
        dotNumber
        retentionDurationDays
        created
        active
        updated
      }
    }
  }`;
};
