/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable import/prefer-default-export */

import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { validateToken } from '../utils/authUtils';
import { navigateToLogin } from '../utils/navigationUtils';
import { env } from '../env';

const createUserPool = () => {
  return new CognitoUserPool({
    UserPoolId: env.REACT_APP_COGNITO_USER_POOL_ID,
    ClientId: env.REACT_APP_COGNITO_CLIENT_ID,
  });
};

export const signOutAndNavigateToLogin = () => {
  window.localStorage.clear();
  signOutCognitoUser();
  navigateToLogin();
};

export const signOutAndNavigateToLoginIfTokenInvalid = () => {
  if (!validateToken()) {
    signOutAndNavigateToLogin();
  }
};

export const getCustomerIds = () => {
  signOutAndNavigateToLoginIfTokenInvalid();
  return window.localStorage.getItem('customer_ids');
};

const createCognitoUser = (Username, Pool) => {
  if (!Username || !Pool) {
    return;
  }
  return new CognitoUser({ Username, Pool });
};

export const sendVerificationCodeEmail = ({ username }) => {
  const cognitoUser = createCognitoUser(username, createUserPool());

  return new Promise((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: result => resolve(result),
      onFailure: err => reject(err),
      inputVerificationCode: () => resolve(cognitoUser),
    });
  });
};

export const resetUserPassword = ({ username, verificationCode, password }) => {
  const cognitoUser = createCognitoUser(username, createUserPool());
  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(verificationCode, password, {
      onSuccess: () => resolve({ message: 'success' }),
      onFailure: err => reject(err),
    });
  });
};

export const signOutCognitoUser = username => {
  const cognitoUser = createCognitoUser(username, createUserPool());
  if (cognitoUser) {
    cognitoUser.signOut();
  }
};
