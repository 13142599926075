import React from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _uniq from 'lodash/uniq';
import { allCustomersOptionDetails, superAdminDetails } from '../components/UserManagement/components/UserManagement';
import _orderBy from 'lodash/orderBy';
import { UserTypes } from '../constants/enums/userTypes';
import userManagementAttributes from '../constants/enums/userManagementAttributes';

export const getEmailVerifiedStatus = emailVerified => {
  const isEmailVerified = {
    null: '-',
    true: 'Yes',
  };
  return isEmailVerified[emailVerified] ?? 'No';
};

export const getUniqueGroupTypes = (groups, hasCustomerAdminRole) => {
  const accountGroupTypes = getAccountGroupTypes(groups, hasCustomerAdminRole);
  const uniqueGroupTypes = [...new Set(accountGroupTypes)];
  return uniqueGroupTypes.map(group => group).join(', ');
};

export const getAccountGroupTypes = (groupTypes, hasCustomerAdminRole) => {
  if (_isEmpty(groupTypes)) {
    return '-';
  }

  return groupTypes.map(group => {
    const groupName = _get(group, 'name', '');

    if (hasCustomerAdminRole && groupName) {
      if (groupName.startsWith('CUSTOMER_ADMIN_')) {
        return UserTypes.ACCOUNT_ADMINISTRATOR.userType;
      } else if (groupName.startsWith('CUSTOMER_')) {
        return UserTypes.STANDARD_USER.userType;
      }
    }
    return groupName;
  });
};

export const getCustomerNames = customers => {
  if (_isEmpty(customers)) {
    return '-';
  }

  return customers.map(group => _get(group, 'shortName')).join(', ');
};

export const getMultipleUsersGroups = (users, joinUserGroupsIndicator = false) =>
  (users ?? []).map(user => getUserAssignedGroups(user?.customers ?? [], joinUserGroupsIndicator));

export const getUserAssignedGroups = (customers, joinUserGroupsIndicator) => {
  if (customers.length === 0) {
    return '-';
  }
  const userGroups = [];
  customers.forEach(({ vehicleGroups }) => {
    userGroups.push(...(vehicleGroups ?? []).map(it => it.name));
  });

  const uniqueUserGroups = _uniq(userGroups);
  if (!joinUserGroupsIndicator) {
    return uniqueUserGroups ?? '-';
  }
  return _uniq(userGroups)?.join(', ') ?? '-';
};

export const getUsersBasedOnSelectedCustomer = (allUsers, selectedCustomer) => {
  if (selectedCustomer === allCustomersOptionDetails) {
    return allUsers;
  }
  return (allUsers ?? []).filter(user => user.customers?.some(cstm => cstm.id === selectedCustomer.id));
};

export const replaceSuperAdminDropdownOptionWithAllCustomers = customersData => {
  const superAdminItemIndex = customersData?.findIndex(cstm => cstm.id === superAdminDetails.id);
  customersData[superAdminItemIndex] = allCustomersOptionDetails;
  return sortCustomerDataAsc(customersData);
};

export const sortCustomerDataAsc = customersList => _orderBy(customersList ?? [], [customersList => customersList.shortName?.toUpperCase()], ['asc']);

export const getColumnSelectOptions = options => {
  const dropdownOptions = options?.map(it => <option key={it}>{it}</option>);
  return (
    <select>
      <option>All</option>
      {dropdownOptions}
    </select>
  );
};

const getTKCommandControlAccess = groups => groups?.some(group => group?.name === userManagementAttributes.THERMO_KING_CONTROL.key);

export const formatUserMngmntData = (user, hasCustomerAdminRole) => {
  const joinUserGroupsIndicator = true;
  return {
    ...user,
    username: user?.email ?? '-',
    enabled: user?.enabled,
    accountStatus: user?.accountStatus ?? '-',
    emailVerified: getEmailVerifiedStatus(user?.emailVerified ?? null),
    roleType: getUniqueGroupTypes(user?.groups ?? null, hasCustomerAdminRole),
    tkCommandControl: getTKCommandControlAccess(user?.groups ?? null),
    lastLogin: user?.lastLoginDateTime ?? null,
    updatedDate: user?.lastModifiedDate ?? null,
    createdDate: user?.createdDate ?? null,
    customerName: getCustomerNames(user?.customers ?? null),
    groups: getUserAssignedGroups(user?.customers ?? [], joinUserGroupsIndicator),
  };
};

export const formatUserMngmntColumns = col => ({
  ...col.agGridProps,
  headerName: col.displayName,
  field: col.attributeName,
  cellStyle: { fontFamily: 'Muli', fontSize: '10px !important', fontWeight: 'bold' },
});

export const resendEmailDescription = (emailType, userEmailAddresses) => {
  if (!emailType) return '';
  return `${emailType.description} ${userEmailAddresses.toEmailAddresses} ?`;
};

export const resendEmailConfirmationMsg = (emailType, resendEmailAdressess) => {
  if (!emailType) return '';
  return `${emailType?.confirmationMessage} ${resendEmailAdressess}.`;
};

export const formatUserManagementFilters = filters => {
  if (!filters) return;
  const formattedFilters = filters.map(item => {
    const col = Object.values(userManagementAttributes).find(attr => attr.attributeName === item[0]);

    if (userManagementAttributes.ENABLED.attributeName === item[0]) {
      return `${col.filter}: ${item[1]?.filter === 'Enabled'}`;
    }

    if (userManagementAttributes.EMAIL_VERIFIED.attributeName === item[0]) {
      return `${col.filter}: ${item[1]?.filter === 'Yes'}`;
    }

    if (userManagementAttributes.THERMO_KING_CONTROL.attributeName === item[0]) {
      return `${col.filter}: ${item[1]?.filter}`;
    }

    if (
      userManagementAttributes.UPDATED.attributeName === item[0] ||
      userManagementAttributes.CREATED.attributeName === item[0] ||
      userManagementAttributes.LAST_LOGIN.attributeName === item[0]
    ) {
      return `${col.filter}: "${item[1]?.dateFrom?.split(' ')[0]}"`;
    }

    return `${col.filter}: "${item[1]?.filter}"`;
  });

  return formattedFilters;
};
