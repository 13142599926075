import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _flow from 'lodash/flow';
import _toInteger from 'lodash/toInteger';
import {
  toggleAllUsersSelect,
  getCustomersData,
  setResendEmailType,
  setEditUserMode,
  disableUser,
  createAccount,
  closeResendEmailModal,
  deleteAccount,
  closeUserAlertDialogBox,
} from '../../../actions/userManagement';
import LoadingCircle from '../../LoadingCircle/LoadingCircle';
import UserModal from '../../UserModal/UserModal';
import SettingsNavigationTabs from '../../SettingsNavigationTabs/SettingsNavigationTabs';
import GroupFilter from '../../GroupHierarchy/components/GroupFilter';
import UserManagementTable from './UserMngmtTable';
import UserManagementHeader from './UserManagementHeader';
import { fetchVehicleGroupsData } from '../../../actions/vehicleGroups';
import { UserTypes } from '../../../constants/enums/userTypes';
import { fleetpulseApiFetch, fleetpulseAuthFetch } from '../../../utils/fetch';
import { isAdminOrCustomerAdmin, isCustomerAdmin } from '../../../security/authorization';
import { UserResendEmailTypes } from '../../../constants/enums/userResendEmailTypes';
import { getUsersBasedOnSelectedCustomer } from '../../../utils/userManagementUtils';
import { classes } from '../styles/muiComponentsStyle';
import '../styles/UserManagement.css';
import AlertDialog from '../../AlertDialog/AlertDialog';
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal';
import userManagementAttributes from '../../../constants/enums/userManagementAttributes';
import Button from '../../Button/Button';
import Switch from '../../Switch/Switch';

export const superAdminDetails = { id: '0', shortName: 'Super Admin', longName: 'Super Admin' };
export const allCustomersOptionDetails = { id: '0', shortName: 'All Customers', longName: 'All Customers' };

function UserManagement() {
  const [customer, setCustomer] = useState(null);
  const [resendEmailDialogOpen, setResendEmailDialogOpen] = useState(false);
  const [createAccountDialogOpen, setCreateAccountDialogOpen] = useState(false);
  const [deleteUser, setDeleteUser] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [filtersActive, setFiltersActive] = useState(true);
  const [previousFilterModel, setPreviousFilterModel] = useState(null);
  const tableRef = useRef(null);
  const { customersData } = useSelector(state => state.customers);

  const dispatch = useDispatch();
  const userManagementProps = useSelector(state => state.userManagement);
  const featureFlags = useSelector(state => state.featureFlag);

  useEffect(() => {
    dispatch(getCustomersData());
  }, [dispatch]);

  useEffect(() => {
    if (customer) {
      dispatch(fetchVehicleGroupsData(customer.id, fleetpulseApiFetch));
      setSelectedUsers([]);
    }
  }, [dispatch, customer]);

  const extractCustomerId = useCallback(
    (group, customerRegex) =>
      _flow(
        group => _get(group, 'name'),
        groupName => groupName.match(customerRegex),
        matches => _get(matches, '[1]'),
        customerId => (_isEmpty(customerId) ? undefined : _toInteger(customerId)),
      )(group),
    [],
  );

  const onResetPasswordClick = useCallback(() => {
    dispatch(setResendEmailType(UserResendEmailTypes.RESET_PASSWORD.key));
    setResendEmailDialogOpen(true);
  }, [dispatch]);

  const onResendVerificationClick = useCallback(() => {
    dispatch(setResendEmailType(UserResendEmailTypes.RESEND_VERIFICATION.key));
    setResendEmailDialogOpen(true);
  }, [dispatch]);

  const onToggleActiveState = useCallback(
    (isEnabled, user) => {
      if (isEnabled) {
        const userRoles = [];
        (user?.roles ?? []).forEach(role => {
          if (role && role.name) {
            userRoles.push(role.name);
          }
        });
        const isEditing = true;
        dispatch(createAccount(fleetpulseAuthFetch, user?.email ?? '', userRoles, isEditing));
      } else {
        dispatch(disableUser(fleetpulseAuthFetch, user.id));
      }
    },
    [dispatch],
  );

  const onToggleCommandControlState = useCallback(
    (isEnabled, user) => {
      const userData = userManagementProps?.filteredUsers?.find(item => item?.id === user?.id);
      const isEditing = true;
      if (isEnabled) {
        const userRoles = [userManagementAttributes.THERMO_KING_CONTROL.key];
        (userData?.groups ?? []).forEach(role => {
          if (role && role.name) {
            userRoles.push(role.name);
          }
        });
        dispatch(createAccount(fleetpulseAuthFetch, user?.email ?? '', userRoles, isEditing));
      } else {
        const userRoles = [];
        (userData?.groups ?? []).forEach(role => {
          if (role && role.name) {
            userRoles.push(role.name);
          }
        });
        const removeThermoKingControl = userRoles.filter(item => item !== userManagementAttributes.THERMO_KING_CONTROL.key);
        dispatch(createAccount(fleetpulseAuthFetch, user?.email ?? '', removeThermoKingControl, isEditing));
      }
    },
    [dispatch, userManagementProps],
  );

  const closeResendEmailDialog = useCallback(() => {
    setResendEmailDialogOpen(false);
    dispatch(closeResendEmailModal());
  }, [dispatch]);

  const openCreateAccountDialog = useCallback(() => {
    const editUserModeDetails = {
      emailAddress: '',
      selectedUserTypes: [],
      selectedCustomers: [],
      selectedGOCustomers: [],
      selectedInstallerCustomers: [],
      selectedAdminCustomers: [],
      isEditMode: false,
    };
    dispatch(setEditUserMode(editUserModeDetails));
    setCreateAccountDialogOpen(true);
  }, [dispatch]);

  const openEditAccountDialog = useCallback(() => {
    setCreateAccountDialogOpen(true);
  }, []);

  const closeCreateAccountDialog = useCallback(() => {
    setCreateAccountDialogOpen(false);
  }, []);

  const searchUsers = useCallback(
    val => {
      tableRef.current.userNameSearch(val);
    },
    [tableRef],
  );

  const onClickEdit = useCallback(
    user => {
      const selectedUserTypes = [];
      const selectedCustomers = [];
      const selectedGOCustomers = [];
      const selectedInstallerCustomers = [];
      const selectedAdminCustomers = [];

      (user?.roles ?? []).forEach(role => {
        if (role?.name) {
          if (role?.name?.startsWith(UserTypes.CUSTOMER_ADMIN.rolePattern)) {
            const customerId = extractCustomerId(role, UserTypes.CUSTOMER_ADMIN.regEx);
            const selectedCustomer = _get(
              (userManagementProps?.customers ?? []).filter(customer => _toInteger(customer.id) === customerId),
              '[0]',
              undefined,
            );
            if (selectedCustomer) {
              selectedAdminCustomers.push({
                value: customerId,
                label: selectedCustomer.shortName,
              });
            }
            selectedUserTypes.push({ name: UserTypes.CUSTOMER_ADMIN.userType });
          } else if (role?.name === UserTypes.GO_INSTALLER.typeName) {
            const customerId = extractCustomerId(role, UserTypes.CUSTOMER.regEx);
            const selectedCustomer = _get(
              (userManagementProps?.customers ?? []).filter(customer => _toInteger(customer.id) === customerId),
              '[0]',
              undefined,
            );
            if (selectedCustomer) {
              selectedGOCustomers.push({
                value: customerId,
                label: selectedCustomer.shortName,
              });
            }
            selectedUserTypes.push({ name: UserTypes.GO_INSTALLER.userType });
          } else if (role?.name === UserTypes.CUSTOMER_INSTALLER.typeName) {
            const customerId = extractCustomerId(role, UserTypes.CUSTOMER.regEx);
            const selectedCustomer = _get(
              (userManagementProps?.customers ?? []).filter(customer => _toInteger(customer.id) === customerId),
              '[0]',
              undefined,
            );
            if (selectedCustomer) {
              selectedInstallerCustomers.push({
                value: customerId,
                label: selectedCustomer.shortName,
              });
            }
            selectedUserTypes.push({ name: UserTypes.CUSTOMER_INSTALLER.userType });
          } else if (role?.name.startsWith(UserTypes.CUSTOMER.rolePattern)) {
            const customerId = extractCustomerId(role, UserTypes.CUSTOMER.regEx);
            const selectedCustomer = _get(
              (userManagementProps?.customers ?? []).filter(customer => _toInteger(customer.id) === customerId),
              '[0]',
              undefined,
            );
            if (selectedCustomer) {
              selectedCustomers.push({
                value: customerId,
                label: selectedCustomer.shortName,
              });
            }
            selectedUserTypes.push({ name: UserTypes.CUSTOMER.userType });
          } else {
            for (let item of Object.values(UserTypes)) {
              if (role?.name.startsWith(item.typeName) || role?.name.startsWith(item.userType)) {
                selectedUserTypes.push({ name: item.userType });
              }
            }
          }
        }
      });

      const editUserModeDetails = {
        emailAddress: user.email,
        selectedUserTypes,
        selectedCustomers,
        selectedGOCustomers,
        selectedInstallerCustomers,
        selectedAdminCustomers,
        isEditMode: true,
        user,
      };

      dispatch(setEditUserMode(editUserModeDetails));
      openEditAccountDialog();
    },
    [userManagementProps, extractCustomerId, dispatch, openEditAccountDialog],
  );

  const onClickDelete = useCallback(user => {
    setDeleteUser(user);
  }, []);

  const availableUsersData = useMemo(() => {
    if (customer) {
      return getUsersBasedOnSelectedCustomer(userManagementProps?.filteredUsers ?? [], customer);
    } else {
      return userManagementProps?.filteredUsers;
    }
  }, [userManagementProps, customer]);

  const isButtonDisabled = useMemo(() => {
    return _isEmpty(selectedUsers);
  }, [selectedUsers]);

  const groupButtonsDisabled = useMemo(() => {
    return !customer || isButtonDisabled || customer === allCustomersOptionDetails;
  }, [isButtonDisabled, customer]);

  const setCustomerData = useCallback(
    customer => {
      setCustomer(customer);
      dispatch(toggleAllUsersSelect({}));
    },
    [dispatch],
  );

  const features = featureFlags?.features ?? {};
  const isLoading = userManagementProps?.loading ?? false;
  const hasCustomerAdminRole = isCustomerAdmin();

  const groupsDisabled = Boolean(customersData?.length > 1 && !customer);

  const groupFilterStyle = isAdminOrCustomerAdmin() ? { width: '232px', display: 'block' } : { width: 'auto', display: 'none' };
  return (
    <div className="userManagement-container">
      <SettingsNavigationTabs />
      {groupsDisabled && <div className="warning">You need to select a customer in order to change group assignments or to edit groups</div>}
      <Grid style={isAdminOrCustomerAdmin() ? classes.gridContainer : {}}>
        <div style={groupFilterStyle}>
          <GroupFilter onCustomerChange={setCustomerData} searchInput={false} userManagementActive={true} />
        </div>
        <div>
          <div className="userManagement-top-buttons-container">
            <Button buttonText="Export to CSV" onClick={() => tableRef.current.csvExport()} outlineButton />
            <Switch labelLeft="Filters" onChange={() => setFiltersActive(!filtersActive)} checked={filtersActive} icon="checkicon" />
          </div>
        </div>
      </Grid>
      {isLoading && <LoadingCircle alignScreenCenter />}
      {!isLoading && (
        <>
          <UserManagementHeader
            isButtonDisabled={isButtonDisabled}
            features={features}
            resendEmailDialogOpen={resendEmailDialogOpen}
            openCreateAccountModal={openCreateAccountDialog}
            searchAllUsers={searchUsers}
            onResetPasswordClick={onResetPasswordClick}
            onResendVerificationClick={onResendVerificationClick}
            setResendEmailDialogClose={closeResendEmailDialog}
            groupButtonsDisabled={groupButtonsDisabled}
            selectedUsers={selectedUsers}
          />
          <AlertDialog
            open={userManagementProps.userAlertDialogOpen}
            onConfirm={() => dispatch(closeUserAlertDialogBox())}
            title={userManagementProps.userAlertDialogTitle}
            content={userManagementProps.userAlertDialogMessage}
            warningIcon={Boolean(userManagementProps.createAccountError) || Boolean(userManagementProps.deleteUserError)}
          />
          {createAccountDialogOpen && (
            <UserModal closeCreateAccountDialog={closeCreateAccountDialog} createAccountDialogOpen={createAccountDialogOpen} customer={customer} />
          )}
          <ConfirmationModal
            open={Boolean(deleteUser)}
            title="Delete User"
            content={`Are you sure you want to delete this user: ${deleteUser?.email}`}
            onConfirm={() => dispatch(deleteAccount(fleetpulseAuthFetch, deleteUser?.id ?? '', deleteUser?.email))}
            onClose={() => setDeleteUser(null)}
          />
          <UserManagementTable
            features={features}
            hasCustomerAdminRole={hasCustomerAdminRole}
            selectedUsers={selectedUsers}
            onToggleActiveState={onToggleActiveState}
            onToggleCommandControlState={onToggleCommandControlState}
            onClickEdit={onClickEdit}
            onClickDelete={onClickDelete}
            filteredUsers={availableUsersData}
            setSelectedUsers={setSelectedUsers}
            selectedCustomer={customer}
            filtersActive={filtersActive}
            ref={tableRef}
            previousFilterModel={previousFilterModel}
            setPreviousFilterModel={setPreviousFilterModel}
          />
        </>
      )}
    </div>
  );
}

UserManagement.propTypes = {
  filteredUsers: PropTypes.array,
  selectedUsers: PropTypes.array,
  features: PropTypes.object,
  loading: PropTypes.bool,
};

UserManagement.defaultProps = {
  filteredUsers: [],
  selectedUsers: [],
  features: {},
  loading: false,
};

export default UserManagement;
