import { combineReducers } from 'redux';
import user from './user';
import login from './login';
import tracking from './tracking';
import overlayBar from './overlayBar';
import global from './global';
import forgotPassword from './forgotPassword';
import geofence from './geofence';
import unsubscribeEmail from './unsubscribeEmail';
import closestAftermarketDealers from './closestAftermarketDealers';
import featureFlag from './featureFlag';
import absTroubleshooting from './absTroubleshootingData';
import faq from './faq';
import vehicleDetailData from './vehicleDetailData';
import vehicleSpecsDetailData from './vehicleSpecsDetail';
import createAccount from './createAccount';
import confirmUser from './confirmUser';
import userManagement from './userManagement';
import navigationSearch from './navigationSearch';
import dashboardFilters from './dashboardFilters';
import activityLog from './activityLog';
import trackingHistory from './trackingHistory';
import trailerImageHistory from './trailerImageHistory';
import alertConfiguration from './alertConfiguration';
import partsData from './partsData';
import allVinsAndUnitIds from './allVinsAndUnitIds';
import currentFleetStatus from './currentFleetStatus';
import vehicleGroups from './vehicleGroups';
import customers from './customers';
import customerManagement from './customerManagement';
import otaManagement from './otaManagement';
import moreyOtaScriptUpload from './moreyOtaScriptUpload';
import deviceConfigManagement from './deviceConfigManagement';
import deviceScriptsManagement from './deviceScriptsManagement';
import deviceMainAppManagement from './deviceMainAppManagement';
import moreyOtaMainAppUpload from './moreyOtaMainAppUpload';
import maintenance from './maintenance';

export default combineReducers({
  user,
  login,
  tracking,
  overlayBar,
  global,
  forgotPassword,
  geofence,
  closestAftermarketDealers,
  unsubscribeEmail,
  featureFlag,
  absTroubleshooting,
  faq,
  vehicleDetailData,
  vehicleSpecsDetailData,
  createAccount,
  confirmUser,
  userManagement,
  navigationSearch,
  dashboardFilters,
  activityLog,
  trackingHistory,
  trailerImageHistory,
  alertConfiguration,
  partsData,
  allVinsAndUnitIds,
  currentFleetStatus,
  vehicleGroups,
  customers,
  customerManagement,
  otaManagement,
  deviceConfigManagement,
  moreyOtaScriptUpload,
  deviceScriptsManagement,
  deviceMainAppManagement,
  moreyOtaMainAppUpload,
  maintenance,
});
