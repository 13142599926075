import _find from 'lodash/find';
import _keyBy from 'lodash/keyBy';
import { WHEEL_END_STATUS } from '../constants/enums/hendricksonStatus';
import sensorStatusTypes from '../constants/enums/sensorStatusTypes';
import _isNumber from "lodash/isNumber";

export const getHendricksonSensorStatus = (sensorEnum, status) => {
  return _find(sensorEnum, item => item.status === status) ?? sensorStatusTypes.NOT_AVAILABLE.display;
};

export const mapWheelEndSensorStatus = item => {
  const wheelEndSensorStatus = getHendricksonSensorStatus(WHEEL_END_STATUS, item?.wheelEndTemperatureThresholdDetection);
  const isWheelEndStatusCritical = wheelEndSensorStatus?.sensorStatus?.display === sensorStatusTypes.CRITICAL.display;
  if (isWheelEndStatusCritical) {
    return `Critical - ${wheelEndSensorStatus?.display}` ?? sensorStatusTypes.NOT_AVAILABLE.display;
  }
  return wheelEndSensorStatus?.sensorStatus?.display ?? sensorStatusTypes.NOT_AVAILABLE.display;
};

export const convertArrayToObject = array => _keyBy(array, 'wheelLocation');

export const getIndividualTirePressureText = value => {
  if (!value) {
    return sensorStatusTypes.NOT_AVAILABLE.display;
  }

  return `${Number(value)?.toFixed(1)}psi`;
};

export const getTemperature = wheelEnd => {
  return wheelEnd.wheelEndTemperatureFahrenheit ? wheelEnd.wheelEndTemperatureFahrenheit : wheelEnd.wheelEndTemperature;
};

export const getWheelEndText = value => {
  if (!value) {
    return sensorStatusTypes.NOT_AVAILABLE.display;
  }
  return `${value}°F`;
};

export const celsiusToFahrenheit = celsius => {
  if (!_isNumber(celsius)) return 'N/A';

  // If the temperature is greater than 250, it is likely an error value.
  if (celsius > 250){
    return 'N/A';
  }
  return `${Math.round((celsius * 9/5) + 32)}°F`;
};

export const pressureChecked = pressure => {
  if (!_isNumber(pressure)) return 'N/A';

  // If the psi is greater than 900, it is likely an error value.
  if (pressure > 900){
    return 'N/A';
  }
  return `${Math.round(pressure)}psi`;
};

export const wheelPositions = {
  "Front Left Wheel": {
    "A": "LFO",
    "B": "LFI"
  },
  "Front Right Wheel": {
    "A": "FRO",
    "B": "FRI"
  },
  "Rear Left Wheel": {
    "A": "RLO",
    "B": "RLI"
  },
  "Rear Right Wheel": {
    "A": "RRO",
    "B": "RRI"
  }
};
