import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { replaceSuperAdminDropdownOptionWithAllCustomers } from '../../utils/userManagementUtils';
import Select from '../Select/Select';

function CustomerSelect({
  customer,
  setCustomer,
  isDisabled = false,
  userManagementActive = false,
  vinTransferActive = false,
  clearable = false,
  hideLabel,
  required,
  customLabel,
  isMulti = false,
  chipsOutside = false,
}) {
  let { customersData, customersLoading } = useSelector(s => s.customers);

  if (userManagementActive) {
    customersData = replaceSuperAdminDropdownOptionWithAllCustomers(customersData);
  }
  if (vinTransferActive) {
    customersData = customersData.map(c => {
      return { label: c.shortName, value: c.id };
    });
  }

  return (
    <Select
      label={hideLabel ? null : customLabel ?? 'Select Customer'}
      required={required}
      value={customer}
      options={customersData}
      onChange={setCustomer}
      getOptionValue={option => (vinTransferActive ? option.value : option?.id)}
      getOptionLabel={option => (vinTransferActive ? option.label : option?.shortName)}
      placeholder={customLabel ?? 'Select Customer'}
      isDisabled={isDisabled}
      isClearable={clearable && !!customer}
      isSearchable={true}
      isLoading={customersLoading}
      isMulti={isMulti}
      isCustomerChip={true}
      chipsOutside={chipsOutside}
    />
  );
}

CustomerSelect.propTypes = {
  customer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    shortName: PropTypes.string.isRequired,
    longName: PropTypes.string.isRequired,
  }),
  setCustomer: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  userManagementActive: PropTypes.bool,
  vinTransferActive: PropTypes.bool,
  clearable: PropTypes.bool,
  hideLabel: PropTypes.bool,
  customLabel: PropTypes.string,
};

export default CustomerSelect;
